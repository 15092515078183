/* Create a custom checkbox */
.c-customCheckbox {
  background-color: var(--neutral-start);
  border: 1px solid var(--primary-base);
  border-radius: 4px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;

  /* Create the checkmark/indicator (hidden when not checked) */
  &::after {
    content: '';
    display: none;
    position: absolute;
  }
}

/* Customize the label (the container) */
.c-inputCheckbox {
  cursor: pointer;
  display: block;
  margin-bottom: 12px;
  padding-left: 35px;
  position: relative;
  user-select: none;

  /* Hide the browsers default checkbox */
  input[type='checkbox'] {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  /* On mouse-over, add a white background color */
  &:hover input[type='checkbox'] ~ .c-customCheckbox {
    background-color: var(--neutral-start);
  }

  /* When the checkbox is checked, add a blue background */
  input[type='checkbox']:checked ~ .c-customCheckbox {
    background-color: var(--primary-base);
  }

  /* Style the checkmark/indicator */
  .c-customCheckbox::after {
    border: solid var(--neutral-start);
    border-width: 0 2px 2px 0;
    height: 14px;
    left: 7px;
    top: 1px;
    transform: rotate(45deg);
    width: 6px;
  }

  input[type='checkbox']:checked ~ .c-customCheckbox::after {
    display: block;
  }
}
