// Typography
//
// Typography is our system of fonts. Each font conveys the appropriate sentiment to assist our users through each stage of their journey.
//
// Weight: -3
//
// Style guide: foundations.typography

// Default HTML tags
//
// All HTML tags, from <code>h1</code> through <code>h6</code>, <code>p</code>, <code>ul</code>, <code>ol</code>, and so on are available.
//
// Markup:
// <h1>H1 is here</h1>
// <hr>
// <h2>H2 is here also</h2>
// <hr>
// <h3>H3 of course</h3>
// <hr>
// <h4>H4 rules the others</h4>
// <hr>
// <h5>H5 is available</h5>
// <hr>
// <h6>H6 is available</h6>
// <hr>
// <p>The paragraph supports all of these H* tags the best it can.</p>
//
// Weight: 1
//
// Style guide: foundations.typography.headings

// Generate typography classes declared as tokens
@each $token in map-keys($font-scale) {
    .text-#{$token} {
        @include font-scale($token);
    }
}

[class^="text-heading-"],
[class^="text-display-"] {
    @extend %heading;
}
