@import "../../00-settings/index";
@import "../../01-tools/index";

// Flow
//
// Markup: flow.hbs
//
// Style guide: components.flow

.flow {
    margin-bottom: 0;
    padding: spacer(gigantic) 0;
    list-style: none;

    > *:first-child::before {
        content: none;
    }
}

.flow-item {
    position: relative;
    max-width: $flow-item-width;
    margin: 0 auto;

    &::before {
        position: relative;
        left: $flow-node-connector-space-left + ((($flow-node-connector-width) - $flow-node-connector-width * .25) * .5);
        display: block;
        width: $flow-node-connector-width * .25;
        height: $flow-connector-line-height;
        background-color: $flow-node-connector-bg;
        content: "";
    }
}

.flow-item-is-dragging {

    &::before {
        visibility: hidden;
    }
}

.flow-action {
    cursor: grab;

    &::before,
    &::after {
        position: absolute;
        left: $flow-node-connector-space-left;
        z-index: 2;
        width: $flow-node-connector-width;
        height: $flow-node-connector-width;
        background-color: $flow-node-connector-bg;
        border: $flow-node-connector-border;
        border-radius: 50%;
        content: "";
    }

    &::before {
        top: -($flow-node-connector-width * .5);

        .flow-item:first-of-type & {
            content: none;
        }
    }

    &::after {
        bottom: -($flow-node-connector-width * .5);

        .flow-item:last-of-type & {
            content: none;
        }
    }
}

.flow-action-is-dragging {
    cursor: grabbing;

    &::before,
    &::after {
        visibility: hidden;
    }
}

.flow-item-placeholder {

    .flow-action {
        background-color: $flow-placeholder-bg;

        &::before,
        &::after {
            background-color: $flow-placeholder-node-connector-bg;
            border-color: $flow-placeholder-node-connector-border-color;
        }
    }
}
