@import "../../00-settings/index";
@import "../../01-tools/index";

// Check selection
//
// A check selection is an input control that allows a user to select one or more options from a number of choices.
//
// Markup:
// <label class="form-check-selection">
//     <input type="checkbox" class="form-check-selection-input">
//     <span class="form-check-selection-label">
//         <span role="img" class="form-check-selection-indicator"></span>
//         <span class="form-check-selection-body">
//             <span class="form-check-selection-title text-heading-small">Credit card</span>
//             <small class="form-check-selection-subtitle">Allow customers to pay with their credit card.</small>
//         </span>
//     </span>
// </label>
//
// Style guide: forms.form-check-selection

// Usage
//
// Primarily for use in forms, check selections are used to collect input from users. Users can select a number of options ranging from zero to multiple options.
//
// The difference between <code>.form-check</code> and <code>.form-check-selection</code> is that the check selection is a more flexible component. We can create selection dependencies by grouping all the fields in the same container.
//
// Markup:
// <label class="form-check-selection">
//     <input type="checkbox" class="form-check-selection-input" checked>
//     <span class="form-check-selection-label">
//         <span role="img" class="form-check-selection-indicator"></span>
//         <span class="form-check-selection-body">
//             <span class="form-check-selection-title text-heading-small">Credit card</span>
//             <small class="form-check-selection-subtitle">Allow customers to pay with their credit card.</small>
//             <span class="form-check-selection-hidden">
//                 <span class="d-block">
//                     <span class="form-label d-block">Credit card configuration ID</span>
//                     <input type="text" class="form-control form-control-sm">
//                 </span>
//             </span>
//         </span>
//     </span>
// </label>
//
// Style guide: forms.form-check-selection.usage

// Anatomy
//
// A default check selection component is made of 3 section:
//
// <ol><li><b>Label</b> (<code>form-check-selection-label</code>) acts as the field container.</li>
// <li><b>Indicator</b> (<code>.form-check-selection-indicator</code>) indicates the <code>:checked</code> state.</li>
// <li><b>Body</b> (<code>.form-check-selection-body</code>) acts as the content container.</li></ol>
//
// The indicator can be removed if the body includes an icon.
//
// Markup:
// <label class="form-check-selection">
//     <input type="checkbox" class="form-check-selection-input">
//     <span class="form-check-selection-label">
//         <span role="img" class="form-check-selection-indicator"></span>
//         <span class="form-check-selection-body">
//             <span class="form-check-selection-title text-heading-small">Credit card</span>
//             <small class="form-check-selection-subtitle">Allow customers to pay with their credit card.</small>
//         </span>
//     </span>
// </label>
// <label class="form-check-selection">
//     <input type="checkbox" class="form-check-selection-input">
//     <span class="form-check-selection-label">
//           <span class="form-check-selection-body">
//               <span class="d-flex align-items-start">
//                   <span class="icon icon-payment-credit-card icon-lg mr-medium">
//                       <svg><use xlink:href="#ui-payment-credit-card" xmlns:xlink="http://www.w3.org/1999/xlink" /></svg>
//                   </span>
//                   <span class="d-block">
//                       <span class="form-check-selection-title text-heading-small">Credit card</span>
//                       <small class="form-check-selection-subtitle">Allow customers to pay with their credit card.</small>
//                   </span>
//               </span>
//           </span>
//     </span>
// </label>
//
// Weight: 1
//
// Style guide: forms.form-check-selection.anatomy

// Types
//
// A check selection component can be a checkbox or a radio button group. It depends on the type you set for <code>.form-check-selection-input</code>.
//
// Markup:
// <div>
//    <p class="form-label">Policy renewal period</p>
//    <label class="form-check-selection">
//        <input type="radio" id="radioDemo1" name="check-radio-selection" class="form-check-selection-input" checked>
//        <span class="form-check-selection-label">
//            <span role="img" class="form-check-selection-indicator"></span>
//                <span class="form-check-selection-body">
//                    <span class="form-check-selection-title text-heading-small">Yearly</span>
//                    <small class="form-check-selection-subtitle">Renew the policy every 12 months.</small>
//                </span>
//            </span>
//        </span>
//    </label>
//    <label class="form-check-selection">
//        <input type="radio" id="radioDemo2" name="check-radio-selection" class="form-check-selection-input">
//        <span class="form-check-selection-label">
//          <span role="img" class="form-check-selection-indicator"></span>
//              <span class="form-check-selection-body">
//                  <span class="form-check-selection-title text-heading-small">Monthly</span>
//                  <small class="form-check-selection-subtitle">Renew the policy every month.</small>
//              </span>
//        </span>
//    </label>
// </div>
//
// Weight: 2
//
// Style guide: forms.form-check-selection.types

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it depends on another selection.
//
// Markup:
// <label class="form-check-selection">
//     <input type="checkbox" class="form-check-selection-input" disabled>
//     <span class="form-check-selection-label">
//         <span role="img" class="form-check-selection-indicator"></span>
//         <span class="form-check-selection-body">
//             <span class="form-check-selection-title text-heading-small">Credit card</span>
//             <small class="form-check-selection-subtitle">Allow customers to pay with their credit card.</small>
//         </span>
//     </span>
// </label>
//
// Weight: 3
//
// Style guide: forms.form-check-selection.disabled

.form-check-selection {
    --padding-y: var(--#{$form-check-selection-padding-y});
    --padding-x: var(--#{$form-check-selection-padding-x});
    --bg: var(--#{$form-check-selection-bg});
    --border-color: var(--#{$form-check-selection-border-color});
    --border-radius: #{$form-check-selection-border-radius};
    --box-shadow: #{$form-check-selection-box-shadow};
    --focus-border-color: var(--#{$form-check-selection-focus-border-color});
    --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
    --checked-bg: var(--#{$form-check-selection-checked-bg});
    --checked-border-color: var(--#{$form-check-selection-checked-border-color});
    --indicator-size: #{$form-check-selection-indicator-size};
    --indicator-bg: var(--#{$form-check-selection-indicator-bg});
    --indicator-border-radius: #{$form-check-selection-indicator-border-radius};
    --indicator-checked-bg: var(--#{$form-check-selection-indicator-checked-bg});

    position: relative;
    display: block;

    & + & {
        margin-top: var(--#{$form-check-stack-gap-y}, spacer(#{$form-check-stack-gap-y}));
    }

    .alert {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
    }
}

.form-check-selection-input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.form-check-selection-label {

    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: var(--padding-y, spacer(#{$form-check-selection-padding-y})) var(--padding-x, spacer(#{$form-check-selection-padding-x}));
    color: var(--color, ui-color(#{$form-check-selection-color}));
    background: var(--bg, ui-color(#{$form-check-selection-bg}));
    background-clip: padding-box;
    border-color: var(--border-color, ui-color(#{$form-check-selection-border-color}));
    border-style: solid;
    border-width: $form-check-selection-border-width;
    border-radius: var(--border-radius, #{$form-check-selection-border-radius});
    box-shadow: var(--box-shadow, #{$form-check-selection-box-shadow});
    cursor: pointer;
    transition: $input-transition;

    .form-check-selection-input:focus + & {
        --border-color: var(--focus-border-color);
        --box-shadow: var(--focus-box-shadow);
    }

    .form-check-selection-input:checked + & {
        --bg: var(--checked-bg);
        --border-color: var(--checked-border-color);

        .form-check-selection-hidden {
            display: block;
        }
    }

    .form-check-selection-input:disabled + &,
    .form-check-selection-input[disabled] + & {
        cursor: default;
        opacity: $form-check-selection-disabled-opacity;
        pointer-events: none;
    }

    .form-check-selection-hidden {
        display: none;
        width: 100%;
        margin: var(--padding-y, spacer(#{$form-check-selection-padding-y})) 0;
    }
}

// Style the checkbox/radio indicator

.form-check-selection-indicator {
    flex-shrink: 0;
    width: var(--indicator-size, #{$form-check-selection-indicator-size});
    height: var(--indicator-size, #{$form-check-selection-indicator-size});
    margin-right: var(--padding-y, spacer(#{$form-check-selection-padding-x}));
    background-color: var(--indicator-bg, ui-color(#{$form-check-selection-indicator-bg}));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: $form-check-transition;

    .form-check-selection-input[type="checkbox"] ~ .form-check-selection-label & {
        border-radius: var(--indicator-border-radius, #{$form-check-selection-indicator-border-radius});
    }

    .form-check-selection-input[type="radio"] ~ .form-check-selection-label & {
        border-radius: 50%;
    }

    .form-check-selection-input:checked ~ .form-check-selection-label & {
        --indicator-bg: var(--indicator-checked-bg);

        background-image: escape-svg($form-check-input-checked-bg-image);
    }
}

.form-check-selection-body {
    flex: 1 1 0;
}

.form-check-selection-title,
.form-check-selection-subtitle {
    display: block;
}

.form-check-selection-title {
    margin-bottom: var(--none);
}

.form-check-selection-input:checked ~ .alert {
    opacity: 100%;
}
