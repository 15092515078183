// Variables

// Color system

@use 'sass:math';

$white: ui-color(neutral-start) !default;
$black: ui-color(neutral-end) !default;

$primary: ui-color(primary-base);
$secondary: ui-color(secondary-base);
$success: ui-color(success-base);
$info: ui-color(info-base);
$warning: ui-color(warning-base);
$danger: ui-color(danger-base);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29')) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid: true !default;
$enable-button-pointers: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: null !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Body
//
// Settings for the `<body>` element.
$body-text-align: null !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;

// Global
//
// Styles that apply globally, can be overwritten at the component level

$focus-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.9), 0 0 0 4px var(--neutral-end);

// Links

$link-color: primary-base !default;
$link-decoration: none !default;
$link-hover-color: primary-dark !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs

$paragraph-margin-b: large !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  tablet: 100%,
  laptop: 1080px,
  desktop: 1080px,
  tv: 1080px
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Container padding

$container-padding-x: calc(var(--#{$gap-default}) * 0.5) !default;

// Icon sizes

$icon-size-sm: rem(16px) !default;
$icon-size: rem(20px) !default;
$icon-size-md: rem(24px) !default;
$icon-size-lg: rem(32px) !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: ui-color(neutral-light) !default;

$border-radius-sm: 4px !default;
$border-radius: 8px !default;
$border-radius-lg: 16px !default;
$border-widths: (
  0: 0,
  1: 1px
) !default;

$box-shadow: 0 2px 4px rgba(ui-color(neutral-end), 0.04), 0 4px 8px rgba(ui-color(neutral-end), 0.04),
  0 4px 12px rgba(ui-color(neutral-end), 0.02) !default;
$box-shadow-sm: 0 1px 3px rgba(ui-color(neutral-end), 0.06), 0 2px 6px rgba(ui-color(neutral-end), 0.02) !default;
$box-shadow-lg: 0 2px 4px rgba(ui-color(neutral-end), 0.04), 0 4px 8px rgba(ui-color(neutral-end), 0.04),
  0 8px 16px rgba(ui-color(neutral-end), 0.08), 0 8px 24px rgba(ui-color(neutral-end), 0.08) !default;
$box-shadow-inset: null !default;

$component-active-color: ui-color(neutral-start) !default;
$component-active-bg: primary-base !default;

$caret-width: 0.32rem !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: spacer(small) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-kyn: 'Kyn', -apple-system, blinkmacsystemfont, avenir next, avenir, segoe ui, helvetica neue, helvetica,
  ubuntu, roboto, noto, arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol !default;
$font-family-monospace: sfmono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace !default;

$font-family-base: $font-family-kyn !default;
$font-family-headings: $font-family-kyn !default;
$font-family-code: $font-family-monospace !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: body !default;
$font-size-sm: caption !default;
$font-size-lg: body-large !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.43 !default;
$line-height-sm: 1.3334 !default;
$line-height-lg: 2 !default;

$h1-font-scale: display-medium !default;
$h2-font-scale: heading-large !default;
$h3-font-scale: heading-medium !default;
$h4-font-scale: heading-small !default;
$h5-font-scale: body !default;
$h6-font-scale: caption !default;

$headings-margin-b: small !default;
$headings-font-family: $font-family-headings !default;
$headings-font-style: normal !default;
$headings-line-height: 1.2 !default;
$headings-font-weight: $font-weight-semibold !default;

$blockquote-font-scale: body-large !default;

$hr-height: $border-width !default;
$hr-margin-y: large !default;
$hr-color: neutral-light !default;
$hr-opacity: 1 !default;

$legend-margin-bottom: large !default;
$legend-font-scale: body !default;
$legend-font-weight: null !default;

$sub-sup-font-size: 0.75em !default;

$mark-padding: 0.2em !default;
$mark-bg: warning-light !default;

$dt-font-weight: $font-weight-semibold !default;

$nested-kbd-font-weight: $font-weight-semibold !default;

$list-inline-padding: small !default;

// Buttons + Forms
// Use the shared 'input-btn' variables to keep 'input-' and 'btn-' in sync

$input-btn-padding-y: medium !default;
$input-btn-padding-x: large !default;
$input-btn-font-family: null !default;
$input-btn-font-scale: body !default;

$input-btn-padding-y-sm: small !default;
$input-btn-padding-x-sm: medium !default;
$input-btn-font-scale-sm: $input-btn-font-scale !default;

$input-btn-padding-y-lg: large !default;
$input-btn-padding-x-lg: large !default;
$input-btn-font-scale-lg: $input-btn-font-scale !default;

$input-btn-border-width: $border-width !default;

// Buttons

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-scale: $input-btn-font-scale !default;
$btn-font-weight: $font-weight-semibold !default;
$btn-white-space: nowrap !default;
$btn-color: neutral-end !default;
$btn-bg: neutral-start !default;
$btn-border-width: $input-btn-border-width !default;
$btn-border-color: neutral-base !default;
$btn-box-shadow: $box-shadow-sm !default;
$btn-border-radius: spacer(gigantic) !default;
$btn-transform: scale(1) !default;

$btn-hover-color: neutral-end !default;
$btn-hover-bg: neutral-lightest !default;
$btn-hover-border-color: $btn-border-color !default;
$btn-hover-box-shadow: $box-shadow !default;

$btn-active-color: $btn-hover-color !default;
$btn-active-bg: $btn-hover-bg !default;
$btn-active-border-color: $btn-hover-border-color !default;
$btn-active-box-shadow: 0 0 0 transparent !default;
$btn-active-transform: scale(0.96) !default;

$btn-selected-color: primary-base !default;
$btn-selected-bg: primary-lightest !default;
$btn-selected-border-color: primary-base !default;
$btn-selected-box-shadow: $btn-box-shadow !default;

$btn-disabled-opacity: 1 !default;
$btn-disabled-color: neutral-base !default;
$btn-disabled-bg: neutral-light !default;
$btn-disabled-border-color: $btn-disabled-bg !default;
$btn-disabled-box-shadow: 0 0 0 transparent !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $btn-disabled-color !default;

$btn-b2b-font-weight: $btn-font-weight !default;
$btn-b2c-font-weight: $btn-font-weight !default;

$btn-block-spacing-y: 0.5rem !default;

$btn-transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease,
  transform 0.25s ease !default;

// Declare the sizes modifiers
$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-scale-sm: $input-btn-font-scale-sm !default;
$btn-border-radius-sm: spacer(gigantic) !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-scale-lg: $input-btn-font-scale-lg !default;
$btn-border-radius-lg: spacer(gigantic) !default;

// Create square button. Used only with .icon as a child
$btn-square-padding: $btn-padding-y !default;
$btn-square-padding-sm: $btn-padding-y-sm !default;
$btn-square-padding-lg: $btn-padding-y-lg !default;

$button-variants: (
  primary: (
    color: neutral-start,
    bg: primary-base,
    border-color: primary-base,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-start,
    hover-bg: primary-dark,
    hover-border-color: primary-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    active-color: neutral-start,
    active-bg: primary-dark,
    active-border-color: primary-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: primary-base,
    selected-bg: primary-lightest,
    selected-border-color: primary-base,
    selected-box-shadow: $btn-box-shadow,
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  subtle: (
    color: neutral-dark,
    bg: color-transparent,
    border-color: color-transparent,
    box-shadow: none,
    hover-color: neutral-end,
    hover-bg: color-transparent,
    hover-border-color: color-transparent,
    hover-box-shadow: none,
    active-color: neutral-end,
    active-bg: color-transparent,
    active-border-color: color-transparent,
    active-box-shadow: none,
    selected-color: primary-dark,
    selected-bg: color-transparent,
    selected-border-color: color-transparent,
    selected-box-shadow: none,
    disabled-color: $btn-disabled-color,
    disabled-bg: color-transparent,
    disabled-border-color: color-transparent,
    disabled-box-shadow: none
  ),
  success: (
    color: neutral-start,
    bg: success-base,
    border-color: success-base,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-start,
    hover-bg: success-dark,
    hover-border-color: success-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    active-color: neutral-start,
    active-bg: success-dark,
    active-border-color: success-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: success-dark,
    selected-bg: success-light,
    selected-border-color: success-base,
    selected-box-shadow: $btn-box-shadow,
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  warning: (
    color: neutral-end,
    bg: warning-base,
    border-color: warning-base,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-end,
    hover-bg: warning-dark,
    hover-border-color: warning-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    active-color: neutral-end,
    active-bg: warning-dark,
    active-border-color: warning-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-end,
    selected-bg: warning-light,
    selected-border-color: warning-base,
    selected-box-shadow: $btn-box-shadow,
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  danger: (
    color: neutral-start,
    bg: danger-base,
    border-color: danger-base,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-start,
    hover-bg: danger-dark,
    hover-border-color: danger-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    active-color: neutral-start,
    active-bg: danger-dark,
    active-border-color: danger-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: danger-dark,
    selected-bg: danger-light,
    selected-border-color: danger-base,
    selected-box-shadow: $btn-box-shadow,
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2c: (
    color: primary-base,
    bg: color-transparent,
    border-color: primary-base,
    box-shadow: $btn-box-shadow,
    hover-color: primary-dark,
    hover-bg: color-transparent,
    hover-border-color: primary-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: primary-dark,
    active-bg: color-transparent,
    active-border-color: primary-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: primary-dark,
    selected-bg: color-transparent,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2c-inverted: (
    color: secondary-light,
    bg: color-transparent,
    border-color: secondary-light,
    box-shadow: $btn-box-shadow,
    hover-color: secondary-base,
    hover-bg: color-transparent,
    hover-border-color: secondary-base,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: secondary-base,
    active-bg: color-transparent,
    active-border-color: secondary-base,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: secondary-base,
    selected-bg: color-transparent,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2c-primary: (
    color: neutral-start,
    bg: primary-base,
    border-color: primary-base,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-start,
    hover-bg: primary-dark,
    hover-border-color: primary-dark,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: neutral-start,
    active-bg: primary-dark,
    active-border-color: primary-dark,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-start,
    selected-bg: primary-dark,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2c-primary-inverted: (
    color: primary-base,
    bg: secondary-light,
    border-color: secondary-light,
    box-shadow: $btn-box-shadow,
    hover-color: primary-base,
    hover-bg: secondary-base,
    hover-border-color: secondary-base,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: primary-base,
    active-bg: secondary-base,
    active-border-color: secondary-base,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: primary-base,
    selected-bg: secondary-base,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2b: (
    color: neutral-darkest,
    bg: color-transparent,
    border-color: neutral-darkest,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-end,
    hover-bg: color-transparent,
    hover-border-color: neutral-end,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: neutral-end,
    active-bg: color-transparent,
    active-border-color: neutral-end,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-end,
    selected-bg: color-transparent,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2b-inverted: (
    color: neutral-start,
    bg: color-transparent,
    border-color: neutral-start,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-lightest,
    hover-bg: color-transparent,
    hover-border-color: neutral-lightest,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: neutral-lightest,
    active-bg: color-transparent,
    active-border-color: neutral-lightest,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-lightest,
    selected-bg: color-transparent,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2b-primary: (
    color: neutral-start,
    bg: neutral-end,
    border-color: neutral-end,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-start,
    hover-bg: neutral-darkest,
    hover-border-color: neutral-darkest,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: neutral-start,
    active-bg: neutral-darkest,
    active-border-color: neutral-darkest,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-start,
    selected-bg: neutral-darkest,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  ),
  b2b-primary-inverted: (
    color: neutral-end,
    bg: neutral-start,
    border-color: neutral-start,
    box-shadow: $btn-box-shadow,
    hover-color: neutral-end,
    hover-bg: neutral-lightest,
    hover-border-color: neutral-lightest,
    hover-box-shadow: $btn-hover-box-shadow,
    // START - Missing on design system, TEMPORAL
    active-color: neutral-end,
    active-bg: neutral-lightest,
    active-border-color: neutral-lightest,
    active-box-shadow: $btn-active-box-shadow,
    selected-color: neutral-end,
    selected-bg: neutral-lightest,
    selected-box-shadow: $btn-box-shadow,
    // END - Missing on design system, TEMPORAL
    disabled-color: $btn-disabled-color,
    disabled-bg: $btn-disabled-bg,
    disabled-border-color: $btn-disabled-border-color,
    disabled-box-shadow: $btn-disabled-box-shadow
  )
) !default;

// Button close

$btn-close-size: 1em !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: ui-color(neutral-end) !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-opacity: 0.5 !default;

$btn-close-hover-opacity: 0.75 !default;

$btn-close-focus-opacity: 1 !default;

$btn-close-disabled-opacity: 0.25 !default;

// Forms

$form-text-margin-top: spacer(small) !default;
$form-text-font-scale: caption !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: ui-color(neutral-base) !default;

$form-label-margin-bottom: small !default;
$form-label-font-scale: body !default;
$form-label-font-style: null !default;
$form-label-font-weight: $font-weight-semibold !default;
$form-label-color: neutral-end !default;

// Form control
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-scale: $input-btn-font-scale !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: rem(font-scale-value($input-font-scale, null, line-height) * 1px); //used to calculate input height
$input-color: neutral-end !default;
$input-bg: neutral-start !default;
$input-border-color: neutral-base !default;
$input-border-width: $input-btn-border-width !default;
$input-border-radius: $border-radius-sm !default;
$input-box-shadow: 0 0 0 transparent !default;
$input-transition: $btn-transition !default;
$input-toggle-transition: color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease,
  background-position 0.25s ease;

$input-focus-width: 1px !default;
$input-focus-color: $input-color !default;
$input-focus-bg: $input-bg !default;
$input-focus-border-color: primary-base !default;

$input-disabled-color: neutral-end !default;
$input-disabled-bg: neutral-lightest !default;
$input-disabled-border-color: $input-disabled-bg !default;

$input-placeholder-color: neutral-base !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-scale-sm: $input-btn-font-scale-sm !default;
$input-border-radius-sm: $input-border-radius !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-scale-lg: $input-btn-font-scale-lg !default;
$input-border-radius-lg: $input-border-radius !default;

// Form check
$form-check-stack-gap-y: medium !default;
$form-check-transition: $input-transition !default;

$form-check-label-color: null !default;
$form-check-label-cursor: pointer !default;

$form-check-input-size: rem(
  font-scale-value($input-font-scale, null, line-height) * 1px
); //used to calculate input height
$form-check-input-cursor: $form-check-label-cursor !default;
$form-check-input-bg: $input-bg !default;
$form-check-input-border-color: $input-border-color !default;
$form-check-input-border-width: $input-border-width !default;
$form-check-input-border-radius: $border-radius-sm !default;
$form-check-input-box-shadow: $input-box-shadow !default;

$form-check-input-focus-border-color: null !default;
$form-check-input-focus-box-shadow: null !default;

$form-check-input-checked-color: ui-color(neutral-start) !default;
$form-check-input-checked-bg: primary-base !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-check-input-checked-color}' d='M11.167 16.167a.833.833 0 01-.59-.245L6.655 12l1.178-1.178 3.334 3.333 6.666-6.667 1.179 1.179-7.256 7.255a.833.833 0 01-.59.245z'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $form-check-input-checked-color !default;
$form-check-input-indeterminate-bg: $form-check-input-checked-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $form-check-input-disabled-opacity !default;

$form-check-inline-margin-right: large !default;

// Form check selection
$form-check-selection-padding-y: $input-btn-padding-y !default;
$form-check-selection-padding-x: $input-btn-padding-x !default;
$form-check-selection-color: null !default;
$form-check-selection-bg: $input-bg !default;
$form-check-selection-border-color: $input-border-color !default;
$form-check-selection-border-width: $input-border-width !default;
$form-check-selection-border-radius: $border-radius-sm !default;
$form-check-selection-box-shadow: $input-box-shadow !default;

$form-check-selection-focus-border-color: $input-focus-border-color !default;

$form-check-selection-checked-bg: primary-lightest !default;
$form-check-selection-checked-border-color: primary-base !default;

$form-check-selection-disabled-opacity: $form-check-input-disabled-opacity !default;

$form-check-selection-indicator-size: rem(
  font-scale-value(body, null, line-height) * 1px
); //used to calculate input checkbox height
$form-check-selection-indicator-bg: neutral-light !default;
$form-check-selection-indicator-border-radius: $form-check-input-border-radius !default;

$form-check-selection-indicator-checked-color: ui-color(neutral-start) !default;
$form-check-selection-indicator-checked-bg: primary-base !default;
$form-check-selection-indicator-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='#{$form-check-selection-indicator-checked-color}' d='M11.167 16.167a.833.833 0 01-.59-.245L6.655 12l1.178-1.178 3.334 3.333 6.666-6.667 1.179 1.179-7.256 7.255a.833.833 0 01-.59.245z'/></svg>") !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: ui-color(neutral-lightest) !default;
$input-group-addon-border-color: $input-group-addon-bg !default;

// Form file
$form-file-button-color: neutral-end !default;
$form-file-button-bg: neutral-lightest !default;
$form-file-button-hover-bg: neutral-light !default;

// Form range
$form-range-track-width: 100% !default;
$form-range-track-height: 0.5rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: ui-color(neutral-base) !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow: $box-shadow-inset !default;

$form-range-thumb-width: 1rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: null !default;
$form-range-thumb-focus-box-shadow: null !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: $component-active-bg !default;
$form-range-thumb-disabled-bg: ui-color(neutral-dark) !default;
$form-range-thumb-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Form select
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-scale: $input-font-scale !default;
$form-select-indicator-padding: 1.25rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight !default;
$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $input-border-radius !default;
$form-select-box-shadow: $box-shadow-inset !default;
$form-select-bg-position: right rem(12px) center !default;
$form-select-bg-size: 20px 20px !default;
$form-select-box-shadow: $input-box-shadow !default;
$form-select-transition: $input-transition !default;

$form-select-indicator-color: ui-color(neutral-dark) !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><polygon fill='#{$form-select-indicator-color}' points='5 8 10 13 15 8'></polygon></svg>") !default;

$form-select-feedback-icon-padding-right: add(
  1em * 0.75,
  (2 * spacer(#{$form-select-padding-y}) * 0.75) + spacer(#{$form-select-padding-x}) + $form-select-indicator-padding
) !default;
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size: rem(16px) rem(16px) !default;

$form-select-focus-width: $input-focus-width !default;
$form-select-focus-color: $input-focus-color !default;
$form-select-focus-bg: $input-focus-bg !default;
$form-select-focus-border-color: $input-focus-border-color !default;

$form-select-disabled-color: $input-disabled-color !default;
$form-select-disabled-bg: $input-disabled-bg !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-scale-sm: $input-font-scale-sm !default;
$form-select-border-radius-sm: $form-select-border-radius !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-scale-lg: $input-font-scale-lg !default;
$form-select-border-radius-lg: $form-select-border-radius !default;

// Form toggle
$form-toggle-size: rem(40px) !default;
$form-toggle-color: ui-color(neutral-base) !default;
$form-toggle-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-toggle-color}'/></svg>") !default;
$form-toggle-border-radius: $form-toggle-size !default;

$form-toggle-focus-color: $form-toggle-color !default;
$form-toggle-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-toggle-focus-color}'/></svg>") !default;

$form-toggle-checked-color: $component-active-color !default;
$form-toggle-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-toggle-checked-color}'/></svg>") !default;

// Form validation

$form-feedback-margin-top: small !default;
$form-feedback-font-scale: $form-text-font-scale !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: success-base !default;
$form-feedback-invalid-color: danger-base !default;

$form-validation-states: (
  'valid': (
    'color': $form-feedback-valid-color
  ),
  'invalid': (
    'color': $form-feedback-invalid-color
  )
) !default;

// Alert

$alert-padding-y: large !default;
$alert-padding-x: large !default;
$alert-margin-bottom: large !default;
$alert-color: neutral-end !default;
$alert-bg: neutral-light !default;
$alert-border-width: 1px !default;
$alert-border-color: $alert-bg !default;
$alert-border-radius: $border-radius !default;

$alert-dismissible-padding-r: spacer(#{$alert-padding-x}) * 3 !default; // 3x covers width of x plus default padding on either side

$alert-actions-margin-t: $alert-padding-y !default;

$alert-btn-color: $alert-color !default;
$alert-btn-bg: neutral-lightest !default;

$alert-variants: (
  info: (
    color: neutral-start,
    bg: info-base,
    border-color: info-base,
    btn-color: neutral-start,
    btn-bg: info-dark
  ),
  success: (
    color: neutral-start,
    bg: success-base,
    border-color: success-base,
    btn-color: neutral-start,
    btn-bg: success-dark
  ),
  warning: (
    color: neutral-end,
    bg: warning-base,
    border-color: warning-base,
    btn-color: neutral-end,
    btn-bg: warning-dark
  ),
  danger: (
    color: neutral-start,
    bg: danger-base,
    border-color: danger-base,
    btn-color: neutral-start,
    btn-bg: danger-dark
  )
) !default;

// Avatars

$avatar-font-scale: 0.4375 !default; // initials' font size proportional to size
$avatar-size: rem(32px) !default;
$avatar-color: neutral-start !default;
$avatar-bg: tertiary-base !default;
$avatar-border-radius: 50% !default;

$avatar-size-sm: rem(20px) !default;
$avatar-size-md: rem(40px) !default;
$avatar-size-lg: rem(48px) !default;

$avatar-company-font-scale: 0.65 !default;
$avatar-company-size: rem(40px) !default;
$avatar-company-bg: primary-base !default;
$avatar-company-border-radius: $border-radius !default;

$avatar-company-size-md: rem(48px) !default;
$avatar-company-size-lg: rem(64px) !default;

// Badges

$badge-padding-y: tiny !default;
$badge-padding-x: small !default;
$badge-font-scale: caption !default;
$badge-line-height: 1 !default;
$badge-font-weight: $font-weight-base !default;
$badge-color: neutral-lightest !default;
$badge-bg: neutral-dark !default;
$badge-border-radius: calc(var(--padding-y) * 2 + #{$badge-line-height} * 1rem) !default;

$badge-variants: (
  primary: (
    color: primary-lightest,
    bg: primary-dark
  )
) !default;

// Breadcrumb

$breadcrumb-padding-y: none !default;
$breadcrumb-padding-x: none !default;
$breadcrumb-font-scale: caption !default;
$breadcrumb-margin-bottom: large !default;
$breadcrumb-bg: color-transparent !default;
$breadcrumb-border-radius: 0 !default;

$breadcrumb-item-padding-x: small !default;
$breadcrumb-divider: quote('/') !default;
$breadcrumb-divider-color: neutral-dark !default;
$breadcrumb-active-color: $breadcrumb-divider-color !default;

// Card

$card-padding-y: huge !default;
$card-padding-x: huge !default;
$card-color: null !default;
$card-bg: neutral-start !default;
$card-border-width: 0 !default;
$card-border-color: neutral-darkest !default;
$card-border-radius: $border-radius !default;
$card-box-shadow: $box-shadow !default;

$card-header-color: null !default;
$card-header-bg: null !default;
$card-subtitle-margin-t: tiny !default;
$card-subtitle-color: neutral-dark !default;

$card-footer-color: null !default;
$card-footer-bg: null !default;

$card-variants: (
  subtle: (
    color: null,
    bg: neutral-lightest,
    border-color: neutral-lightest,
    box-shadow: none,
    header-color: null,
    header-bg: null,
    footer-color: null,
    footer-bg: null
  ),
  placeholder: (
    color: null,
    bg: neutral-light,
    border-color: neutral-light,
    box-shadow: inset 0 0 1px ui-color(neutral-dark),
    header-color: null,
    header-bg: null,
    footer-color: null,
    footer-bg: null
  )
) !default;

// Dropdown

$dropdown-min-width: spacer(small) * 16 !default; // 128px
$dropdown-max-width: spacer(small) * 40 !default; // 320px
$dropdown-padding-y: tiny !default;
$dropdown-margin-y: none !default;
$dropdown-font-scale: body !default;
$dropdown-color: null !default;
$dropdown-bg: neutral-start !default;
$dropdown-border-color: neutral-base !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: 1px !default;
$dropdown-box-shadow: $box-shadow-lg !default;
$dropdown-divider-bg: neutral-light !default;
$dropdown-divider-margin-y: $dropdown-padding-y !default;

$dropdown-item-padding-y: medium !default;
$dropdown-item-padding-x: large !default;
$dropdown-item-color: neutral-end !default;
$dropdown-item-hover-color: $dropdown-item-color !default;
$dropdown-item-hover-bg: neutral-lightest !default;
$dropdown-item-selected-color: primary-base !default;
$dropdown-item-selected-bg: primary-lightest !default;
$dropdown-item-disabled-color: neutral-base !default;

$dropdown-header-padding-y: $dropdown-item-padding-y;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-color: neutral-dark !default;

$dropdown-title-color: neutral-dark !default;

// Empty-state

$empty-state-padding: spacer(gigantic) * 2 0;

// Flow

$flow-item-width: rem(480px);

$flow-node-connector-width: rem(16px);
$flow-node-connector-bg: ui-color(neutral-base);
$flow-node-connector-border: solid 3px ui-color(neutral-start);
$flow-node-connector-space-left: spacer(huge);

$flow-connector-line-height: rem(32px);

$flow-placeholder-bg: rgba(ui-color(neutral-base), 0.25);
$flow-placeholder-node-connector-bg: ui-color(neutral-start);
$flow-placeholder-node-connector-border-color: ui-color(neutral-base);

// Lozenges

$lozenge-font-scale: caption !default;
$lozenge-color: neutral-dark !default;

$lozenge-indicator-size: rem(16px) !default;
$lozenge-indicator-bg: neutral-dark !default;

$lozenge-variants: (
  info: (
    color: info-base,
    indicator-bg: info-base
  ),
  success: (
    color: success-base,
    indicator-bg: success-base
  ),
  warning: (
    color: warning-dark,
    indicator-bg: warning-base
  ),
  danger: (
    color: danger-base,
    indicator-bg: danger-base
  )
) !default;

// Nav

$nav-link-padding-y: small !default;
$nav-link-padding-x: large !default;
$nav-link-font-scale: body !default;
$nav-link-font-weight: $font-weight-semibold !default;
$nav-link-color: neutral-dark !default;
$nav-link-transition: $btn-transition !default;
$nav-link-hover-color: neutral-end !default;
$nav-link-selected-color: primary-base !default;
$nav-link-disabled-color: neutral-base !default;

$nav-link-icon-margin-x: small !default;

$nav-tabs-border-color: neutral-lightest !default;
$nav-tabs-border-width: $border-width * 2 !default;

$nav-tabs-link-hover-border-color: $nav-link-hover-color !default;
$nav-tabs-link-selected-color: $nav-link-selected-color !default;
$nav-tabs-link-selected-border-color: $nav-tabs-link-selected-color !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-selected-color: primary-base !default;
$nav-pills-link-selected-bg: primary-lightest !default;

// Navbar

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$navbar-padding-y: 0 !default;
$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: 1rem !default;

$navbar-brand-font-size: 0 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: 0 !default;
$navbar-brand-margin-right: 0 !default;

$navbar-toggler-padding-y: $input-btn-padding-y !default;
$navbar-toggler-padding-x: $input-btn-padding-y !default;
$navbar-toggler-font-size: $font-size-base !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: 1px !default;
$navbar-toggler-transition: null !default;

$navbar-dark-color: rgba(ui-color(neutral-start), 0.85) !default;
$navbar-dark-hover-color: ui-color(neutral-start) !default;
$navbar-dark-active-color: ui-color(neutral-start) !default;
$navbar-dark-disabled-color: rgba(ui-color(neutral-start), 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba(ui-color(neutral-start), 0.1) !default;

$navbar-light-color: ui-color(neutral-dark) !default;
$navbar-light-hover-color: rgba(ui-color(neutral-dark), 0.7) !default;
$navbar-light-active-color: ui-color(primary-base) !default;
$navbar-light-disabled-color: rgba(ui-color(neutral-dark), 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba(ui-color(neutral-dark), 0.1) !default;

$navbar-light-brand-color: ui-color(neutral-dark) !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

$navbar-background-color: ui-color(neutral-start) !default;
$navbar-min-height: rem(64px) !default;
$navbar-box-shadow: 0 1px 0 0 ui-color(neutral-light) !default;

// Modals

$modal-inner-padding: spacer(gigantic) !default;

$modal-title-line-height: $headings-line-height !default;

$modal-content-color: null !default;
$modal-content-bg: $white !default;
$modal-content-border-color: ui-color(neutral-lightest) !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: null !default;
$modal-content-inner-border-radius: null !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: ui-color(neutral-base) !default;
$modal-backdrop-opacity: 0.6 !default;

$modal-header-border-color: ui-color(neutral-lightest) !default;
$modal-header-border-width: $border-width !default;
$modal-header-padding-y: spacer(huge) !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm: 300px !default;
$modal-md: 600px !default;
$modal-lg: 900px !default;
$modal-xl: 1240px !default;

$modal-footer-border-color: $modal-header-border-color !default;
$modal-footer-border-width: $border-width !default;
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Panel

$panel-padding-x: spacer(gigantic);
$panel-padding-y: spacer(huge);
$panel-border-width: $border-width;
$panel-border-color: ui-color(neutral-light);

$panel-bg: ui-color(neutral-start);
$panel-box-shadow: $box-shadow;

$panel-bg-disabled: ui-color(neutral-light);

// Progress

$progress-height: rem(8px) !default;
$progress-font-scale: caption !default;
$progress-line-height: $line-height-sm !default;
$progress-bg: success-base !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: $box-shadow-inset !default;

$progress-bar-color: neutral-start !default;
$progress-bar-bg: success-base !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// Progress height with label
$progress-height-lg: rem(20px) !default;

// Toolbar

$toolbar-bg: ui-color(neutral-start);
$toolbar-box-shadow: 0 1px 0 0 rgba(ui-color(neutral-base), 0.5), $box-shadow;

// Table

$table-cell-padding-y: medium !default;
$table-cell-padding-x: large !default;
$table-cell-padding-y-sm: small !default;
$table-cell-padding-x-sm: $table-cell-padding-x !default;

$table-cell-vertical-align: middle !default;
$table-cell-bg-disabled: ui-color(neutral-light);

$table-color: inherit !default;
$table-bg: color-transparent !default;
$table-margin-bottom: spacer(large) !default;

$table-th-font-scale: caption !default;
$table-th-font-weight: $headings-font-weight !default;
$table-th-color: ui-color(neutral-dark) !default;
$table-th-bg: ui-color(neutral-lightest) !default;

$table-group-separator-color: ui-color(neutral-light) !default;

$table-border-width: 1px !default;
$table-border-color: ui-color(neutral-light) !default;

$table-caption-color: neutral-dark !default;

// Tooltips

$tooltip-font-scale: caption !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: $spacer * 0.25 !default;
$tooltip-padding-x: $spacer * 0.5 !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Code

$code-font-scale: caption !default;
$code-color: danger-base !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-scale: $code-font-scale !default;
$kbd-color: $code-color !default;
$kbd-bg: neutral-lightest !default;

$pre-color: $kbd-color !default;
$pre-bg: $kbd-bg !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

// Transitions
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Variables
//
// Non bootstrap variables and non bootstrap sections
// This must stays decoupled grouped by custom sections

// Page layout structure

$page-max-width: rem(1152px) !default;
$page-width-narrow: rem(768px) !default;

$section-gutter: spacer(gigantic) !default;

$section-width: math.div($page-max-width - $section-gutter, 3) * 2 !default;
$section-width-secondary: math.div($page-max-width - $section-gutter, 3) !default;
$section-width-one-half: ($page-max-width - $section-gutter) * 0.5 !default;
$section-width-one-third: math.div($page-max-width - $section-gutter, 3) !default;

// Vertical divider

$divider-height: rem(32px) !default;
$divider-border: $border-width solid ui-color(neutral-light) !default;
