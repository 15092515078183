// Icons
//
// This library exposes the classes to use the icons.
//
// The import and use of them, can be consulted at <a href="https://github.com/uberforcede/wefox-web-lib-icons" target="__blank">wefox-web-lib-icons library</a>. There you will find the necessary information you need. We can find different types of icons: ui icons, wecons circle icons, wecons helix icons and flags icons.
//
// These icons do not have to be up to date, to check for the latest version please access the link above.
//
// Weight: 3
//
// Style guide: icons

.icon {
    --size: #{$icon-size};
    position: relative;
    display: inline-flex;
    flex-shrink: 0; // side effect: if parent is flex, don't let this shrink
    align-items: center;
    justify-content: center;
    width: var(--size, #{$icon-size});
    height: var(--size, #{$icon-size});
    line-height: 0; // keep this square if children is text
    vertical-align: middle;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    }
}

// Sizes
//
// Make an icon smaller or larger by adding the <code>.icon-sm</code>, <code>.icon-md</code> or <code>.icon-lg</code> classes.
//
// Markup:
// <span>
// <img class="icon icon-sm" src="https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.2.0/svg/ui/user.svg" alt="small"/>
// </span>
// <span>
// <img class="icon" src="https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.2.0/svg/ui/user.svg" alt="normal"/>
// </span>
// <span >
// <img class="icon icon-md" src="https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.2.0/svg/ui/user.svg" alt="medium"/>
// </span>
// <span>
// <img class="icon icon-lg" src="https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.2.0/svg/ui/user.svg" alt="large"/>
// </span>
//
// Weight: 1
//
// Style guide: icons.sizes

.icon-sm {
    @include icon-size($icon-size-sm);
}

.icon-md {
    @include icon-size($icon-size-md);
}

.icon-lg {
    @include icon-size($icon-size-lg);
}

// Loader
//
// A loader is an animated spinning icon that lets users know content is being loaded. The loader is used inside any component like any other <code>.icon</code> element.
//
// Markup:
// <span class="icon icon-sm"><span class="icon-loader"></span></span>
// <span class="icon"><span class="icon-loader"></span></span>
// <span class="icon icon-md"><span class="icon-loader"></span></span>
// <span class="icon icon-lg"><span class="icon-loader"></span></span>
//
// Weight: 2
//
// Style guide: icons.loader

// Customizing the loader
//
// The loader will automatically respect its parent color. You can change the loader color using the text utility classes.
//
// Markup:
// <span class="icon {{modifier_class}}"><span class="icon-loader"></span></span>
//
// .color-primary    - With the <code>.color-primary</code> class
// .color-success    - With the <code>.color-success</code> class
// .color-warning    - With the <code>.color-warning</code> class
//
// Style guide: icons.loader.custom

.icon-loader {
    --size: #{$icon-size};
    display: inline-block;
    width: var(--size, #{$icon-size});
    height: var(--size, #{$icon-size});
    vertical-align: middle;
    border: 3px solid rgba(ui-color(neutral-end), .08);
    border-top: 3px solid currentColor;
    border-radius: 50%;
    animation: spin .54s linear infinite;

    .icon & {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// Icons Library
//
// Style guide: icons.library

// Flag Icons
//
// Markup: icon/_flags.html
//
//
// Style guide: icons.library.flags

// Ui Icons
//
// Markup: icon/_ui.html
//
//
// Style guide: icons.library.ui

// Wecons Circle Icons
//
// Markup: icon/_wecons-circle.html
//
//
// Style guide: icons.library.circle

// Wecons Helix Icons
//
// Markup: icon/_wecons-helix.html
//
//
// Style guide: icons.library.helix
