// Forms
//
// Use forms to allow people to enter data for use by the application, or to configure options.
//
// Forms are used to get information and guide people with minimal fuss. Designing forms well requires making decisions about structure, sequence, interface elements, field labels, help, and feedback.
//
// Weight: 4
//
// Style guide: forms

@import "../../00-settings/index";
@import "../../01-tools/index";

@import "form-control";
@import "form-check";
@import "form-check-selection";
@import "form-range";
@import "form-label";
@import "form-select";
@import "form-text";
@import "form-validation";
@import "input-group";

.form-header {
    margin-bottom: var(--gigantic);
}

.form-title {
    margin-bottom: var(--small);
    @include font-scale(heading-small);
}
