@import "../../00-settings/index";
@import "../../01-tools/index";

// Lozenge
//
// A lozenge is a visual indicator used to highlight an item's status for quick recognition.
//
// Use default lozenges for a general status or state, such as: not started, unavailable, inactive.
//
// Markup: lozenge.html
//
// .lozenge-info        - Use it to represent a new status or state, such as: new, created, help.
// .lozenge-warning     - Use it to represent an in progress or current status or state, such as: draft, in progress, open, modified.
// .lozenge-danger      - Use it to represent a critical or problematic status or state, such as: error, declined, deleted, failed.
// .lozenge-success     - Use it to represent a constructive status or state, such as: active, completed, approved, resolved, added.
//
// Style guide: components.lozenge

// Usage
//
// Use lozenges to communicate the status or state of an action, product, or any entity that needs to move between different phases.
//
// <b>Use cases</b>
//
// <ul><li>When creating an insurance product, it goes trough different statuses: Draft -> Pending -> Active.</li>
// <li> A distributor account can be: Pending (the distributor needs to sign up) or Active (the distributor signed up)</li></ul>
//
// <b>Best practices</b>
//
// <ul><li>Don't use more than 1 lozenge for an entity.</li>
// <li>Combine color with a logical label.</li></ul>
//
// Style guide: components.lozenge.usage

.lozenge {
    --size: #{$lozenge-indicator-size};
    --color: var(--#{$lozenge-color});
    --bg: var(--#{$lozenge-indicator-bg});
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--color, ui-color(#{$lozenge-color}));
    @include font-scale($lozenge-font-scale);

    &::before {
        display: block;
        width: var(--size, #{$lozenge-indicator-size});
        height: var(--size, #{$lozenge-indicator-size});
        margin-right: var(--small, spacer(small));
        background: var(--bg, ui-color(#{$lozenge-indicator-bg}));
        content: "";
    }
}

@each $variant, $property in $lozenge-variants {
    .lozenge-#{$variant} {
        --color: var(--#{map-get($property, color)});
        --bg: var(--#{map-get($property, indicator-bg)});
    }
}
