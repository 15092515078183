@import "../../00-settings/index";
@import "../../01-tools/index";

// Button group
//
// A button group gives users access to frequently performed, related actions. Use button groups when there is a close relationship between a number of buttons.
//
// Markup: button-group.html
//
// Style guide: components.buttongroup

// Usage
//
// This pattern is normally used at the top of the page or component. Common placements of grouped buttons can be found in <code>.card-header</code> actions and <code>.toolbar</code>.
//
// <b>Best practices</b>
//
// <ul><li>Group buttons logically into sets based on usage and importance.
// <li>Ensure that button actions are clear and consistent.</li>
// <li>The main action of a button group can be a primary button.</li>
// <li>Select a single button variant and do not mix them.</li></ul>
//
// <b>Examples</b>
//
// <ol><li>Show different display options for the same organism, eg, 'List vs Grid' view.</li>
// <li>Show different currency options, eg. '&euro; vs $'.</li>
// <li>Show different options to style a text, eg. 'Bold, Italic, Underline'.</li></ol>
//
// Markup: button-group-options.html
//
// Style guide: components.buttongroup.usage

// HTML tags
//
// These classes can also be added to groups of links, as an alternative to the <code>.nav</code> navigation component.
//
// <b>Ensure correct role and provide a label</b>
//
// In order for assistive technologies (such as screen readers) to convey that a series of buttons is grouped, an appropriate <code>role</code> attribute needs to be provided.
//
// In addition, groups should be given an explicit label, as most assistive technologies will otherwise not announce them, despite the presence of the correct role attribute. In the examples provided here, we use aria-label, but alternatives such as aria-labelledby can also be used.
//
// Markup:
// <div class="btn-group" role="group" aria-label="Main navigation">
//     <a href="#" class="btn btn-subtle selected" aria-current="page">Home</a>
//     <a href="#" class="btn btn-subtle">About us</a>
//     <a href="#" class="btn btn-subtle">Contact</a>
// </div>
//
// Weight: 1
//
// Style guide: components.buttongroup.tags

// Checkbox button groups
//
// Combine button-like checkbox into a seamless looking button group.
//
// Markup:
// <div class="btn-group" role="group" aria-label="Sandwich ingredients">
//     <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off">
//     <label class="btn" for="btncheck1">Jamon</label>
//     <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off">
//     <label class="btn" for="btncheck2">Queso</label>
//     <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off">
//     <label class="btn" for="btncheck3">Tomate</label>
// </div>
//
// Weight: 2
//
// Style guide: components.buttongroup.checkbox

// Radio button groups
//
// Combine button-like radios into a seamless toggle component.
//
// Markup:
// <div class="btn-group" role="group" aria-label="Wine types">
//     <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
//     <label class="btn" for="btnradio1">Red wine</label>
//     <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
//     <label class="btn" for="btnradio2">White wine</label>
// </div>
//
// Weight: 3
//
// Style guide: components.buttongroup.radio

// Group multiple button groups together for a toolbar
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input-group {
        width: auto;
    }
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .btn alignment given font-size hack above

    > .btn {
        position: relative;
        flex: 1 1 auto;
    }

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    > .btn-check:checked + .btn,
    > .btn-check:focus + .btn,
    > .btn:hover,
    > .btn:focus,
    > .btn:active,
    > .btn.selected {
        z-index: 1;
    }
}

.btn-group {

    // Prevent double borders when buttons are next to each other
    // Reset border radius
    > .btn {
        margin-left: -$btn-border-width;
        border-radius: 0;

        // Add left border radius if is first of type
        &:first-of-type {
            margin-left: 0;
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
        }

        // Add right border radius if is last child
        &:last-child {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
        }
    }

    // Reset when is inside a child .btn-group
    > .btn-group:not(:first-child) {
        margin-left: -$btn-border-width;

        > .btn:first-of-type {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        > .btn:last-of-type {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
        }
    }
}

// Sizes
//
// Make a button group smaller or larger by adding the <code>.btn-group-sm</code> or <code>.btn-group-lg</code> classes.
//
// Markup:
// <div class="btn-group {{modifier_class}}" role="group" aria-label="Main actions">
//     <button type="button" class="btn btn-square">
//         <span class="icon icon-plus">
//             <svg><use xlink:href="#ui-plus" xmlns:xlink="http://www.w3.org/1999/xlink" /></svg>
//         </span>
//     </button>
//     <button type="button" class="btn btn-square">
//         <span class="icon icon-edit">
//             <svg><use xlink:href="#ui-edit" xmlns:xlink="http://www.w3.org/1999/xlink" /></svg>
//         </span>
//     </button>
//     <button type="button" class="btn btn-square">
//         <span class="icon icon-menu-vertical">
//             <svg><use xlink:href="#ui-menu-vertical" xmlns:xlink="http://www.w3.org/1999/xlink" /></svg>
//         </span>
//     </button>
// </div>
//
// .btn-group-sm - Smaller size
// .btn-group-lg - Larger size
//
// Weight: 4
//
// Style guide: components.buttongroup.sizes

.btn-group-sm > .btn {
    // scale the button
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-scale-sm, $btn-border-radius-sm);

    // scale the icon
    .icon {
        @include icon-size($icon-size-sm);
    }
}

.btn-group-lg > .btn {
    // scale the button
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-scale-lg, $btn-border-radius-lg);

    // scale the icon
    .icon {
        @include icon-size($icon-size-md);
    }
}

//
// Split button dropdowns
//

.dropdown-toggle-split {
    padding-right: calc(var(--#{$btn-padding-x}) * .75);
    padding-left: calc(var(--#{$btn-padding-x}) * .75);

    &::after,
    .dropup &::after,
    .dropright &::after {
        margin-left: 0;
    }

    .dropleft &::before {
        margin-right: 0;
    }
}

.btn-sm + .dropdown-toggle-split {
    padding-right: calc(var(--#{$btn-padding-x-sm}) * .75);
    padding-left: calc(var(--#{$btn-padding-x-sm}) * .75);
}

.btn-lg + .dropdown-toggle-split {
    padding-right: calc(var(--#{$btn-padding-x-lg}) * .75);
    padding-left: calc(var(--#{$btn-padding-x-lg}) * .75);
}

// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.btn-group.show .dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        @include box-shadow(none);
    }
}

//
// Vertical button groups
//

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > .btn,
    > .btn-group {
        width: 100%;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
        margin-top: -$btn-border-width;
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        @include border-bottom-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        @include border-top-radius(0);
    }
}
