.pac-container {
  border: 1px solid var(--neutral-base);
  border-radius: var(--tiny);
  box-shadow: none small small rgb(43 49 58 / 25%);
  padding-bottom: var(--small);
  padding-top: var(--small);

  &.pac-logo::after {
    display: none;
  }
}

.pac-icon {
  display: none;
}

.pac-item {
  border-top: none;
  color: var(--neutral-darkest);
  font-size: var(--medium);
  line-height: var(--medium);
  padding: var(--small) var(--large);

  &:first-child {
    margin-top: var(--small);
  }

  &:last-child {
    margin-bottom: var(--small);
  }
}

.pac-item:hover,
.pac-item:focus {
  background-color: var(--primary-lightest);
}

.pack-item-selected {
  background-color: var(--bg-body);
}

.pac-item-query {
  color: var(--neutral-dark);
  font-size: var(--large);
  line-height: var(--large);

  &::after {
    content: '\a';
    white-space: pre;
  }
}

.pac-matched {
  font-size: var(--large);
}
