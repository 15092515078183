html,
body {
  height: 100%;
  min-height: 100%;
  overflow: inherit;

  &.kss-body {
    overflow: auto;
  }
}

body {
  &.-noScroll {
    overflow: hidden;
  }
}

* {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

b,
strong {
  font-weight: bold;
}

a:hover {
  color: var(--hover-color);
}
