// Text
//
// Style guide: utilities.text

// Emphasis classes
//
// Markup:
// <div>This text is normal, <span class="small">but this text applies the .small class</span></div>
//
// Weight: 2
//
// Style guide: utilities.text.emphasis-classes

.small {
    @extend small;
}

.color-muted {
    a {
        color: $link-color;
    }
}

// Font weight and italics
//
// Markup:
// <p class="fw-bold">Bold text.</p>
// <p class="fw-bolder">Bolder weight text (relative to the parent element).</p>
// <p class="fw-normal">Normal weight text.</p>
// <p class="fw-light">Light weight text.</p>
// <p class="fw-lighter">Lighter weight text (relative to the parent element).</p>
// <p class="fst-italic">Italic text.</p>
// <p class="fst-normal">Text with normal font style</p>
//
// Weight: 2
//
// Style guide: utilities.text.font-weight-and-italics

// Text transform
//
// Markup:
// <p class="text-lowercase">Lowercased text.</p>
// <p class="text-uppercase">Uppercased text.</p>
// <p class="text-capitalize">CapiTaliZed text.</p>
//
// Weight: 2
//
// Style guide: utilities.text.text-transform

// Text decoration
//
// Markup:
// <p class="text-decoration-underline">This text has a line underneath it.</p>
// <p class="text-decoration-line-through">This text has a line going through it.</p>
// <a href="#" class="text-decoration-none">This link has its text decoration removed</a>
//
// Weight: 2
//
// Style guide: utilities.text.text-decoration

// Word break
//
// Prevent long strings of text from breaking your components' layout by using .text-break to set word-wrap: break-word and word-break: break-word. We use word-wrap instead of the more common overflow-wrap for wider browser support, and add the deprecated word-break: break-word to avoid issues with flex containers.
//
// Markup:
// <p class="text-break">mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm</p>
//
// Weight: 2
//
// Style guide: utilities.text.word-break
