//
// GRID .grid object
//

// Create the .grid object using display:grid
@use "sass:math";

@mixin make-grid($gap: $gap-default) {
    --gap-y: var(--none);
    --gap-x: var(--#{$gap});
    display: grid;
    gap: var(--gap-y, spacer(none)) var(--gap-x, spacer(#{$gap}));
}

// Specify .cols-# to generate the number of equal columns.
@mixin grid-cols($count) {
    grid-template-columns: repeat($count, 1fr);
}

//Generate the correct number of grid template columns given any value of `$grid-cols`.
@mixin make-grid-columns($breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {

            @if $grid-cols > 0 {
                @for $i from 1 through $grid-cols {
                    .cols#{$infix}-#{$i} {
                        @include grid-cols($i);
                    }
                }
            }
        }
    }
}

//
// ROW .row object
//

// Create the .row object using display:flex
@mixin make-row($gap: $gap-default) {
    --gap-y: var(--none);
    --gap-x: var(--#{$gap});
    display: flex;
    flex-wrap: wrap;
    // the negative margins are needed until we can use directly the gap property for flexbox
    margin-top: calc(var(--gap-y) * -1);
    margin-right: calc(var(--gap-x) / -2);
    margin-left: calc(var(--gap-x) / -2);
}

@mixin make-row-col-ready($gap: $gap-default) {
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we set the width
    // later on to override this initial width.
    flex-shrink: 0;
    // Add box sizing if only the grid is loaded
    box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
    width: 100%;
    max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
    margin-top: var(--gap-y);
    // the padding-y is needed until we can use directly the gap property for flexbox
    padding-right: calc(var(--gap-x) / 2);
    padding-left: calc(var(--gap-x) / 2);
}

@mixin make-row-col($size, $columns: $row-col) {
    flex: 0 0 auto;
    width: percentage(math.div($size, $columns));
}

@mixin make-row-col-auto() {
    flex: 0 0 auto;
    width: auto;
}

@mixin make-row-col-offset($size, $columns: $row-col) {
    $num: math.div($size, $columns);
    margin-left: if($num == 0, 0, percentage($num));
}

// Specify on a parent element to force immediate children into NN number of equal columns.
@mixin row-cols($count) {
    > [class*="col"] {
        flex: 0 0 auto;
        width: math.div(100%, $count);
    }
}

// Generate the correct number of grid classes given any value of `$row-col`.
@mixin make-row-columns($columns: $row-col, $gap: $gap-default, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            // Provide basic `.col-{bp}` classes for equal-width flexbox columns
            .col#{$infix} {
                flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            }

            .cols#{$infix}-auto > [class*="col"] {
                @include make-row-col-auto();
            }

            @if $grid-cols > 0 {
                @for $i from 1 through $grid-cols {
                    .cols#{$infix}-#{$i} {
                        @include row-cols($i);
                    }
                }
            }

            .col#{$infix}-auto {
                @include make-row-col-auto();
            }

            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .col#{$infix}-#{$i} {
                        @include make-row-col($i, $columns);
                    }
                }

                // `$columns - 1` because offsetting by the width of an entire row isn't possible
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
                        .offset#{$infix}-#{$i} {
                            @include make-row-col-offset($i, $columns);
                        }
                    }
                }
            }
        }
    }
}

//
// GAP
//

// Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
@mixin make-gaps($breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {

            @each $key, $value in $gaps {
                .g#{$infix}-#{$key},
                .gx#{$infix}-#{$key} {
                    --gap-x: var(--#{$key});
                }

                .g#{$infix}-#{$key},
                .gy#{$infix}-#{$key} {
                    --gap-y: var(--#{$key});
                }
            }
        }
    }
}
