.ant-select:not(.ant-pagination-options-size-changer) .ant-select-selector {
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
  height: 50px;
  padding: 10px 15px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 50px;

  &:focus-visible {
    box-shadow: none;
  }
}

.ant-select:not(.ant-select-disabled) .ant-select-selector {
  border-color: var(--neutral-base);
  color: var(--primary-100);
}

.ant-select-dropdown {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 12%);
}