.anticon-double-right {
  color: var(--primary-base) !important;
}

.ant-pagination-item-link {
  color: var(--primary-base) !important;

  &:hover {
    border-color: var(--primary-base) !important;
  }
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item:hover {
  border-color: var(--primary-base);
}

.ant-pagination-item a,
.ant-pagination-item:hover a {
  color: var(--primary-base);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-base);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary-base);
}

.ant-table-pagination,
.ant-pagination {
  margin: 32px 0 16px !important;
}

.ant-select-item-option,
.ant-select-selection-item {
  color: var(--primary-base) !important;
}

.ant-select-item-option-selected {
  background-color: var(--primary-lightest) !important;
}
