/* stylelint-disable no-descending-specificity */
.ant-drawer {
  height: 100%;
  position: fixed;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
  width: 0%;
  z-index: 1000;
}

.ant-drawer-content-wrapper {
  height: 100%;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
}

.ant-drawer .ant-drawer-content {
  height: 100%;
  width: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
  height: 100%;
  top: 0;
  width: 0%;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
}

.ant-drawer-left {
  left: 0;
}

.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgb(0 0 0 / 8%), 9px 0 28px 0 rgb(0 0 0 / 5%), 12px 0 48px 16px rgb(0 0 0 / 3%);
}

.ant-drawer-right {
  right: 0;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%), -12px 0 48px 16px rgb(0 0 0 / 3%);
}

.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}

.ant-drawer-top,
.ant-drawer-bottom {
  height: 0%;
  left: 0;
  width: 100%;
}

.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}

.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer-top {
  top: 0;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgb(0 0 0 / 8%), 0 9px 28px 0 rgb(0 0 0 / 5%), 0 12px 48px 16px rgb(0 0 0 / 3%);
}

.ant-drawer-bottom {
  bottom: 0;
}

.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  height: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: none;
}

.ant-drawer-title {
  color: rgb(0 0 0 / 85%);
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}

.ant-drawer-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.ant-drawer-close {
  background: transparent;
  border: 0;
  color: rgb(0 0 0 / 45%);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-right: 12px;
  outline: 0;
  text-align: center;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  transition: color 0.3s;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgb(0 0 0 / 75%);
  text-decoration: none;
}

.ant-drawer-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  color: rgb(0 0 0 / 85%);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  position: relative;
}

.ant-drawer-header-title {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.ant-drawer-header-close-only {
  border: none;
  padding-bottom: 0;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
}

.ant-drawer-body {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5715;
  overflow: auto;
  padding: 24px;
  word-wrap: break-word;
}

.ant-drawer-footer {
  border-top: 1px solid #f0f0f0;
  flex-shrink: 0;
  padding: 10px 16px;
}

.ant-drawer-mask {
  background-color: rgb(0 0 0 / 45%);
  height: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  width: 100%;
}

.ant-drawer .ant-picker-clear {
  background: #fff;
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-left: 12px;
  margin-right: 0;
}