@import "../../00-settings/index";
@import "../../01-tools/index";

// Progress bar
//
// A progress bar displays the progress through a journey.
//
// Markup: progress.html
//
// Style guide: components.progress

// Label
//
// Add a label when the progress needs to be more accurate than just the bar. Add <code>.progress-lg</code> modifier to accommodate the label.
//
// Markup:
// <div class="progress progress-lg">
//     <div class="progress-bar" role="progressbar" style="width:43%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">43%</div>
// </div>
//
// Weight: 1
//
// Style guide: components.progress.label

.progress {
    --height: #{$progress-height};
    --bg: var(--#{$progress-bg});
    --border-radius: #{$progress-border-radius};
    --bar-color: var(--#{$progress-bar-color});
    --bar-bg: var(--#{$progress-bar-bg});

    display: flex;
    height: var(--height, #{$progress-height});
    overflow: hidden; // force rounded corners by cropping it
    @include font-scale($progress-font-scale);
    line-height: $progress-line-height;
    background: var(--bg, ui-color(#{$progress-bg}));
    border-radius: var(--border-radius, #{$progress-border-radius});
    box-shadow: var(--box-shadow, #{$progress-box-shadow});
}

.progress-lg {
    --height: #{$progress-height-lg};
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bar-color, ui-color(#{$progress-bar-color}));
    white-space: nowrap;
    text-align: center;
    background: var(--bar-bg, ui-color(#{$progress-bar-bg}));
    @include transition($progress-bar-transition);
}

// Config options
//
// <pre><code class="sass">$progress-height:                       rem(8px) !default;
// $progress-font-size:                    $font-size-sm !default;
// $progress-line-height:                  $line-height-sm !default;
// $progress-bg:                           success-base !default;
// $progress-border-radius:                $border-radius !default;
// $progress-box-shadow:                   $box-shadow-inset !default;
//
// $progress-bar-color:                    neutral-start !default;
// $progress-bar-bg:                       success-base !default;
// $progress-bar-transition:               width .6s ease !default;</code></pre>
//
// Weight: 10
//
// Style guide: components.progress.config
