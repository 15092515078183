@import "../../00-settings/index";
@import "../../01-tools/index";

// Avatar
//
// An avatar is a visual representation of an user or a company and supports a square image/logo or initials.
//
// Use the default circle (<code>.avatar</code>) to represent a person or a square avatar (<code>.avatar-company</code>) to represent a company.
//
// Markup: avatar.html
//
// Style guide: components.avatar

// User
//
// Use round avatars to quickly identify users. When no image is available, user initials can be used in place of it.
//
// .avatar-sm - Smaller size
// .avatar-md - Medium size
// .avatar-lg - Larger size
//
// Markup: avatar-user.html
//
// Weight: 1
//
// Style guide: components.avatar.user

.avatar {
    --size: #{$avatar-size};
    --color: var(--#{$avatar-color});
    --bg: var(--#{$avatar-bg});
    --font-scale: #{$avatar-font-scale};
    --border-radius: #{$avatar-border-radius};

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size, #{$avatar-size});
    height: var(--size, #{$avatar-size});
    overflow: hidden;
    color: var(--color, ui-color(#{$avatar-color}));
    font-size: calc(var(--size) * var(--font-scale));
    vertical-align: middle;
    background: var(--bg, ui-color(#{$avatar-bg}));
    border-radius: var(--border-radius, #{$avatar-border-radius});

    img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.avatar[data-initial]::before {
    z-index: 1;
    color: currentColor;
    content: attr(data-initial);
}

.avatar-sm {
    @include avatar-size($avatar-size-sm);
}

.avatar-md {
    @include avatar-size($avatar-size-md);
}

.avatar-lg {
    @include avatar-size($avatar-size-lg);
}

// Company
//
// Use square avatars to help identify our or other companies. When no logo is available, company's first letter is used in place of it.
// The <code>.user-company</code> background is the <code>primary-base</code> color token.
//
// .avatar-md - Medium size
// .avatar-lg - Larger size
//
// Markup: avatar-company.html
//
// Weight: 2
//
// Style guide: components.avatar.company

// Custom background and color
//
// The avatar's text and background colors can be changed <u>inline</u> replacing the values of the CSS native variables <code>--color</code> and <code>--bg</code>.
//
// Markup:
// <figure class="avatar avatar-company" data-initial="S" style="--bg:#00704a"></figure>
// <figure class="avatar avatar-company" data-initial="C" style="--bg:#F40009"></figure>
// <figure class="avatar avatar-company" data-initial="P" style="--bg:#004B93"></figure>
// <figure class="avatar avatar-company" data-initial="F" style="--bg:#3b5998"></figure>
// <figure class="avatar avatar-company" data-initial="P" style="--color:#e60023; --bg:#efefef"></figure>
//
// Weight: 3
//
// Style guide: components.avatar.custom

.avatar-company {
    --size: #{$avatar-company-size};
    --bg: var(--#{$avatar-company-bg});
    --font-scale: #{$avatar-company-font-scale};
    --border-radius: #{$avatar-company-border-radius};

    &.avatar-md {
        @include avatar-size($avatar-company-size-md);
    }

    &.avatar-lg {
        @include avatar-size($avatar-company-size-lg);
    }
}

// Config options
//
// <pre><code class="sass">$avatar-font-scale:                 .4375 !default; // initials' font size proportional to size
// $avatar-size:                       rem(32px) !default;
// $avatar-color:                      neutral-start !default;
// $avatar-bg:                         tertiary-base !default;
// $avatar-border-radius:              50% !default;
//
// $avatar-size-sm:                    rem(20px) !default;
// $avatar-size-md:                    rem(40px) !default;
// $avatar-size-lg:                    rem(48px) !default;
//
// $avatar-company-font-scale:         .65 !default;
// $avatar-company-size:               rem(40px) !default;
// $avatar-company-bg:                 primary-base !default;
// $avatar-company-border-radius:      $border-radius !default;
//
// $avatar-company-size-md:            rem(48px) !default;
// $avatar-company-size-lg:            rem(64px) !default;</code></pre>
//
// Weight: 4
//
// Style guide: components.avatar.config
