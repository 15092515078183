.c-inputDropdownOptionsPanel {
  align-self: center;
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 12%);
  box-sizing: border-box;
  color: var(--neutral-darkest);
  display: block;
  left: 0;
  margin-top: 4px;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  right: 0;
  top: 82px;
  transition: border-color 0.2s ease, border-width 0.2s ease;
  transition-duration: 3s;
  width: auto;
  z-index: 100;

  &:focus {
    outline: none;
  }

  &.-invalid {
    top: 82px;
  }

  &.-compact {
    max-height: 156px;
  }

  &.-compressed {
    top: 40px;
  }

  &.-noLabel {
    text-align: left;
    top: 50px;
  }

  .c-inputDropdownOption {
    min-height: 38px;
    padding: 10px 16px;

    &:hover {
      background-color: var(--neutral-lightest);
      cursor: pointer;
    }

    &.-selected {
      background-color: var(--primary-lightest);
      color: var(--primary-base);
    }
  }
}

.c-inputDropdownContainer {
  display: flex;
  position: relative;
  width: 100%;

  input.-compressed {
    height: 42px;
  }
}

.c-inputDropdownContainerFlex {
  flex: 1 auto;
  position: relative;
}

.c-inputDropdownDummyClick {
  inset: 0;
  position: absolute;
}
