/* stylelint-disable no-duplicate-selectors */
.c-inputRadio {
  input[type='radio'] {
    appearance: none;
    background-color: var(--neutral-start);
    border: 2px solid var(--primary-base);
    border-radius: 20px;
    cursor: pointer;
    height: 1px;
    margin-right: 16px;
    outline: 0;
    padding: 9px;
    position: relative;
    width: 1px;

    &:checked {
      &::after {
        background-color: var(--primary-base);
        border-radius: 100px;
        content: '';
        height: 8px;
        left: 5px;
        position: absolute;
        top: 5px;
        width: 8px;
      }
    }

    &.-noBorder {
      margin-left: 0;
    }

    &.-invalid {
      border: 2px solid var(--danger-base);
    }

    &.-disabled {
      border: 2px solid var(--neutral-dark);

      &:checked {
        &::after {
          background-color: var(--neutral-dark);
        }
      }
    }
  }
}

.c-inputRadio {
  align-items: center;
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: flex-start;
  margin-left: 0;
  min-height: 50px;
  outline-color: var(--neutral-start);
  overflow: hidden;
  padding: 9px 24px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;

  &.-noBorder {
    border: none;
    cursor: default;
    margin-left: 0 !important;
    margin-right: 24px;
    padding: 9px 8px 0;

    & input {
      cursor: pointer;
    }

    &.-invalid {
      border: none;
    }
  }

  &.-block {
    display: flex;
  }

  &.-disabled {
    color: var(--neutral-dark);
    pointer-events: none;
  }

  &.-invalid {
    border: 1px solid var(--danger-base);
    color: var(--danger-base);
  }
}

.c-inputRadio.-small {
  margin-left: 14px;
  margin-right: 18px;
  min-height: 40px;
  padding: 0;

  &.-noBorder {
    margin-right: 0;
  }

  input[type='radio'] {
    padding: 8px;

    &:checked {
      &::after {
        height: 10px;
        left: 3px;
        top: 3px;
        width: 10px;
      }
    }
  }
}
/* stylelint-enable no-duplicate-selectors */
