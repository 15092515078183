form {
    > *,
    fieldset > *,
    .col {
        margin-bottom: spacer(huge);
    }

    fieldset,
    > button,
    > *:last-child,
    > .row {
        margin-bottom: inherit;
    }
}

legend {
    color: $form-label-color;
    @include font-scale($form-label-font-scale);
    text-transform: uppercase;
}
