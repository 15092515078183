// Layout
//
// Weight: 3
//
// Style guide: foundations.layout

// Containers
//
// Containers are a fundamental building block that contain, pad, and align your content within a given device or viewport.
//
// How they work
//
// Containers are the most basic layout element and are required when using our default grid system.
// Containers are used to contain, pad, and (sometimes) center the content within them.
//
// While containers can be nested, most layouts do not require a nested container.
// The library comes with three different containers:
// <ul><li><code>.container</code>, which sets a max-width at each responsive breakpoint</li>
// <li><code>.container-fluid</code>, which is width: 100% at all breakpoints</li>
// <li><code>.container-{breakpoint}</code>, which is width: 100% until the specified breakpoint</li></ul>
//
// The table below illustrates how each container’s <code>max-width</code> compares to the original
// <code>.container</code> and <code>.container-fluid</code> across each breakpoint.
//
// <table class="table">
//   <thead>
//     <tr>
//       <td class="border-dark"></td>
//       <th scope="col">
//         Mobile<br>
//         <span class="fw-normal">&lt;600px</span>
//       </th>
//       <th scope="col">
//         Tablet<br>
//         <span class="fw-normal">&ge;600px</span>
//       </th>
//       <th scope="col">
//         Laptop<br>
//         <span class="fw-normal">&ge;900px</span>
//       </th>
//       <th scope="col">
//         Desktop<br>
//         <span class="fw-normal">&ge;1200px</span>
//       </th>
//       <th scope="col">
//         TV<br>
//         <span class="fw-normal">&ge;1800px</span>
//       </th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td>1080px</td>
//       <td>1080px</td>
//       <td>1080px</td>
//     </tr>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container-mobile</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td>1080px</td>
//       <td>1080px</td>
//       <td>1080px</td>
//     </tr>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container-tablet</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td>1080px</td>
//       <td>1080px</td>
//       <td>1080px</td>
//     </tr>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container-desktop</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td>1080px</td>
//       <td>1080px</td>
//       <td>1080px</td>
//     </tr>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container-tv</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td>1080px</td>
//       <td>1080px</td>
//       <td>1080px</td>
//     </tr>
//     <tr>
//       <th scope="row" class="fw-normal"><code>.container-fluid</code></th>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//       <td class="color-muted">100%</td>
//     </tr>
//   </tbody>
// </table>
//
// Weight: 1
//
// Style guide: foundations.layout.containers

@if $enable-grid {
    // Single container class with breakpoint max-widths
    .container,
    .container-fluid {
        @include make-container();
    }

    // Responsive containers that are 100% wide until a breakpoint
    @each $breakpoint, $container-max-width in $container-max-widths {
        .container-#{$breakpoint} {
            @extend .container-fluid;
        }

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            %responsive-container-#{$breakpoint} {
                max-width: $container-max-width;
            }

            // Extend each breakpoint which is smaller or equal to the current breakpoint
            $extend-breakpoint: true;

            @each $name, $width in $grid-breakpoints {
                @if ($extend-breakpoint) {
                    .container#{breakpoint-infix($name, $grid-breakpoints)} {
                        @extend %responsive-container-#{$breakpoint};
                    }

                    // Once the current breakpoint is reached, stop extending
                    @if ($breakpoint == $name) {
                        $extend-breakpoint: false;
                    }
                }
            }
        }
    }
}
