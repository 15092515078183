// Space
//
// The spacing system is managed using spacing tokens.
//
// Spacing tokens allow us to bridge design to code by speaking the same language, push spacing updates within the library, and keep 100% consistency. The same spacing map and token naming convention is used in the design tools.
//
// <b>Different kits:</b> For a different kit, the same tokens can be mapped to different values, optional.
//
//  <table class="table table-sm">
//      <thead>
//          <tr><th>Token name</th><th>SCSS variable</th><th>CSS variable</th>
//      </thead>
//      <tbody>
//          <tr><td>tiny</td><td>tiny</td><td>var(--tiny)</td></tr>
//          <tr><td>small</td><td>small</td><td>var(--small)</td></tr>
//          <tr><td>medium</td><td>medium</td><td>var(--medium)</td></tr>
//          <tr><td>large</td><td>large</td><td>var(--large)</td></tr>
//          <tr><td>huge</td><td>huge</td><td>var(--huge)</td></tr>
//          <tr><td>gigantic</td><td>gigantic</td><td>var(--gigantic)</td></tr>
//          <tr><td>huge2x</td><td>huge2x</td><td>var(--huge2x)</td></tr>
//          <tr><td>gigantic2x</td><td>gigantic2x</td><td>var(--gigantic2x)</td></tr>
//          <tr><td>gigantic4x</td><td>gigantic4x</td><td>var(--gigantic4x)</td></tr>
//      </tbody>
//  </table>
//
// Style guide: tokens.space

// Config and update
//
// <pre><code class="sass">$spacer:                rem(4px) !default;
// $spacers: (
//    none:               0,
//    tiny:               $spacer,
//    small:              $spacer * 2,
//    medium:             $spacer * 3,
//    large:              $spacer * 4,
//    huge:               $spacer * 6,
//    gigantic:           $spacer * 8,
//    huge2x:             $spacer * 12,
//    gigantic2x:         $spacer * 16,
//    gigantic4x:         $spacer * 32,
// ) !default;</code></pre>
//
// Weight: 1
//
// Style guide: tokens.space.config

// Usage
//
// <div class="alert alert-warning"><b>!Important:</b> We prioritize the use of CSS native variables over hardcoded values so we can change them in the DOM, and not at the build time. Think same atoms inside different organism can be easily changed!</div>
//
// Therefore, in <code>variables.scss</code> we only need to declare the <code>token</code> name. In <code>component.scss</code> we can get its value by using the <code>spacer(token)</code> mixins.
//
// <pre><code class="sass">     // variables.scss
//      $btn-padding-y: small;
//      $btn-padding-x: large;
//
//      // button.scss
//      .btn {
//          // define the CSS native variable
//          --padding-y: var(--#{$btn-padding-y});
//          --padding-x: var(--#{$btn-padding-x});
//
//          // asign the CSS native variable
//          padding: var(--padding-y, spacer(#{$btn-padding-y})) var(--padding-x, spacer(#{$btn-padding-x}));
//      }
//
//      // CSS output
//      .btn {
//          --padding-y: var(--small);
//          --padding-x: var(--large);
//          padding: var(--padding-y, .5rem) var(--padding-x, 1rem);
//      }
// </code></pre>
//
// Weight: 2
//
// Style guide: tokens.space.usage

$spacer:                rem(4px) !default;
$spacers: (
    none:               0,
    tiny:               $spacer,
    small:              $spacer * 2,
    medium:             $spacer * 3,
    large:              $spacer * 4,
    huge:               $spacer * 6,
    gigantic:           $spacer * 8,
    huge2x:             $spacer * 12,
    gigantic2x:         $spacer * 16,
    gigantic4x:         $spacer * 32,
) !default;
