@import "../../00-settings/index";
@import "../../01-tools/index";

// Panel
//
// A panel is a container used to group related information and actions about a single subject.
//
// Style guide: components.panel

// Usage
//
// A panel is made up of 3 sections:
// <ol><li><b>Header</b> section is optional and has limitations.</li>
// <li><b>Body</b> section is optional and can accommodate any layout of related information.</li>
// <li><b>Footer</b> section is optional and has limitations.</li></ol>
//
// When the panel doesn't include a <code>.panel-header</code> section, a title/heading should exist in the <code>.panel-body</code> section.
// You can use the class <code>.panel-actions</code> inside <code>.panel-header</code> to set a dropdown on the top right of the panel
//
// Markup: panel.html
//
// Weight: 1
//
// Style guide: components.panel.usage

.panel {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $panel-bg;
    box-shadow: $panel-box-shadow;

    &.disabled {
        background-color: $panel-bg-disabled;
        box-shadow: none !important;
    }
}

.panel-header,
.panel-body,
.panel-footer {
    padding: $panel-padding-y $panel-padding-x;
}

.panel-header,
.panel-footer {
    flex: 0 0 auto;
}

.panel-body {
    flex: 1 1 auto;
    overflow-y: auto;

    .panel-header + & {
        padding-top: 0;
    }
}

.panel-footer {
    border-top: $panel-border-width solid $panel-border-color;
}

.panel-title {
    margin-bottom: 0;
    @include font-scale(heading-medium);
}

// Simple panel
//
// Markup: panel-simple.html
//
// Weight: 2
//
// Style guide: components.panel.simple
