// Blockquote
//
// Markup:
// <div class="blockquote">
//     <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
// </div>
//
// Weight: 3
//
// Style guide: foundations.typography.blockquote

// Blockquotes
.blockquote {
    margin-bottom: spacer(tiny);
    @include font-scale($blockquote-font-scale);
}
