.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: var(--primary-light) !important;
}

.ant-spin {
  color: var(--primary-dark) !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-table-row:hover {
  background-color: none;
}

.ant-table-expanded-row {
  background-color: none;
}
