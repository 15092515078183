// Hide content visually while keeping it accessible to assistive technologies
//
// See: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin visually-hidden() {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    padding: 0 !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    border: 0 !important;
    clip: rect(0, 0, 0, 0) !important;
}

// Use to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

@mixin visually-hidden-focusable() {
    &:not(:focus) {
        @include visually-hidden();
    }
}
