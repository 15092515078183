.wg-badge-container {
  display: flex;
  position: relative;

  .badge {
    align-items: center;
    align-self: center;
    background-color: var(--primary-base);
    color: #fff;
    display: flex;
    max-height: 25px;

    &.top {
      top: -15px;
    }

    &.bottom {
      bottom: -15px;
    }

    &.left {
      left: -15px;
    }

    &.right {
      right: -15px;
    }
  }
}
