@import "../../00-settings/index";
@import "../../01-tools/index";

// Breadcrumb
//
// Breadcrumbs are a navigation component used to show a user's location in a site or app. It indicates the current page’s location within a navigational hierarchy.
//
// Markup: breadcrumb.html
//
// Style guide: components.breadcrumb

// Usage
//
// Breadcrumbs are an alternative way to help users orient themselves. They're a useful addition to, but shouldn’t replace, the main navigation on a page.
//
// Use breadcrumbs for nested navigation, with each item acting as a link.
// They show the hierarchical progress from the highest item level to the lowest, one step at a time. This typically starts with the product landing page and goes to the current page or content.
//
// <b>Best practices</b>
// <ul><li>Use breadcrumbs when the user is most likely to have landed on the page from an external source.</li>
// <li>Use for large websites and complex products that have hierarchically arranged pages, so that users who land on the page can quickly know where they are.</li>
// <li>In products, breadcrumbs can adapt to the state of navigation by being toggled on, off, or auto-collapsing.</li>
// <li>Make sure breadcrumbs don’t visually overwhelm the page.</li>
// <li>Place breadcrumbs at the top left corner of the screen, above the page title.</li></ul>
//
// Style guide: components.breadcrumb.usage

.breadcrumb {
    --padding-y: var(--#{$breadcrumb-padding-y});
    --padding-x: var(--#{$breadcrumb-padding-x});
    --margin-bottom: var(--#{$breadcrumb-margin-bottom});
    --bg: var(--#{$breadcrumb-bg});
    --border-radius: #{$breadcrumb-border-radius};

    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--margin-bottom, spacer(#{$breadcrumb-margin-bottom}));
    padding: var(--padding-y, spacer(#{$breadcrumb-padding-y})) var(--padding-x, spacer(#{$breadcrumb-padding-x}));
    @include font-scale($breadcrumb-font-scale);
    list-style: none;
    background: var(--bg, ui-color(#{$breadcrumb-bg}));
    border-radius: var(--border-radius, #{$breadcrumb-border-radius});
}

.breadcrumb-item {
    --padding-x: var(--#{$breadcrumb-item-padding-x});
    --active-color: var(--#{$breadcrumb-active-color});
    --divider-color: var(--#{$breadcrumb-divider-color});

    display: flex;
    align-items: center;
    font-weight: $font-weight-semibold;

    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: var(--padding-x, spacer(#{$breadcrumb-item-padding-x}));

        &::before {
            display: inline-block; // Suppress underlining of the separator
            padding-right: var(--padding-x, spacer(#{$breadcrumb-item-padding-x}));
            color: var(--divider-color, ui-color(#{$breadcrumb-divider-color}));
            content: escape-svg($breadcrumb-divider);
        }
    }

    &.active {
        color: var(--active-color, ui-color(#{$breadcrumb-active-color}));
        font-weight: $font-weight-normal;
    }

    .icon:first-child {
        margin-right: var(--small);
    }
}

// Config options
//
// <pre><code class="sass">$breadcrumb-padding-y:                      0 !default;
// $breadcrumb-padding-x:                      0 !default;
// $breadcrumb-font-size:                      $font-size-sm !default;
// $breadcrumb-line-height:                    $line-height-sm !default;
// $breadcrumb-margin-bottom:                  large !default;
// $breadcrumb-bg:                             --color-transparent !default;
// $breadcrumb-border-radius:                  0 !default;
//
// $breadcrumb-item-padding-x:                 small !default;
// $breadcrumb-divider:                        quote("/") !default;
// $breadcrumb-divider-color:                  neutral-dark !default;
// $breadcrumb-active-color:                   $breadcrumb-divider-color !default;</code></pre>
//
// Separators are automatically added in CSS through <code>::before</code> and <code>content</code>. They can be changed by changing <code>$breadcrumb-divider</code>.
// The sass <code>quote</code> function is needed to generate the quotes around a string, so if you want <code>></code> as separator, you can use this:
//
// <pre><code class="sass">$breadcrumb-divider: quote(">");</code></pre>
//
// It’s also possible to use an embedded SVG icon like this:
//
// <pre><code class="sass">$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");</code></pre>
//
// The separator can be removed by setting <code>$breadcrumb-divider</code> to <code>none</code>:
//
// <pre><code class="sass">$breadcrumb-divider: none;</code></pre>
//
// Weight: 1
//
// Style guide: components.breadcrumb.config
