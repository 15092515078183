@import "../../00-settings/index";
@import "../../01-tools/index";

// Toolbar
//
// A toolbar is normally used for grouping the other components that contains, and to set the right background and shadow to the container.
//
// Style guide: components.toolbar

// Usage
//
// The toolbar should be used along with a navbar, like the following example
//
// Markup: toolbar.html
//
// Weight: 1
//
// Style guide: components.toolbar.usage

.toolbar {
    background-color: $toolbar-bg;
    box-shadow: $toolbar-box-shadow;
}
