@import "../../00-settings/index";
@import "../../01-tools/index";

// Badge
//
// A badge is a visual indicator for numeric values such as tallies and scores. They can also be used as a tag to label the entity they are part of.
// Badges are usually placed before or after the label of the element they're quantifying, such as the number of distributors for a product or its category.
//
// The default form <code>.badge</code> should be used most of the times. Use the <code>.badge-primary</code> to help draw attention to new or updated information.
//
// Markup: badge.html
//
// .badge-primary       - When you need to highlight new or updated information
//
// Style guide: components.badge

// Usage
//
// Badges can be used as part of links or buttons to provide a counter. Note that depending on how they are used, badges may be confusing for users of screen readers and similar assistive technologies.
//
// While the styling of badges provides a visual cue as to their purpose, these users will simply be presented with the content of the badge. Depending on the specific situation, these badges may seem like random additional words or numbers at the end of a sentence, link, or button.
//
//
// <b>Best practices</b>
//
// <ul><li>Use badges in conjunction with a single item or label, to avoid ambiguity around which item is being quantified.</li>
// <li>Badges should include tooltips, where necessary, to enhance user understanding. For example, a badge is used in conjunction with an icon.</li>
// <li>Unless the context is clear (as with the “Notifications” example, where it is understood that the “4” is the number of notifications), consider including additional context with a visually hidden piece of additional text.</li></ul>
//
// Markup: badge-usage.html
//
// Weight: 1
//
// Style guide: components.badge.usage

.badge {
    --padding-y: var(--#{$badge-padding-y});
    --padding-x: var(--#{$badge-padding-x});
    --color: var(--#{$badge-color});
    --bg: var(--#{$badge-bg});
    --border-radius: #{$badge-border-radius};

    display: inline-block;
    padding: var(--padding-y, spacer(#{$badge-padding-y})) var(--padding-x, spacer(#{$badge-padding-x}));
    color: var(--color, ui-color(#{$badge-color}));
    @include font-scale($badge-font-scale);
    font-weight: $badge-font-weight;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    background: var(--bg, ui-color(#{$badge-bg}));
    border-radius: var(--border-radius, #{$badge-border-radius});

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Generate contextual modifier classes for colorizing the badge.

@each $variant, $property in $badge-variants {
    .badge-#{$variant} {
        --color: var(--#{map-get($property, color)});
        --bg: var(--#{map-get($property, bg)});
    }
}

// Config options
//
// <pre><code class="sass">$badge-padding-y:                   tiny !default;
// $badge-padding-x:                   small !default;
// $badge-font-size:                   $font-size-sm !default;
// $badge-line-height:                 1 !default;
// $badge-font-weight:                 $font-weight-normal !default;
// $badge-color:                       neutral-lightest !default;
// $badge-bg:                          neutral-dark !default;
// $badge-border-radius:               calc(var(--padding-y) * 2 + #{$badge-line-height} * 1rem) !default;
//
// $badge-variants: (
//     primary: (
//         color: primary-lightest,
//         bg: primary-dark
//     )
// ) !default;</code></pre>
//
// Weight: 2
//
// Style guide: components.badge.config
