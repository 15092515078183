@import "../../00-settings/index";
@import "../../01-tools/index";

// Control
//
// A <code>.form-control</code> field is an input that allows a user to input information.
//
// Markup:
// <input type="text" class="form-control" id="input">
//
// Weight: -1
//
// Style guide: forms.form-control

// Usage
//
// Use form control fields to help people enter, select, and search for text. Text fields are normally found within a form but can also be part of a modal, search, or card.
//
// In most cases a form control field should be used with a label (<code>.form-label</code>) and can display a helper text.
//
// <b>Best practices</b>
//
// <ul><li>Only supply placeholder text where clarification is required, try not to overuse it.</li>
// <li>Place labels directly above the input, and align to the left.</li></ul>
//
// <b>Content guidelines</b>
// <ul><li>For helper text provide an example or specific syntax for the input, rather than in the input area, so that it's visible after text has been entered. Only use this where clarification is required, and try not to overuse it.</li>
// <li>Field label text above the input area should be short and concise.</li></ul>
//
// Markup:
// <div>
//     <label class="form-label" for="name">Name</label>
//     <input type="text" class="form-control" id="name" placeholder="First Last">
//     <span class="form-helper">Ex: Raz Tirboaca</span>
// </div>
//
// Style guide: forms.form-control.usage

// Types
//
// Markup: form-control.html
//
// Weight: 1
//
// Style guide: forms.form-control.types

// States
//
// Markup:
// <input type="text" class="form-control {{modifier_class}}" value="wefox.com">
//
// :hover           - When the component is hovered with a mouse
// :focus           - When the component is focused by the keyboard, mouse, or touch
//
// Weight: 2
//
// Style guide: forms.form-control.states

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it doesn't have a value and it depends on another selection. Otherwise, use the <code>:readonly</code> state.
//
// Markup:
// <div>
//     <label class="form-label" for="nameDisabled">Name</label>
//     <input type="text" class="form-control" id="nameDisabled" placeholder="First Last" disabled>
// </div>
//
// Weight: 3
//
// Style guide: forms.form-control.disabled

// Readonly
//
// Add the <code>readonly</code> boolean attribute to prevent modification of the input’s value. Read-only inputs appear as plain text (remove the default form field styling and preserve the correct margin and padding).
//
// Markup:
// <div>
//     <label class="form-label" for="namereadonly">Name</label>
//     <input type="text" class="form-control" id="namereadonly" value="Raz Tirboaca" readonly>
// </div>
//
// Weight: 4
//
// Style guide: forms.form-control.readonly

.form-control {
    --padding-y: var(--#{$input-padding-y});
    --padding-x: var(--#{$input-padding-x});
    --color: var(--#{$input-color});
    --bg: var(--#{$input-bg});
    --border-color: var(--#{$input-border-color});
    --border-radius: #{$input-border-radius};
    --box-shadow: #{$input-box-shadow};
    --focus-color: var(--#{$input-focus-color});
    --focus-bg: var(--#{$input-focus-bg});
    --focus-border-color: var(--#{$input-focus-border-color});
    --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
    --disabled-color: var(--#{$input-disabled-color});
    --disabled-bg: var(--#{$input-disabled-bg});
    --disabled-border-color: var(--#{$input-disabled-border-color});
    --placeholder-color: var(--#{$input-placeholder-color});
    --file-button-color: var(--#{$form-file-button-color});
    --file-button-bg: var(--#{$form-file-button-bg});
    --file-button-hover-bg: var(--#{$form-file-button-hover-bg});

    display: block;
    width: 100%;
    min-height: calc((var(--padding-y) * 2 + #{$input-line-height}) + #{$input-border-width} * 2);
    padding: var(--padding-y, spacer(#{$input-padding-y})) var(--padding-x, spacer(#{$input-padding-x}));
    color: var(--color, ui-color(#{$input-color}));
    @include font-scale($input-font-scale);
    font-weight: $input-font-weight;
    font-family: $input-font-family;
    background: var(--bg, ui-color(#{$input-bg}));
    background-clip: padding-box;
    border-color: var(--border-color, ui-color(#{$input-border-color}));
    border-style: solid;
    border-width: $input-border-width;
    border-radius: var(--border-radius, #{$input-border-radius});
    box-shadow: inset var(--box-shadow, #{$input-box-shadow});
    transition: $input-transition;
    appearance: none; // Fix appearance for date inputs in Safari
    caret-color: var(--primary-base);

    &[type="file"] {
        overflow: hidden; // prevent pseudo element button overlap

        &:not(:disabled):not([readonly]) {
            cursor: pointer;
        }
    }

    // Add some height to date inputs on iOS
    // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
    &::-webkit-date-and-time-value {
        // Multiply line-height by 1em if it has no unit
        height: if(unit($input-line-height) == "", $input-line-height * 1em, $input-line-height);
    }

    // Placeholder
    &::placeholder {
        --color: var(--placeholder-color);

        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    &:focus {
        --color: var(--focus-color);
        --bg: var(--focus-bg);
        --border-color: var(--focus-border-color);
        --box-shadow: var(--focus-box-shadow);
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be disabled if the fieldset is disabled.
    // Due to implementation difficulty, we don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &.disabled {
        --color: var(--disabled-color);
        --bg: var(--disabled-bg);
        --border-color: var(--disabled-border-color);
        --box-shadow: none;
        // iOS fix for unreadable disabled content
        opacity: 1;
    }

    // File input buttons theming
    &[type=file]::file-selector-button {
        --color: var(--file-button-color);
        --bg: var(--file-button-bg);

        margin: calc(var(--padding-y, spacer(#{$input-padding-y})) / 2 * -1) calc(var(--padding-x, spacer(#{$input-padding-x})) / 2 * -1);
        padding: calc(var(--padding-y, spacer(#{$input-padding-y})) / 2) calc(var(--padding-x, spacer(#{$input-padding-x})) / 2);
        margin-inline-end: calc(var(--padding-x, spacer(#{$input-padding-x})) / 2);
        color: var(--color, ui-color(#{$form-file-button-color}));
        background: var(--bg, ui-color(#{$form-file-button-bg}));
        border-color: inherit;
        border-style: solid;
        border-width: 0;
        border-inline-end-width: 0;
        border-radius: 0;
        border-radius: var(--border-radius, #{$input-border-radius});
        transition: $btn-transition;
        pointer-events: none;
    }

    &[type=file]:hover:not(:disabled):not([readonly])::file-selector-button,
    &[type=file]:hover:not(.disabled):not([readonly])::file-selector-button {
        --bg: var(--file-button-hover-bg);
    }

    // File input buttons theming
    &[type=file]::-webkit-file-upload-button {
        --color: var(--file-button-color);
        --bg: var(--file-button-bg);

        margin: calc(var(--padding-y) / 2 * -1) calc(var(--padding-x) / 2 * -1);
        padding: calc(var(--padding-y) / 2) calc(var(--padding-x) / 2);
        margin-inline-end: calc(var(--padding-x, spacer(#{$input-padding-x})) / 2);
        color: var(--color, ui-color(#{$form-file-button-color}));
        background: var(--bg, ui-color(#{$form-file-button-bg}));
        border-color: inherit;
        border-style: solid;
        border-width: 0;
        border-inline-width: 0;
        border-radius: 0;
        border-radius: var(--border-radius, #{$input-border-radius});
        pointer-events: none;
        @include transition($btn-transition);
    }

    &[type=file]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
    &[type=file]:hover:not(.disabled):not([readonly])::-webkit-file-upload-button {
        --bg: var(--file-button-hover-bg);
    }
}

// Sizes
//
// Make a field smaller or larger by adding the <code>.form-control-sm</code> or <code>.form-control-lg</code> classes.
//
// Markup:
// <input type="text" class="form-control {{modifier_class}}" placeholder="Text">
//
// .form-control-sm           - Smaller size
// .form-control-lg           - Larger size
//
// Weight: 5
//
// Style guide: forms.form-control.sizes

.form-control-sm {
    @include input-size($input-padding-y-sm, $input-padding-x-sm, $input-font-scale-sm, $input-border-radius-sm);
}
.form-control-lg {
    @include input-size($input-padding-y-lg, $input-padding-x-lg, $input-font-scale-lg, $input-border-radius-lg);
}

// Control type color

.form-control-color[type="color"] {
    --focus-box-shadow: 0 0 0 1px var(--neutral-end);

    max-width: calc(var(--padding-y) * 2 + #{$input-line-height} + #{$input-border-width} * 2);
    height: calc(var(--padding-y) * 2 + #{$input-line-height} + #{$input-border-width} * 2); // Override fixed browser height
    padding: 0;
    border-width: 0;

    &:not(:disabled):not([readonly]),
    &:not(.disabled):not([readonly]) {
        cursor: pointer;
    }

    &::-moz-color-swatch {
        height: auto;
        border: none;
        border-radius: var(--border-radius, #{$input-border-radius});
    }

    &::-webkit-color-swatch {
        height: auto;
        border: none;
        border-radius: var(--border-radius, #{$input-border-radius});
    }
}

// Config options
//
// <pre><code class="sass">$input-padding-y:                               $input-btn-padding-y !default;
// $input-padding-x:                               $input-btn-padding-x !default;
// $input-font-family:                             $input-btn-font-family !default;
// $input-font-size:                               $input-btn-font-size !default;
// $input-font-weight:                             $font-weight-base !default;
// $input-line-height:                             $input-btn-line-height !default;
// $input-color:                                   neutral-end !default;
// $input-bg:                                      neutral-start !default;
// $input-border-color:                            neutral-base !default;
// $input-border-width:                            $input-btn-border-width !default;
// $input-border-radius:                           $border-radius !default;
// $input-box-shadow:                              0 0 0 transparent !default;
//
// $input-focus-width:                             1px !default;
// $input-focus-color:                             $input-color !default;
// $input-focus-bg:                                $input-bg !default;
// $input-focus-border-color:                      primary-base !default;
// $input-focus-box-shadow:                        0 0 0 1px var(--#{$input-focus-border-color}) !default;
//
// $input-disabled-color:                          neutral-dark !default;
// $input-disabled-bg:                             neutral-light !default;
// $input-disabled-border-color:                   $input-disabled-bg !default;
//
// $input-placeholder-color:                       neutral-base !default;
//
// $input-transition:                              $btn-transition !default;
//
// $input-padding-y-sm:                            $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                            $input-btn-padding-x-sm !default;
// $input-font-size-sm:                            $input-btn-font-size-sm !default;
// $input-border-radius-sm:                        $border-radius-sm !default;
//
// $input-padding-y-lg:                            $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                            $input-btn-padding-x-lg !default;
// $input-font-size-lg:                            $input-btn-font-size-lg !default;
// $input-border-radius-lg:                        $border-radius-lg !default;</code></pre>
//
// Weight: 6
//
// Style guide: forms.form-control.config

::-webkit-datetime-edit-ampm-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-week-field,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-text {
    color: $black;
    -webkit-text-fill-color: $black;
    -webkit-opacity: 1;
}
