// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
    --size: #{$btn-close-size};
    --padding-y: #{$btn-close-padding-y};
    --padding-x: #{$btn-close-padding-x};
    //--color: var(--#{$btn-close-color});
    //--hover-color: var(--#{$btn-close-color});

    box-sizing: content-box;
    width: var(--size, #{$btn-close-size});
    height: var(--size, #{$btn-close-size});
    padding: var(--padding-y, #{$btn-close-padding-y}) var(--padding-x, #{$btn-close-padding-x});
    color: var(--color, ui-color(#{$btn-close-color}));
    background: transparent escape-svg($btn-close-bg) center / $btn-close-size auto no-repeat; // include transparent for button elements
    border: 0; // for button elements
    border-radius: 0;
    opacity: $btn-close-opacity;

    // Override <a>'s hover style
    &:hover {
        //--color: var(--hover-color);

        text-decoration: none;
        opacity: $btn-close-hover-opacity;
    }

    &:focus {
        outline: none;
        opacity: $btn-close-focus-opacity;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-close-disabled-opacity;
        user-select: none;
        pointer-events: none;
    }
}
