:root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --gradient: #{$gradient};
    --transparent: transparent;

    // Colors
    @each $name, $color in $ui-colors {
        --#{$name}: #{$color};
    }

    // Spacers
    @each $name, $value in $spacers {
        --#{$name}: #{$value};
    }
}
