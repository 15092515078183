@import "../00-settings/index";
@import "../01-tools/index";

// Table
//
// Markup: _tables.hbs
//
// .table-sm - small
// .table-subtle - subtle
//
// Style guide: objects.table

//
// Basic Bootstrap table
//

.table {
    width: 100%;
    margin-bottom: $table-margin-bottom;
    color: $table-color;
    vertical-align: $table-cell-vertical-align;
    border-color: $table-border-color;

    // Target th & td
    // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
    // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
    // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
        padding: var(--#{$table-cell-padding-y}, spacer($table-cell-padding-y)) var(--#{$table-cell-padding-x}, spacer($table-cell-padding-x));
        background-color: var(--#{$table-bg}, ui-color($table-bg));
        border-bottom-width: $table-border-width;
    }

    > tbody {
        vertical-align: inherit;
    }

    > thead {
        vertical-align: bottom;

        > * > * {
            color: $table-th-color;
            font-weight: $table-th-font-weight;
            font-size: font-scale($table-th-font-scale);
            vertical-align: bottom;
            background-color: $table-th-bg;
        }
    }

    // Highlight border color between thead, tbody and tfoot.
    > :not(:last-child) > :last-child > * {
        border-bottom-color: $table-group-separator-color;
    }

    tr.disabled {
        td {
            background-color: $table-cell-bg-disabled;
        }
    }
}

//
// Change placement of captions with a class
//

.caption-top {
    caption-side: top;
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-down($breakpoint) {
        .table-responsive#{$infix} {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}

// Table sizes

.table-sm {
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
        padding: var(--#{$table-cell-padding-y-sm}, spacer($table-cell-padding-y-sm)) var(--#{$table-cell-padding-x-sm}, spacer($table-cell-padding-x-sm));
    }
}

// Table variants

.table-subtle {

    > :not(caption) > * > * {
        background-color: ui-color(neutral-lightest);
    }
}

// Table utilities

.table-col-actions {
    width: rem(16px);
}
