@import "../../00-settings/index";
@import "../../01-tools/index";

// Select
//
// Select gives users the ability to make a single selection, or multiple selections from a number of options.
//
// Markup:
// <select class="form-select" id="selectDemo" aria-label="Select demo">
//     <option selected>All options</option>
//     <option value="1">One</option>
//     <option value="2">Two</option>
//     <option value="3">Three</option>
// </select>
//
// Style guide: forms.form-select

// Usage
//
// Select it’s usually found in forms, filterbars or inline edit.
//
// In most cases a select field should be used with a label (<code>.form-label</code>) and can display a helper text.
//
// <b>Best practices</b>
//
// <ul><li>Order your list of options in a way that will make the most sense to the user. This could be:
// <ul><li>alphabetically</li>
// <li>numerically</li>
// <li>by most commonly selected option</li></ul></li>
// <li>Remove options that rarely get used.</li>
// <li>Don’t overwhelm the user with too many options.</li></ul>
//
// <b>Content guidelines</b>
// <ul><li>Use concise, descriptive labels so that users clearly know the purpose of the selection. For example, use a "Filter by product type" label for a list containing options such as "Car, Household, Health".</li>
// <li>Keep options to a single line of text.</li>
// <li>Use commonly known terms for your options, make sure they’re clear and direct</li></ul>
//
// Markup:
// <div>
//     <label class="form-label" for="productType">Filter by product type</label>
//     <select class="form-select" id="productType" aria-label="Product type">
//         <option selected>All types</option>
//         <option value="1">Car</option>
//         <option value="2">Household</option>
//         <option value="3">Health</option>
//     </select>
// </div>
//
// Style guide: forms.form-select.usage

// States
//
// Markup:
// <select class="form-select {{modifier_class}}" id="" aria-label="Select demo">
//     <option selected>All options</option>
//     <option value="1">1</option>
//     <option value="2">2</option>
//     <option value="3">3</option>
// </select>
//
// :hover           - When the component is hovered with a mouse
// :focus           - When the component is focused by the keyboard, mouse, or touch
//
// Weight: 1
//
// Style guide: forms.form-select.states

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it depends on another selection.
//
// Markup:
// <select class="form-select" id="" aria-label="Select demo" disabled>
//     <option selected>All options</option>
//     <option value="1">1</option>
//     <option value="2">2</option>
//     <option value="3">3</option>
// </select>
//
// Weight: 2
//
// Style guide: forms.form-select.disabled

.form-select {
    --padding-y: var(--#{$form-select-padding-y});
    --padding-x: var(--#{$form-select-padding-x});
    --color: var(--#{$form-select-color});
    --bg: var(--#{$form-select-bg});
    --border-color: var(--#{$form-select-border-color});
    --border-radius: #{$form-select-border-radius};
    --box-shadow: #{$form-select-box-shadow};
    --focus-color: var(--#{$form-select-focus-color});
    --focus-bg: var(--#{$form-select-focus-bg});
    --focus-border-color: var(--#{$form-select-focus-border-color});
    --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
    --disabled-color: var(--#{$form-select-disabled-color});
    --disabled-bg: var(--#{$form-select-disabled-bg});
    --disabled-border-color: var(--#{$form-select-disabled-border-color});

    display: block;
    width: 100%;
    padding: var(--padding-y, spacer(#{$form-select-padding-y})) var(--padding-x, spacer(#{$form-select-padding-x}));
    color: var(--color, ui-color(#{$form-select-color}));
    font-weight: $form-select-font-weight;
    font-family: $form-select-font-family;
    @include font-scale($form-select-font-scale);
    background-color: var(--bg, ui-color(#{$form-select-bg}));
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: $form-select-bg-position;
    background-size: $form-select-bg-size;
    border-color: var(--border-color, ui-color(#{$form-select-border-color}));
    border-style: solid;
    border-width: $form-select-border-width;
    border-radius: var(--border-radius, #{$form-select-border-radius});
    box-shadow: var(--box-shadow, #{$form-select-box-shadow});
    transition: $form-select-transition;
    appearance: none;

    &:focus {
        --color: var(--focus-color);
        --bg: var(--focus-bg);
        --border-color: var(--focus-border-color);
        --box-shadow: var(--focus-box-shadow);
    }

    &[multiple],
    &[size]:not([size="1"]) {
        padding-right: var(--padding-y);
        background-image: none;
    }

    &:disabled,
    &.disabled {
        --color: var(--disabled-color);
        --bg: var(--disabled-bg);
        --border-color: var(--disabled-border-color);
        --box-shadow: none;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $form-select-color;
    }
}

// Sizes
//
// Make a field smaller or larger by adding the <code>.form-select-sm</code> or <code>.form-select-lg</code> classes.
//
// Markup:
// <select class="form-select {{modifier_class}}" aria-label="Select demo">
//     <option selected>All options</option>
//     <option value="1">One</option>
//     <option value="2">Two</option>
//     <option value="3">Three</option>
// </select>
//
// .form-select-sm           - Smaller size
// .form-select-lg           - Larger size
//
// Weight: 4
//
// Style guide: forms.form-select.sizes

.form-select-sm {
    @include input-size($form-select-padding-y-sm, $form-select-padding-x-sm, $form-select-font-scale-sm, $form-select-border-radius-sm);
}

.form-select-lg {
    @include input-size($form-select-padding-y-lg, $form-select-padding-x-lg, $form-select-font-scale-lg, $form-select-border-radius-lg);
}
