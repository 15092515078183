@import "../../00-settings/index";
@import "../../01-tools/index";

// Button
//
// Use buttons for actions in forms, dialogs, and more with support for multiple sizes, states, and more.
//
// We include several predefined button styles, each serving its own semantic purpose, with a few extras thrown in for more control.
//
// <b>Conveying meaning to assistive technologies</b>
//
// Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers.
// Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, such as additional text hidden with the <code>.visually-hidden</code> class.
//
// <b>Disable text wrapping</b>
//
// If you don’t want the button text to wrap, you can add the <code>.text-nowrap</code> class to the button. In Sass, you can set <code>$btn-white-space: nowrap</code> to disable text wrapping for each button.
//
// The default form of a button is used for most cases. They are not impactful enough to represent the primary action in a section tho.
//
// Markup: button.html
//
// .btn-primary                 - Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the <code>navbar</code> or in a <code>modal</code> dialog). Not every screen requires a primary button.
// .btn-subtle                  - Use a subtle button with a primary button for secondary actions, such as “Cancel". Subtle buttons have a transparent background and can be used as button icons inside components.
// .btn-link                    - Use a link button to navigate to another page. These should open in the same window unless information may be lost (for example, when someone is filling out a form), or when the destination is an external site (for example, an API referance article).
// .btn-success                 - Use a success button to communicate the succesfull validation of an action and confidence in taking the next step. These are found mostly in forms that have multiple steps.
// .btn-warning                 - A warning button appears before we request the user to take action, usually in anticipation of a significant change. These are found mostly in confirmation modals.
// .btn-danger                  - The danger button appears as a final confirmation for a destructive action such as deleting. These are found mostly in confirmation modals.
// .btn-b2c                     - The default button for B2C sites
// .btn-b2c-inverted            - An inverted button for B2C sites
// .btn-b2c-primary             - The primary button for B2C sites
// .btn-b2c-primary-inverted    - The primary inverted button for B2C sites
// .btn-b2b                     - The default button for B2B sites
// .btn-b2b-inverted            - An inverted button for B2B sites
// .btn-b2b-primary             - The primary button for B2B sites
// .btn-b2b-primary-inverted    - The primary inverted button for B2B sites
//
// Style guide: components.button

.btn {
    --padding-y: var(--#{$btn-padding-y});
    --padding-x: var(--#{$btn-padding-x});
    --color: var(--#{$btn-color});
    --bg: var(--#{$btn-bg});
    --border-color: var(--#{$btn-border-color});
    --border-radius: #{$btn-border-radius};
    --box-shadow: #{$btn-box-shadow};
    --transform: #{$btn-transform};
    --hover-color: var(--#{$btn-hover-color});
    --hover-bg: var(--#{$btn-hover-bg});
    --hover-border-color: var(--#{$btn-hover-border-color});
    --hover-box-shadow: #{$btn-hover-box-shadow};
    --active-color: var(--#{$btn-active-color});
    --active-bg: var(--#{$btn-active-bg});
    --active-border-color: var(--#{$btn-active-border-color});
    --active-box-shadow: #{$btn-active-box-shadow};
    --active-transform: #{$btn-active-transform};
    --selected-color: var(--#{$btn-selected-color});
    --selected-bg: var(--#{$btn-selected-bg});
    --selected-border-color: var(--#{$btn-selected-border-color});
    --selected-box-shadow: #{$btn-selected-box-shadow};
    --disabled-color: var(--#{$btn-disabled-color});
    --disabled-bg: var(--#{$btn-disabled-bg});
    --disabled-border-color: var(--#{$btn-disabled-border-color});
    --disabled-box-shadow: #{$btn-disabled-box-shadow};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-y, spacer(#{$btn-padding-y})) var(--padding-x, spacer(#{$btn-padding-x}));
    color: var(--color, ui-color(#{$btn-color}));
    font-weight: $btn-font-weight;
    font-family: $btn-font-family;
    white-space: $btn-white-space;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    background: var(--bg, ui-color(#{$btn-bg}));
    border-color: var(--border-color, ui-color(#{$btn-border-color}));
    border-style: solid;
    border-width: $btn-border-width;
    border-radius: var(--border-radius, #{$btn-border-radius});
    box-shadow: var(--box-shadow, #{$btn-box-shadow});
    transform: var(--transform, #{$btn-transform});
    cursor: pointer;
    user-select: none;
    @include transition($btn-transition);

    &:hover {
        --color: var(--hover-color);
        --bg: var(--hover-bg);
        --border-color: var(--hover-border-color);
        --box-shadow: var(--hover-box-shadow);
        text-decoration: none;
    }

    &:active {
        --color: var(--active-color);
        --bg: var(--active-bg);
        --border-color: var(--active-border-color);
        --box-shadow: var(--active-box-shadow);
        --transform: var(--active-transform);
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &.selected,
    &.show {
        --color: var(--selected-color);
        --bg: var(--selected-bg);
        --border-color: var(--selected-border-color);
        --box-shadow: var(--selected-box-shadow);
    }

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        --color: var(--disabled-color);
        --bg: var(--disabled-bg);
        --border-color: var(--disabled-border-color);
        --box-shadow: var(--disabled-box-shadow);
        opacity: $btn-disabled-opacity;
        pointer-events: none;
    }

    // style icons inside the button
    .icon {
        margin-right: var(--small);
    }

    // makes a square button by using the same padding
    &.btn-square {
        --padding-x: var(--padding-y);

        .icon {
            margin-right: 0;
        }
    }

    &[class*="btn-b2b"] {
        font-weight: $btn-b2b-font-weight;
    }

    &[class*="btn-b2c"] {
        font-weight: $btn-b2c-font-weight;
    }
}

// Generate contextual modifier classes for colorizing the button.
@each $variant, $property in $button-variants {
    .btn-#{$variant} {
        --color: var(--#{map-get($property, color)});
        --bg: var(--#{map-get($property, bg)});
        --border-color: var(--#{map-get($property, border-color)});
        --box-shadow: #{map-get($property, box-shadow)};
        --hover-color: var(--#{map-get($property, hover-color)});
        --hover-bg: var(--#{map-get($property, hover-bg)});
        --hover-border-color: var(--#{map-get($property, hover-border-color)});
        --hover-box-shadow: #{map-get($property, hover-box-shadow)};
        --active-color: var(--#{map-get($property, active-color)});
        --active-bg: var(--#{map-get($property, active-bg)});
        --active-border-color: var(--#{map-get($property, active-border-color)});
        --active-box-shadow: #{map-get($property, active-box-shadow)};
        --selected-color: var(--#{map-get($property, selected-color)});
        --selected-bg: var(--#{map-get($property, selected-bg)});
        --selected-border-color: var(--#{map-get($property, selected-border-color)});
        --selected-box-shadow: #{map-get($property, selected-box-shadow)};
        --disabled-color: var(--#{map-get($property, disabled-color)});
        --disabled-bg: var(--#{map-get($property, disabled-bg)});
        --disabled-border-color: var(--#{map-get($property, disabled-border-color)});
        --disabled-box-shadow: #{map-get($property, disabled-box-shadow)};
    }
}

//
// Link button
//

.btn-link {
    --color: var(--#{$btn-link-color});
    --bg: var(--color-transparent);
    --border-color: var(--color-transparent);
    --hover-color: var(--#{$btn-link-hover-color});
    --hover-bg: var(--color-transparent);
    --hover-border-color: var(--color-transparent);
    --active-color: var(--hover-color);
    --active-bg: var(--color-transparent);
    --active-border-color: var(--color-transparent);
    --selected-color: var(--active-color);
    --selected-bg: var(--color-transparent);
    --selected-border-color: var(--color-transparent);
    --disabled-color: var(--#{$btn-link-disabled-color});
    --disabled-bg: var(--color-transparent);
    --disabled-border-color: var(--color-transparent);
    text-decoration: $link-decoration;
    box-shadow: none !important;

    &:hover {
        text-decoration: $link-hover-decoration;
    }
}

// Usage
//
// Buttons are triggers for events or actions. They’re commonly used as part of larger components or patterns such as forms or modal dialogs.
//
// <ol><li>Move users through a sequence of screens.</li>
// <li>Act as calls to action (CTAs).</li>
// <li>With an icon to convey meaning quicker.</li>
// <li>With a badge to indicate a value.</li></ol>
//
// <b>Best practices</b>
//
// <ul><li>Use a primary button to indicate the main action of a group button set.</li>
// <li>Default and subtle buttons should use a less dominant color.</li>
// <li>Button size matters - make sure the size of the button is large enough to interact with on web or other device but not too large so as to be visually overwhelming.</li>
// <li>Use action verbs or phrases to tell the user what will happen next.</li></ul>
//
// Weight: 0
//
// Style guide: components.button.usage

// HTML tags
//
// The <code>.btn</code> classes are designed to be used with the <code>button</code> element. However, you can also use these classes on <code>a</code> or <code>input</code> elements (though some browsers may apply a slightly different rendering).
//
// When using button classes on <code>a</code> elements that are used to trigger in-page functionality (like collapsing content), rather than linking to new pages or sections within the current page, these links should be given a <code>role="button"</code> to appropriately convey their purpose to assistive technologies such as screen readers.
//
// Markup:
// <a href="#" role="button" class="btn btn-primary">Link</a>
// <button class="btn btn-primary" type="submit">Button</button>
// <input class="btn btn-primary" type="button" value="Input">
// <input class="btn btn-primary" type="submit" value="Submit">
// <input class="btn btn-primary" type="reset" value="Reset">
//
// Weight: 1
//
// Style guide: components.button.tags

// States
//
// A <code>:disabled button</code> is a native HTML concept, but disabled is not a native concept for other element types such as <code>a</code> and <code>span</code>. The behavior of a <code>:disabled button</code> is imitated as much as possible regardless of the element type. For <code>a</code> and <code>span</code> use the <code>.disabled</code> class.
//
// A disabled element will not fire any user events. We apply <code>pointer-events: none</code> to all children elements of the element. This prevents inner elements publishing events.
//
// Markup: button-states.html
//
// :hover           - When the component is hovered with a mouse
// :focus-visible   - When the component is focused only by a keyboard
// :active          - When the component is activated by the mouse
// .selected        - When the component is selected or checked
// :disabled        - Disable a button when an action is not ready to be taken
//
// Weight: 2
//
// Style guide: components.button.states

// Sizes
//
// Make a button smaller or larger by adding the <code>.btn-sm</code> or <code>.btn-lg</code> classes.
//
// Markup: button-sizes.html
//
// Weight: 3
//
// Style guide: components.button.sizes

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-scale-sm, $btn-border-radius-sm);

    // scale the icon
    .icon {
        @include icon-size($icon-size-sm);
    }
}

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-scale-lg, $btn-border-radius-lg);

    // scale the icon
    .icon {
        @include icon-size($icon-size-md);
    }
}

//
// Block button
//

.btn-block {
    display: flex;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Icon button
//
// Use <code>.btn-square</code> class to make a square icon button.
//
// Markup: button-icon.html
//
// .btn-sm      - Smaller size
// .btn-lg      - Larger size
//
// Weight: 4
//
// Style guide: components.button.icon

// Loading state
//
// Use the <code>.icon-loader</code> icon to create a loading state.
//
// Markup: button-loading.html
//
// Weight: 5
//
// Style guide: components.button.loading

// Config options
//
// <pre><code class="sass">$btn-padding-y:                      medium !default;
// $btn-padding-x:                      large !default;
// $btn-font-family:                    $input-btn-font-family !default;
// $btn-font-size:                      $input-btn-font-size !default;
// $btn-font-weight:                    $font-weight-normal !default;
// $btn-line-height:                    $input-btn-line-height !default;
// $btn-white-space:                    nowrap !default;
// $btn-color:                          neutral-end !default;
// $btn-bg:                             neutral-start !default;
// $btn-border-width:                   $input-btn-border-width !default;
// $btn-border-color:                   neutral-base !default;
// $btn-border-radius:                  $border-radius !default;
// $btn-box-shadow:                     0 1px 2px rgba(ui-color(neutral-end), .16) !default;
//
// ... // :hover, :active, .selected, :disabled
//
// $button-variants: (
//     primary: (
//         color:                              neutral-start,
//         bg:                                 primary-base,
//         border-color:                       primary-base,
//         box-shadow:                         $btn-box-shadow,
//         hover-color:                        neutral-start,
//         hover-bg:                           primary-dark,
//         hover-border-color:                 primary-dark,
//         hover-box-shadow:                   $btn-hover-box-shadow,
//         active-color:                       neutral-start,
//         active-bg:                          primary-dark,
//         active-border-color:                primary-dark,
//         active-box-shadow:                  $btn-active-box-shadow,
//         selected-color:                     primary-base,
//         selected-bg:                        primary-lightest,
//         selected-border-color:              primary-base,
//         selected-box-shadow:                $btn-box-shadow,
//         disabled-color:                     $btn-disabled-color,
//         disabled-bg:                        $btn-disabled-bg,
//         disabled-border-color:              $btn-disabled-border-color,
//         disabled-box-shadow:                $btn-disabled-box-shadow
//     ),
//     subtle: (
//         ...
//     ),
//     success: (
//         ...
//     ),
//     warning: (
//         ...
//     ),
//     danger: (
//         ...
//     ),
//     b2c: (
//         ...
//     ),
//     b2c-inverted: (
//         ...
//     ),
//     b2c-primary: (
//         ...
//     ),
//     b2c-primary-inverted: (
//         ...
//     ),
//     b2b: (
//         ...
//     ),
//     b2b-inverted: (
//         ...
//     ),
//     b2b-primary: (
//         ...
//     ),
//     b2b-primary-inverted: (
//         ...
//     )
// ) !default;</code></pre>
//
// Weight: 6
//
// Style guide: components.button.config
