.ant-picker {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
  transition: color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
  width: 100%;
}

.anticon {
  vertical-align: middle;
}

.ant-picker:focus, .ant-picker-focused {
  color: var(--primary-base);
}

.ant-picker.ng-invalid.ng-touched {
  border-color: var(--comet-color-invalid);
}

.ant-picker-disabled .anticon {
  color: var(--lumo-disabled-text-color);
}

.ant-input-disabled {
  color: var(--lumo-disabled-text-color)  !important;

  &::placeholder {
    color: transparent;
  }
}

.ant-picker.ng-invalid.ng-touched:hover {
  background-color: var(--comet-color-invalid-hover);
}

.ant-picker:hover {
  background-color: var(--comet-color-hover);
}

.ant-picker-input {
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;
  
  input { 
    background-color: transparent;
    border: 0;
    color: var(--primary-dark);
    font-size: var(--font-size);
    font-weight: 400;
    transition: all .3s;
    width: 100%;

    &:focus-visible {
      box-shadow: none;
    }
  }
}

.ant-picker-disabled {
  background-color: var(--comet-color-disabled-bg);
  border: none;
}

.ant-picker-disabled:hover {
  background-color: var(--comet-color-disabled-bg);
}

.ant-picker-clear {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.3s, color 0.3s;
}

.ant-picker:hover .ant-picker-clear {
  background-color: var(--comet-color-hover);
  color: var(--color, #301254);
  opacity: 1;
}


// Dropdown 
.ant-picker-dropdown {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  left: unset;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  top: unset;
}

.ant-picker-inline {
  border: none;
  padding: 0;
  width: fit-content;
}

.ant-picker-panel-container {
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 12%);
  overflow: hidden;
  transition: margin .3s;
  vertical-align: top;
}

.ant-picker-panel {
  background: var(--neutral-start);
  border: 1px solid rgb(0 0 0 / 6%);
  border-radius: 2px;
  display: inline-flex;
  flex-direction: column;
  outline: none;
  text-align: center;
}

.ant-picker-panel-container .ant-picker-panel {
  background: transparent;
  border-radius: 0;
  border-width: 0 0 1px;
  vertical-align: top;
}

.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.ant-picker-header {
  border-bottom: 1px solid rgb(0 0 0 / 6%);
  color: var(--primary-dark);
  display: flex;
  padding: 0 8px;
}

.ant-picker-header > button {
  font-size: 14px;
  min-width: 1.6em;
}

.ant-picker-header button {
  background: transparent;
  border: 0;
  color: var(--primary-light);
  cursor: pointer;
  line-height: 40px;
  padding: 0;
  transition: color .3s;
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

// Body 
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-cell {
  cursor: pointer;
  padding: 3px 0;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 2px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
  position: relative;
  transition: background .3s,border .3s;
  z-index: 2;
}

.ant-picker-cell-selected {
  background-color: var(--primary-base);
  color: var(--neutral-start);
}

.ant-picker-cell-in-range {
  background-color: var(--primary-light);
  color: var(--neutral-start);
}

.ant-picker-cell-disabled {
  color: #00000040;
  pointer-events: none;
}

// Footer 
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgb(0 0 0 / 6%);
}

.ant-picker-footer {
  border-bottom: 1px solid transparent;
  line-height: 38px;
  min-width: 100%;
  text-align: center;
  width: min-content;

  a {
    font-weight: 600;
  }
}

.ant-picker-ranges {
  line-height: 34px;
  list-style: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 4px 12px;
  text-align: left;

  li {
    display: inline-block;
  }

  .ant-picker-ok {
    float: right;
    margin-left: 8px;
    
    button {
      border-radius: 4px;
      font-weight: 600;
    }

    .ant-btn-primary {
      background-color: var(--primary-base);
      border-color: var(--primary-base);
      box-shadow: 0 2px #0000000b;
      color: var(--neutral-start);
      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    }

    .ant-btn-primary:hover {
      background-color: var(--primary-dark);
    }

    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
      background: #f5f5f5;
      border-color: var(--neutral-base);
      box-shadow: none;
      color: #00000040;
      text-shadow: none;
    }
  }
}

// Navigation icons
.ant-picker-prev-icon, .ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}

.ant-picker-next-icon, .ant-picker-super-next-icon {
  transform: rotate(135deg);
}

.ant-picker-prev-icon, .ant-picker-next-icon, .ant-picker-super-prev-icon, .ant-picker-super-next-icon {
  display: inline-block;
  height: 7px;
  position: relative;
  width: 7px;
}

.ant-picker-prev-icon::before, .ant-picker-next-icon::before, .ant-picker-super-prev-icon::before, .ant-picker-super-next-icon::before {
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: "";
  display: inline-block;
  height: 7px;
  left: 0;
  position: absolute;
  top: 0;
  width: 7px;
}

.ant-picker-super-prev-icon::after, .ant-picker-super-next-icon::after {
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: "";
  display: inline-block;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.ant-picker-header > button:hover {
  color: var(--primary-base);
}

// Range 
.ant-picker-range {
  display: inline-flex;
  position: relative;
}

.ant-picker-range-separator {
  align-items: center;
  line-height: 1;
  padding: 0 8px;
}

// Time
.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-content {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
  padding-left: 14px;
  padding-top: 4px;
}

.ant-picker-time-panel {
  min-width: auto;
  width: auto;
}

.ant-picker-time-panel-column {
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  overflow-y: hidden;
  padding: 0;
  text-align: left;
  transition: background .3s;
  width: 50px;
}

.ant-picker-time-panel-column:hover {
  overflow-y: scroll;
}

.ant-picker-time-panel-column > li .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  border-radius: 0;
  color: var(--primary-dark);
  cursor: pointer;
  display: block;
  height: 28px;
  line-height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  transition: background .3s;
  width: 100%;
}

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}

.ant-picker-time-panel-cell-selected {
  background-color: var(--primary-light);
  color: var(--neutral-start);
}
