.onebp-u-bold {
  font-weight: 600;
}

.onebp-u-cursor-default {
  cursor: default !important;
}

.onebp-u-flex {
  display: flex;
}

.onebp-u-flex-centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.onebp-u-liststyle-none {
  list-style: none;
}

.onebp-u-nowrap {
  white-space: nowrap;
}

.onebp-u-overflow-wrap {
  overflow-wrap: anywhere;
}

.onebp-u-pointer {
  cursor: pointer !important;
}

.onebp-u-relative {
  position: relative;
}

.onebp-u-right {
  float: right;
}

.onebp-u-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.onebp-u-text-ellipsis-normal-space {
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: normal;
}

.onebp-u-text-right {
  text-align: right;
}

.onebp-u-text-word-break {
  word-break: break-all;
}

.onebp-u-underline {
  text-decoration: underline;
}

.onebp-width-100 {
  width: 100%;
}

.wipo-u-w-900 {
  max-width: 900px;
}

.wipo-u-hidden {
  display: none;
}

.wipo-m-auto {
  margin: 0 auto;
}

.wipo-u-pointer-events-none {
  pointer-events: none;
}

.wipo-u-separator {
  border-bottom: 1px solid var(--neutral-base);
}

.wipo-u-show {
  display: block;
}

.wipo-u-text-center {
  text-align: center;
}

.wipo-text-14 {
  font-size: 14px;
}
