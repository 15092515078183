// Lists
//
// Markup:
// <ul class="list-unstyled">
//     <li>Lorem ipsum dolor sit amet</li>
//     <li>Facilisis in pretium nisl aliquet</li>
//     <li>
//         Nulla volutpat aliquam velit
//         <ul>
//             <li>Phasellus iaculis neque</li>
//             <li>Purus sodales ultricies</li>
//         </ul>
//     </li>
//     <li>Integer molestie lorem at massa</li>
// </ul>
// <hr>
// <ul class="list-inline">
//     <li class="list-inline-item">Lorem ipsum</li>
//     <li class="list-inline-item">Phasellus iaculis</li>
//     <li class="list-inline-item">Nulla volutpat</li>
// </ul>
// <hr>
// <ol>
//     <li>Lorem ipsum dolor sit amet</li>
//     <li>Facilisis in pretium nisl aliquet</li>
//     <li>Nulla volutpat aliquam velit</li>
// </ol>
// <hr>
// <dl>
//     <dt>Lorem ipsum</dt>
//     <dd>Dolor sit amet</dd>
//     <dt>Facilisis in</dt>
//     <dd>Pretium nisl aliquet</dd>
//     <dt>Nulla</dt>
//     <dd>Volutpat aliquam velit</dd>
// </dl>
//
// Weight: 4
//
// Style guide: foundations.typography.lists

.list-unstyled {
    @include list-unstyled();
}

.list-inline {
    @include list-unstyled();
}

.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}

.list-info {
    display: grid;
    grid-template-columns: minmax(100px, min-content) auto;

    dt {
        margin-right: spacer(large);
        color: ui-color(neutral-darkest);
        font-weight: $font-weight-normal;
    }

    dd {
        color: ui-color(neutral-dark);
    }

    dt,
    dd {
        margin-bottom: spacer(large);

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
