@import "../../00-settings/index";
@import "../../01-tools/index";

// Nav
//
// A nav presents a list of items from which a user can choose to navigate around.
//
// An item within a nav is known as a nav item (<code>.nav-item</code>), and may be configured to initiate an action, toggle a state on or off, or display a submenu <code>.dropdown-menu</code> of additional nav items when selected or in response to an associated keyboard shortcut.
//
// Markup: nav.html
//
// Style guide: components.nav

// Alignment
//
// The base <code>.nav</code> component is built with flexbox and works with the flex utilities for alignment.
//
// Markup:
// <ul class="nav {{modifier_class}}">
//     <li class="nav-item">
//         <a class="nav-link selected" aria-current="page" href="#">Home</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">Features</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">About</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">Contact</a>
//     </li>
// </ul>
//
// .justify-content-center      - Centered aligned
// .justify-content-end         - Right aligned
//
// Weight: 1
//
// Style guide: components.nav.alignment

// Direction
//
// Stack the nav by changing the flex item direction with the <code>.flex-column</code> utility. Need to stack them on some viewports but not others? Use the responsive versions (ex: <code>.flex-laptop-column</code>).
//
// Markup:
// <ul class="nav flex-column">
//     <li class="nav-item">
//         <a class="nav-link selected" aria-current="page" href="#">Home</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">Features</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">About</a>
//     </li>
//     <li class="nav-item">
//         <a class="nav-link" href="#">Contact</a>
//     </li>
// </ul>
//
// Weight: 2
//
// Style guide: components.nav.direction

// Nav item
//
// A nav item can:
//
// <ul><li>Be a link to an external page.</li>
// <li>Be a link to an ID inside the current page.</li>
// <li>Have an <code>.icon</code>.</li>
// <li>Be a button that triggers a user action.</li>
// <li>Be a <code>.form-toggle</code> to enable an option.</li></ul>
//
// To make an <code>a</code> behave like a button, use <code>role="button"</code>. The button role is used for clickable elements that trigger a response when activated by the user. Adding <code>role="button"</code> will make an anchor appear as a button control to a screen reader.
//
// Use <code>.nav-link-square</code> modifier class when the item contains only an <code>.icon</code>.
//
// Markup: nav-item.html
//
// Weight: 3
//
// Style guide: components.nav.item

.nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.nav-link {
    --padding-y: var(--#{$nav-link-padding-y});
    --padding-x: var(--#{$nav-link-padding-x});
    --color: var(--#{$nav-link-color});
    --hover-color: var(--#{$nav-link-hover-color});
    --selected-color: var(--#{$nav-link-selected-color});
    --disabled-color: var(--#{$nav-link-disabled-color});
    --icon-margin-x: var(--#{$nav-link-icon-margin-x});

    display: flex;
    align-items: center;
    padding: var(--padding-y, spacer(#{$nav-link-padding-y})) var(--padding-x, spacer(#{$nav-link-padding-x}));
    color: var(--color, ui-color(#{$nav-link-color}));
    @include font-scale($nav-link-font-scale);
    font-weight: $nav-link-font-weight;
    text-decoration: if($link-decoration == none, null, none);
    cursor: pointer;
    transition: $nav-link-transition;

    &:hover,
    &:focus {
        --color: var(--hover-color);

        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    &.selected {
        --color: var(--selected-color);
    }

    &.disabled {
        --color: var(--disabled-color);

        cursor: not-allowed;
        pointer-events: none;
    }

    > .icon {
        margin-right: var(--icon-margin-x, spacer($nav-link-icon-margin-x));
    }

    &-square {
        --padding-x: var(--#{$nav-link-padding-y});

        > .icon {
            --icon-margin-x: var(--none);
        }
    }
}

// Tabs
//
// Use to alternate among related views within the same context.
//
// Markup: nav-tabs.html
//
// Weight: 3
//
// Style guide: components.nav.tabs

.nav-tabs {
    --border-color: var(--#{$nav-tabs-border-color});
    --selected-color: var(--#{$nav-tabs-link-selected-color});
    --selected-border-color: var(--#{$nav-tabs-link-selected-border-color});

    border-color: var(--border-color, ui-color(#{$nav-tabs-border-color}));
    border-style: solid;
    border-width: 0 0 $nav-tabs-border-width;

    .nav-link {
        --border-color: var(--color-transparent);

        margin-bottom: -$nav-tabs-border-width;
        border-color: var(--border-color);
        border-style: solid;
        border-width: 0 0 $nav-tabs-border-width;

        &:hover,
        &:focus {
            --border-color: var(--#{$nav-tabs-link-hover-border-color});
        }

        &.disabled {
            --border-color: var(--color-transparent);
        }

        &.selected,
        .show & {
            --border-color: var(--selected-border-color);
        }
    }

    .dropdown-menu {
        // Make dropdown border overlap tab border
        margin-top: -$nav-tabs-border-width;
    }
}

// Pills
//
// Use to alternate among related views within the same context.
//
// Markup: nav-pills.html
//
// Weight: 4
//
// Style guide: components.nav.pills

.nav-pills {
    .nav-link {
        --border-radius: #{$nav-pills-border-radius};
        --selected-color: var(--#{$nav-pills-link-selected-color});
        --selected-bg: var(--#{$nav-pills-link-selected-bg});

        border-radius: var(--border-radius, #{$nav-pills-border-radius});

        &.selected,
        .show > .nav-link {
            background: var(--selected-bg, ui-color(#{$nav-pills-link-selected-bg}));
        }
    }
}

// Segmented
//
// Use to alternate among related views within the same context.
//
// Markup: nav-segmented.html
//
// Weight: 4
//
// Style guide: components.nav.segmented

.nav-segmented {
    .nav-link {
        flex-direction: column;
        align-items: start;
        padding: spacer(large) spacer(huge);
        color: ui-color(neutral-end);

        small {
            margin-bottom: $spacer;
            color: ui-color(neutral-base);
        }

        &.selected {
            color: white;
            background-color: ui-color(primary-base);

            small {
                color: inherit;
            }
        }
    }
}

// Control width
//
// Weight: 5
//
// Style guide: components.nav.width

// Full width, items space based on the content
//
// Use <code>.nav-fill</code> modifier to make the <code>.nav</code> fill all available space. Notice that every nav item has different width dependently of its content.
//
// Markup:
// <ul class="nav nav-tabs nav-fill">
//     <li class="nav-item">
//         <a href="#" class="nav-link selected" aria-current="page">This is longer so it takes more space</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
// </ul>
//
// Weight: 1
//
// Style guide: components.nav.width.fill

// Full width, items space equal
//
// Use <code>.nav-justified</code> modifier to make the <code>.nav</code> fill all available space. Notice that every nav item has same width independently of its content.
//
// Markup:
// <ul class="nav nav-tabs nav-justified">
//     <li class="nav-item">
//         <a href="#" class="nav-link selected" aria-current="page">This is longer but doesn't take more space</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
//     <li class="nav-item">
//         <a href="#" class="nav-link">Item</a>
//     <li>
// </ul>
//
// Weight: 2
//
// Style guide: components.nav.width.justified

.nav-fill {

    .nav-item {
        flex: 1 1 auto;
    }

    .nav-link {
        justify-content: center;
        text-align: center;
    }
}

.nav-justified {
    align-items: flex-end;

    .nav-item {
        flex-basis: 0;
        flex-grow: 1;
    }

    .nav-link {
        justify-content: center;
        text-align: center;
    }
}

// Config options
//
// <pre><code class="sass">$nav-link-padding-y:                small !default;
// $nav-link-padding-x:                large !default;
// $nav-link-font-size:                null !default;
// $nav-link-font-weight:              null !default;
// $nav-link-color:                    neutral-dark !default;
// $nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
// $nav-link-hover-color:              neutral-end !default;
// $nav-link-selected-color:           primary-base !default;
// $nav-link-disabled-color:           neutral-base !default;
//
// $nav-link-icon-margin-x:            small !default;
//
// $nav-tabs-border-color:             neutral-lightest !default;
// $nav-tabs-border-width:             $border-width * 2 !default;
//
// $nav-tabs-link-hover-border-color:    $nav-link-hover-color !default;
// $nav-tabs-link-selected-color:        $nav-link-selected-color !default;
// $nav-tabs-link-selected-border-color: $nav-tabs-link-selected-color !default;
//
// $nav-pills-border-radius:           $border-radius !default;
// $nav-pills-link-selected-color:     primary-base !default;
// $nav-pills-link-selected-bg:        primary-lightest !default;</code></pre>
//
// Weight: 10
//
// Style guide: components.nav.config
