@import "../00-settings/index";
@import "../01-tools/index";

// Filterbar
//
// Markup: _filterbar.html
//
// Style guide: objects.filterbar

.filterbar {

    &.row {
        --gap-x: var(--small);
        --gap-y: var(--small);
    }

    .form-control {
        @include input-size($input-padding-y-sm, $input-padding-x-sm, $input-font-scale-sm, $input-border-radius-sm);
    }

    .form-select {
        @include input-size($form-select-padding-y-sm, $form-select-padding-x-sm, $form-select-font-scale-sm, $form-select-border-radius-sm);
    }

    .btn {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-scale-sm, $btn-border-radius-sm);

        // scale the icon
        .icon {
            @include icon-size($icon-size-sm);
        }
    }
}
