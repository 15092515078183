$base-width: 1400px;

@mixin base-layout() {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.o-base-layout {
  @include base-layout();
}
