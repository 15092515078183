.onebp-u-bold {
  font-weight: 600;
}

.onebp-u-cursor-default {
  cursor: default !important;
}

.onebp-u-flex {
  display: flex;
}

.onebp-u-flex-centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.onebp-u-liststyle-none {
  list-style: none;
}

.onebp-u-nowrap {
  white-space: nowrap;
}

.onebp-u-overflow-wrap {
  overflow-wrap: anywhere;
}

.onebp-u-pointer {
  cursor: pointer !important;
}

.onebp-u-relative {
  position: relative;
}

.onebp-u-right {
  float: right;
}

.onebp-u-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.onebp-u-text-ellipsis-normal-space {
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: normal;
}

.onebp-u-text-right {
  text-align: right;
}

.onebp-u-text-word-break {
  word-break: break-all;
}

.onebp-u-underline {
  text-decoration: underline;
}

.onebp-width-100 {
  width: 100%;
}

.wipo-u-w-900 {
  max-width: 900px;
}

.wipo-u-hidden {
  display: none;
}

.wipo-m-auto {
  margin: 0 auto;
}

.wipo-u-pointer-events-none {
  pointer-events: none;
}

.wipo-u-separator {
  border-bottom: 1px solid var(--neutral-base);
}

.wipo-u-show {
  display: block;
}

.wipo-u-text-center {
  text-align: center;
}

.wipo-text-14 {
  font-size: 14px;
}

html,
body {
  height: 100%;
  min-height: 100%;
  overflow: inherit;
}
html.kss-body,
body.kss-body {
  overflow: auto;
}

body.-noScroll {
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

b,
strong {
  font-weight: bold;
}

a:hover {
  color: var(--hover-color);
}

[type=submit] {
  appearance: none;
}

input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button,
input::-webkit-search-cancel-button {
  opacity: 0;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.o-base-layout {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.wg-badge-container {
  display: flex;
  position: relative;
}
.wg-badge-container .badge {
  align-items: center;
  align-self: center;
  background-color: var(--primary-base);
  color: #fff;
  display: flex;
  max-height: 25px;
}
.wg-badge-container .badge.top {
  top: -15px;
}
.wg-badge-container .badge.bottom {
  bottom: -15px;
}
.wg-badge-container .badge.left {
  left: -15px;
}
.wg-badge-container .badge.right {
  right: -15px;
}

.c-action-block {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  text-align: center;
}

.ant-picker {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
  transition: color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
  width: 100%;
}

.anticon {
  vertical-align: middle;
}

.ant-picker:focus, .ant-picker-focused {
  color: var(--primary-base);
}

.ant-picker.ng-invalid.ng-touched {
  border-color: var(--comet-color-invalid);
}

.ant-picker-disabled .anticon {
  color: var(--lumo-disabled-text-color);
}

.ant-input-disabled {
  color: var(--lumo-disabled-text-color) !important;
}
.ant-input-disabled::placeholder {
  color: transparent;
}

.ant-picker.ng-invalid.ng-touched:hover {
  background-color: var(--comet-color-invalid-hover);
}

.ant-picker:hover {
  background-color: var(--comet-color-hover);
}

.ant-picker-input {
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;
}
.ant-picker-input input {
  background-color: transparent;
  border: 0;
  color: var(--primary-dark);
  font-size: var(--font-size);
  font-weight: 400;
  transition: all 0.3s;
  width: 100%;
}
.ant-picker-input input:focus-visible {
  box-shadow: none;
}

.ant-picker-disabled {
  background-color: var(--comet-color-disabled-bg);
  border: none;
}

.ant-picker-disabled:hover {
  background-color: var(--comet-color-disabled-bg);
}

.ant-picker-clear {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.3s, color 0.3s;
}

.ant-picker:hover .ant-picker-clear {
  background-color: var(--comet-color-hover);
  color: var(--color, #301254);
  opacity: 1;
}

.ant-picker-dropdown {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  left: unset;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  top: unset;
}

.ant-picker-inline {
  border: none;
  padding: 0;
  width: fit-content;
}

.ant-picker-panel-container {
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: margin 0.3s;
  vertical-align: top;
}

.ant-picker-panel {
  background: var(--neutral-start);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  display: inline-flex;
  flex-direction: column;
  outline: none;
  text-align: center;
}

.ant-picker-panel-container .ant-picker-panel {
  background: transparent;
  border-radius: 0;
  border-width: 0 0 1px;
  vertical-align: top;
}

.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.ant-picker-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  color: var(--primary-dark);
  display: flex;
  padding: 0 8px;
}

.ant-picker-header > button {
  font-size: 14px;
  min-width: 1.6em;
}

.ant-picker-header button {
  background: transparent;
  border: 0;
  color: var(--primary-light);
  cursor: pointer;
  line-height: 40px;
  padding: 0;
  transition: color 0.3s;
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-cell {
  cursor: pointer;
  padding: 3px 0;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 2px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
  position: relative;
  transition: background 0.3s, border 0.3s;
  z-index: 2;
}

.ant-picker-cell-selected {
  background-color: var(--primary-base);
  color: var(--neutral-start);
}

.ant-picker-cell-in-range {
  background-color: var(--primary-light);
  color: var(--neutral-start);
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.2509803922);
  pointer-events: none;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-picker-footer {
  border-bottom: 1px solid transparent;
  line-height: 38px;
  min-width: 100%;
  text-align: center;
  width: min-content;
}
.ant-picker-footer a {
  font-weight: 600;
}

.ant-picker-ranges {
  line-height: 34px;
  list-style: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 4px 12px;
  text-align: left;
}
.ant-picker-ranges li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-ranges .ant-picker-ok button {
  border-radius: 4px;
  font-weight: 600;
}
.ant-picker-ranges .ant-picker-ok .ant-btn-primary {
  background-color: var(--primary-base);
  border-color: var(--primary-base);
  box-shadow: 0 2px rgba(0, 0, 0, 0.0431372549);
  color: var(--neutral-start);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}
.ant-picker-ranges .ant-picker-ok .ant-btn-primary:hover {
  background-color: var(--primary-dark);
}
.ant-picker-ranges .ant-picker-ok .ant-btn-primary[disabled], .ant-picker-ranges .ant-picker-ok .ant-btn-primary[disabled]:hover, .ant-picker-ranges .ant-picker-ok .ant-btn-primary[disabled]:focus, .ant-picker-ranges .ant-picker-ok .ant-btn-primary[disabled]:active {
  background: #f5f5f5;
  border-color: var(--neutral-base);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.2509803922);
  text-shadow: none;
}

.ant-picker-prev-icon, .ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}

.ant-picker-next-icon, .ant-picker-super-next-icon {
  transform: rotate(135deg);
}

.ant-picker-prev-icon, .ant-picker-next-icon, .ant-picker-super-prev-icon, .ant-picker-super-next-icon {
  display: inline-block;
  height: 7px;
  position: relative;
  width: 7px;
}

.ant-picker-prev-icon::before, .ant-picker-next-icon::before, .ant-picker-super-prev-icon::before, .ant-picker-super-next-icon::before {
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: "";
  display: inline-block;
  height: 7px;
  left: 0;
  position: absolute;
  top: 0;
  width: 7px;
}

.ant-picker-super-prev-icon::after, .ant-picker-super-next-icon::after {
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: "";
  display: inline-block;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.ant-picker-header > button:hover {
  color: var(--primary-base);
}

.ant-picker-range {
  display: inline-flex;
  position: relative;
}

.ant-picker-range-separator {
  align-items: center;
  line-height: 1;
  padding: 0 8px;
}

.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-content {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
  padding-left: 14px;
  padding-top: 4px;
}

.ant-picker-time-panel {
  min-width: auto;
  width: auto;
}

.ant-picker-time-panel-column {
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  overflow-y: hidden;
  padding: 0;
  text-align: left;
  transition: background 0.3s;
  width: 50px;
}

.ant-picker-time-panel-column:hover {
  overflow-y: scroll;
}

.ant-picker-time-panel-column > li .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  border-radius: 0;
  color: var(--primary-dark);
  cursor: pointer;
  display: block;
  height: 28px;
  line-height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  transition: background 0.3s;
  width: 100%;
}

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}

.ant-picker-time-panel-cell-selected {
  background-color: var(--primary-light);
  color: var(--neutral-start);
}

.anticon-double-right {
  color: var(--primary-base) !important;
}

.ant-pagination-item-link {
  color: var(--primary-base) !important;
}
.ant-pagination-item-link:hover {
  border-color: var(--primary-base) !important;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item:hover {
  border-color: var(--primary-base);
}

.ant-pagination-item a,
.ant-pagination-item:hover a {
  color: var(--primary-base);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-base);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary-base);
}

.ant-table-pagination,
.ant-pagination {
  margin: 32px 0 16px !important;
}

.ant-select-item-option,
.ant-select-selection-item {
  color: var(--primary-base) !important;
}

.ant-select-item-option-selected {
  background-color: var(--primary-lightest) !important;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: var(--primary-light) !important;
}

.ant-spin {
  color: var(--primary-dark) !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-table-row:hover {
  background-color: none;
}

.ant-table-expanded-row {
  background-color: none;
}

.ant-select:not(.ant-pagination-options-size-changer) .ant-select-selector {
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 400;
  height: 50px;
  padding: 10px 15px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 50px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input:focus-visible {
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled) .ant-select-selector {
  border-color: var(--neutral-base);
  color: var(--primary-100);
}

.ant-select-dropdown {
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
}

/* stylelint-disable no-descending-specificity */
.ant-drawer {
  height: 100%;
  position: fixed;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
  width: 0%;
  z-index: 1000;
}

.ant-drawer-content-wrapper {
  height: 100%;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
}

.ant-drawer .ant-drawer-content {
  height: 100%;
  width: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
  height: 100%;
  top: 0;
  width: 0%;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
}

.ant-drawer-left {
  left: 0;
}

.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right {
  right: 0;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}

.ant-drawer-top,
.ant-drawer-bottom {
  height: 0%;
  left: 0;
  width: 100%;
}

.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}

.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer-top {
  top: 0;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom {
  bottom: 0;
}

.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  height: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: none;
}

.ant-drawer-title {
  color: rgba(0, 0, 0, 0.85);
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}

.ant-drawer-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.ant-drawer-close {
  background: transparent;
  border: 0;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-right: 12px;
  outline: 0;
  text-align: center;
  text-decoration: none;
  text-rendering: auto;
  text-transform: none;
  transition: color 0.3s;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-drawer-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  position: relative;
}

.ant-drawer-header-title {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.ant-drawer-header-close-only {
  border: none;
  padding-bottom: 0;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
}

.ant-drawer-body {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5715;
  overflow: auto;
  padding: 24px;
  word-wrap: break-word;
}

.ant-drawer-footer {
  border-top: 1px solid #f0f0f0;
  flex-shrink: 0;
  padding: 10px 16px;
}

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.45);
  height: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  width: 100%;
}

.ant-drawer .ant-picker-clear {
  background: #fff;
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-left: 12px;
  margin-right: 0;
}

.pac-container {
  border: 1px solid var(--neutral-base);
  border-radius: var(--tiny);
  box-shadow: none small small rgba(43, 49, 58, 0.25);
  padding-bottom: var(--small);
  padding-top: var(--small);
}
.pac-container.pac-logo::after {
  display: none;
}

.pac-icon {
  display: none;
}

.pac-item {
  border-top: none;
  color: var(--neutral-darkest);
  font-size: var(--medium);
  line-height: var(--medium);
  padding: var(--small) var(--large);
}
.pac-item:first-child {
  margin-top: var(--small);
}
.pac-item:last-child {
  margin-bottom: var(--small);
}

.pac-item:hover,
.pac-item:focus {
  background-color: var(--primary-lightest);
}

.pack-item-selected {
  background-color: var(--bg-body);
}

.pac-item-query {
  color: var(--neutral-dark);
  font-size: var(--large);
  line-height: var(--large);
}
.pac-item-query::after {
  content: "\a";
  white-space: pre;
}

.pac-matched {
  font-size: var(--large);
}

/* TODO: Use info banner */
.c-formInformation.-confirmation, .c-formInformation.-info, .c-formInformation.-warning, .c-formInformation.-danger, .c-formInformation.-base {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: 14px;
  line-height: 19px;
  padding: 16px;
}
.c-formInformation.-danger {
  background-color: var(--danger-light);
  color: var(--danger-darkest);
}
.c-formInformation.-warning {
  background-color: var(--warning-light);
  color: var(--warning-darkest);
}
.c-formInformation.-warningImage {
  background-image: linear-gradient(74deg, var(--warning-light) -1%, var(--neutral-start) 75%);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.05);
  padding: 24px 48px;
}
.c-formInformation.-confirmation {
  background-color: var(--info-light);
  color: var(--info-darkest);
}
.c-formInformation.-info {
  background-color: var(--info-light);
  color: var(--info-darkest);
}
.c-formInformation.-base {
  background-color: var(--neutral-base);
}

.c-form__separator {
  border-bottom: 1px solid var(--neutral-base);
}

.c-formLogin {
  max-width: 500px;
}

.c-formThin {
  margin: 0 auto;
  max-width: 900px;
}
.c-formThin.-public {
  flex-grow: inherit;
  margin: 48px auto;
}

.-public {
  flex-grow: inherit;
  margin: 48px auto;
  max-width: 900px;
}

/* Create a custom checkbox */
.c-customCheckbox {
  background-color: var(--neutral-start);
  border: 1px solid var(--primary-base);
  border-radius: 4px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  /* Create the checkmark/indicator (hidden when not checked) */
}
.c-customCheckbox::after {
  content: "";
  display: none;
  position: absolute;
}

/* Customize the label (the container) */
.c-inputCheckbox {
  cursor: pointer;
  display: block;
  margin-bottom: 12px;
  padding-left: 35px;
  position: relative;
  user-select: none;
  /* Hide the browsers default checkbox */
  /* On mouse-over, add a white background color */
  /* When the checkbox is checked, add a blue background */
  /* Style the checkmark/indicator */
}
.c-inputCheckbox input[type=checkbox] {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.c-inputCheckbox:hover input[type=checkbox] ~ .c-customCheckbox {
  background-color: var(--neutral-start);
}
.c-inputCheckbox input[type=checkbox]:checked ~ .c-customCheckbox {
  background-color: var(--primary-base);
}
.c-inputCheckbox .c-customCheckbox::after {
  border: solid var(--neutral-start);
  border-width: 0 2px 2px 0;
  height: 14px;
  left: 7px;
  top: 1px;
  transform: rotate(45deg);
  width: 6px;
}
.c-inputCheckbox input[type=checkbox]:checked ~ .c-customCheckbox::after {
  display: block;
}

.c-inputDropdownOptionsPanel {
  align-self: center;
  background-color: var(--neutral-start);
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  color: var(--neutral-darkest);
  display: block;
  left: 0;
  margin-top: 4px;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  right: 0;
  top: 82px;
  transition: border-color 0.2s ease, border-width 0.2s ease;
  transition-duration: 3s;
  width: auto;
  z-index: 100;
}
.c-inputDropdownOptionsPanel:focus {
  outline: none;
}
.c-inputDropdownOptionsPanel.-invalid {
  top: 82px;
}
.c-inputDropdownOptionsPanel.-compact {
  max-height: 156px;
}
.c-inputDropdownOptionsPanel.-compressed {
  top: 40px;
}
.c-inputDropdownOptionsPanel.-noLabel {
  text-align: left;
  top: 50px;
}
.c-inputDropdownOptionsPanel .c-inputDropdownOption {
  min-height: 38px;
  padding: 10px 16px;
}
.c-inputDropdownOptionsPanel .c-inputDropdownOption:hover {
  background-color: var(--neutral-lightest);
  cursor: pointer;
}
.c-inputDropdownOptionsPanel .c-inputDropdownOption.-selected {
  background-color: var(--primary-lightest);
  color: var(--primary-base);
}

.c-inputDropdownContainer {
  display: flex;
  position: relative;
  width: 100%;
}
.c-inputDropdownContainer input.-compressed {
  height: 42px;
}

.c-inputDropdownContainerFlex {
  flex: 1 auto;
  position: relative;
}

.c-inputDropdownDummyClick {
  inset: 0;
  position: absolute;
}

/* stylelint-disable no-duplicate-selectors */
.c-inputRadio input[type=radio] {
  appearance: none;
  background-color: var(--neutral-start);
  border: 2px solid var(--primary-base);
  border-radius: 20px;
  cursor: pointer;
  height: 1px;
  margin-right: 16px;
  outline: 0;
  padding: 9px;
  position: relative;
  width: 1px;
}
.c-inputRadio input[type=radio]:checked::after {
  background-color: var(--primary-base);
  border-radius: 100px;
  content: "";
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 8px;
}
.c-inputRadio input[type=radio].-noBorder {
  margin-left: 0;
}
.c-inputRadio input[type=radio].-invalid {
  border: 2px solid var(--danger-base);
}
.c-inputRadio input[type=radio].-disabled {
  border: 2px solid var(--neutral-dark);
}
.c-inputRadio input[type=radio].-disabled:checked::after {
  background-color: var(--neutral-dark);
}

.c-inputRadio {
  align-items: center;
  border: 1px solid var(--neutral-base);
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: flex-start;
  margin-left: 0;
  min-height: 50px;
  outline-color: var(--neutral-start);
  overflow: hidden;
  padding: 9px 24px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}
.c-inputRadio.-noBorder {
  border: none;
  cursor: default;
  margin-left: 0 !important;
  margin-right: 24px;
  padding: 9px 8px 0;
}
.c-inputRadio.-noBorder input {
  cursor: pointer;
}
.c-inputRadio.-noBorder.-invalid {
  border: none;
}
.c-inputRadio.-block {
  display: flex;
}
.c-inputRadio.-disabled {
  color: var(--neutral-dark);
  pointer-events: none;
}
.c-inputRadio.-invalid {
  border: 1px solid var(--danger-base);
  color: var(--danger-base);
}

.c-inputRadio.-small {
  margin-left: 14px;
  margin-right: 18px;
  min-height: 40px;
  padding: 0;
}
.c-inputRadio.-small.-noBorder {
  margin-right: 0;
}
.c-inputRadio.-small input[type=radio] {
  padding: 8px;
}
.c-inputRadio.-small input[type=radio]:checked::after {
  height: 10px;
  left: 3px;
  top: 3px;
  width: 10px;
}

/* stylelint-enable no-duplicate-selectors */
/* stylelint-disable no-descending-specificity, no-duplicate-selectors */
.c-input__text {
  align-self: center;
  background-color: var(--neutral-start);
  border: solid 1px var(--neutral-base);
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  height: 50px;
  min-height: 50px;
  padding-left: 16px;
  transition: border-color 0.2s ease, border-width 0.2s ease;
  width: 100%;
}
.c-input__text:focus, .c-input__text.-focus {
  border-color: var(--primary-base);
  border-width: 2px;
  box-shadow: 0 0 4px 10px var(--neutral-start);
  outline: 1px solid transparent;
}
.c-input__text.-text-area {
  height: inherit;
  padding-top: 24px;
}

.c-input__text:disabled {
  background-color: var(--neutral-light);
  border-color: var(--neutral-dark);
  color: var(--neutral-dark);
  position: relative;
}

.c-input__text:focus + .c-label__text,
.c-input__text.-focus + .c-label__text {
  color: var(--primary-base);
}

.c-input__text:focus + .c-label__text,
.c-input__text.-activeLabel + .c-label__text,
.c-input__text.-activeLabel.-focus + .c-label__text,
.c-input__text.ng-valid:not(.ng-pristine) + .c-label__text,
.c-input__text.ng-valid.ng-touched + .c-label__text,
.c-input__text.ng-invalid.ng-touched + .c-label__text,
.c-input__text:disabled + .c-label__text {
  font-size: 13px;
  transform: translate3d(0, -25px, 0);
}

.c-input__text:not(.ng-untouched).ng-invalid {
  border-color: var(--danger-base);
}
.-submitted.ng-invalid .c-input__text:not(.ng-untouched).ng-invalid {
  border-color: var(--danger-base);
}

.-submitted.ng-invalid .c-input__text.ng-invalid {
  border-color: var(--danger-base);
  color: var(--danger-base);
}

.-submitted.ng-invalid .c-input__text.ng-invalid + .c-label__text,
.-submitted.ng-invalid .c-input__text.ng-invalid:focus + .c-label__text {
  color: var(--danger-base);
}

.c-input__text:not(.ng-untouched).ng-invalid + .c-label__text {
  color: var(--danger-base);
}

.form-control:disabled {
  background-color: var(--disabled-bg);
  border: none;
  color: var(--disabled-color);
  padding-left: 16px;
}

/* stylelint-enable no-descending-specificity, no-duplicate-selectors */
input.c-input__text:-webkit-autofill,
input.c-input__text:-webkit-autofill:hover,
input.c-input__text:-webkit-autofill:focus,
input.c-input__text:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--neutral-start) inset !important;
}

.grecaptcha-badge {
  bottom: 105px !important;
  z-index: 2;
}

:root {
  --neutral-0: #fff;
  --neutral-10: #f7f6f4;
  --neutral-20: #f1f0ee;
  --neutral-30: #ebeae8;
  --neutral-40: #e0dfdc;
  --neutral-50: #c7c6c5;
  --neutral-60: #a5a4a1;
  --neutral-70: #575655;
  --neutral-80: #3d3d3d;
  --neutral-90: #303030;
  --neutral-100: #242424;
  --primary-10: #faf7ff;
  --primary-20: #cec0e0;
  --primary-30: #b194ca;
  --primary-40: #9d71dc;
  --primary-50: #501e96;
  --primary-60: #3d127a;
  --primary-70: #340970;
  --primary-80: #301254;
  --primary-90: #21024c;
  --primary-100: #18092a;
  --secondary-10: #ebfaf8;
  --secondary-20: #d9f7f3;
  --secondary-30: #bde8f1;
  --secondary-40: #a0e1dc;
  --secondary-50: #28d7dc;
  --secondary-60: #0fc3c8;
  --secondary-70: #01b4bb;
  --secondary-80: #01abb2;
  --secondary-90: #008c96;
  --secondary-100: #003033;
  --tertiary-10: #fff8ed;
  --tertiary-20: #fff1dd;
  --tertiary-30: #f3e2b3;
  --tertiary-40: #f7d51e;
  --tertiary-50: #fbc326;
  --tertiary-60: #fba626;
  --tertiary-70: #fb8c26;
  --tertiary-80: #e77710;
  --tertiary-90: #ab5608;
  --tertiary-100: #301903;
  --success-10: #def7e8;
  --success-20: #c6f1d7;
  --success-30: #b0f2cb;
  --success-40: #24c665;
  --success-50: #1daf57;
  --success-60: #148541;
  --success-70: #077734;
  --success-80: #0f5c2e;
  --success-90: #0b4a24;
  --success-100: #083419;
  --info-10: #e9f4fd;
  --info-20: #d0e8fb;
  --info-30: #5a9eed;
  --info-40: #197bec;
  --info-50: #116cd6;
  --info-60: #0c53a7;
  --info-70: #054085;
  --info-80: #063974;
  --info-darkest-90: #023067;
  --info-darkest-100: #011e41;
  --warning-10: #fef6e4;
  --warning-20: #feecc3;
  --warning-30: #fee4a7;
  --warning-40: #f1aa51;
  --warning-50: #ed9424;
  --warning-60: #d0790b;
  --warning-70: #935608;
  --warning-80: #6a471b;
  --warning-90: #4c3211;
  --warning-100: #241809;
  --danger-10: #fff0ee;
  --danger-20: #fededc;
  --danger-30: #feb9b4;
  --danger-base-40: #ea564e;
  --danger-50: #ea3e35;
  --danger-60: #c0241b;
  --danger-70: #9e1710;
  --danger-80: #7d201c;
  --danger-90: #621613;
  --danger-100: #3b0907;
}

.grid-row-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

@font-face {
  font-weight: bold;
  font-family: "Kyn";
  font-style: normal;
  src: url("../../fonts/Kyn/Kyn-Bold.eot");
  src: url("../../fonts/Kyn/Kyn-Bold.woff") format("woff");
  src: url("../../fonts/Kyn/Kyn-Bold.woff2") format("woff2");
  font-display: auto;
}
@font-face {
  font-weight: 500;
  font-family: "Kyn";
  font-style: normal;
  src: url("../../fonts/Kyn/Kyn-Medium.eot");
  src: url("../../fonts/Kyn/Kyn-Medium.woff") format("woff");
  src: url("../../fonts/Kyn/Kyn-Medium.woff2") format("woff2");
  font-display: auto;
}
@font-face {
  font-weight: normal;
  font-family: "Kyn";
  font-style: normal;
  src: url("../../fonts/Kyn/Kyn-Regular.eot");
  src: url("../../fonts/Kyn/Kyn-Regular.woff") format("woff");
  src: url("../../fonts/Kyn/Kyn-Regular.woff2") format("woff2");
  font-display: auto;
}
@font-face {
  font-weight: 600;
  font-family: "Kyn";
  font-style: normal;
  src: url("../../fonts/Kyn/Kyn-Semibold.eot");
  src: url("../../fonts/Kyn/Kyn-Semibold.woff") format("woff");
  src: url("../../fonts/Kyn/Kyn-Semibold.woff2") format("woff2");
  font-display: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--body-color, var(--neutral-end));
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 400;
  font-family: "Kyn", -apple-system, blinkmacsystemfont, avenir next, avenir, segoe ui, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  background: var(--body-bg, var(--neutral-start));
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:focus,
:focus-visible {
  outline: 0 !important;
}

:focus-visible {
  --focus-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.9), 0 0 0 4px var(--neutral-end);
  box-shadow: none;
}

hr {
  --margin-y: var(--large);
  --color: var(--neutral-light);
  margin: var(--margin-y, 1rem) 0;
  color: var(--color, #e9e6ed);
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

[class^=text-heading-],
[class^=text-display-], h6, h5, h4, h3, h2, h1 {
  --margin-bottom: var(--small);
  margin-top: 0;
  margin-bottom: var(--margin-bottom, 0.5rem);
  font-family: "Kyn", -apple-system, blinkmacsystemfont, avenir next, avenir, segoe ui, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-style: normal;
}

h1 {
  --font-size: 2rem;
  --line-height: 1.25;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  h1 {
    --font-size: 3.125rem;
    --line-height: 1.04;
  }
}

h2 {
  --font-size: 1.5rem;
  --line-height: 1.3333333333;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  h2 {
    --font-size: 2rem;
    --line-height: 1.25;
  }
}

h3 {
  --font-size: 1.375rem;
  --line-height: 1.2727272727;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

h4 {
  --font-size: 1.1875rem;
  --line-height: 1.2631578947;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

h5 {
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

h6 {
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

p {
  --margin-bottom: var(--large);
  margin-top: 0;
  margin-bottom: var(--margin-bottom, 1rem);
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: var(--large, 1rem);
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: var(--gigantic, 2rem);
}

ol,
ul,
dl {
  --margin-bottom: var(--large);
  margin-top: 0;
  margin-bottom: var(--margin-bottom, 1rem);
}

ol ol,
ul ul,
ol ul,
ul ol {
  --margin-bottom: var(--none);
}

dt {
  font-weight: 600;
}

dd {
  --margin-bottom: var(--small);
  margin-bottom: var(--margin-bottom, 0.5rem);
  margin-left: 0;
}

blockquote {
  --margin-bottom: var(--large);
  margin: 0 0 var(--margin-bottom, 1rem);
}

b,
strong {
  font-weight: 600;
}

small, .small {
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

mark {
  padding: var(0.2em, );
  background: var(--warning-light, #feecc3);
}

sub,
sup {
  position: relative;
  font-size: 0.5em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  --color: var(--primary-base);
  --hover-color: var(--primary-dark);
  color: var(--color, #501e96);
  text-decoration: none;
}
a:hover {
  --color: var(--hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  --color: var(--);
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
}

pre {
  --margin-bottom: var(--large);
  --color: var(--danger-base);
  --bg: var(--neutral-lightest);
  display: block;
  margin-top: 0;
  margin-bottom: var(--margin-bottom, 1rem);
  overflow: auto;
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color, #ea3e35);
  background: var(--bg, #f9f8fa);
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

code {
  --color: var(--danger-base);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color, #ea3e35);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: var(-- 0.2rem, ) var(-- 0.4rem, );
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--danger-base, #ea3e35);
  background: var(--neutral-lightest, #f9f8fa);
  border-radius: 4px;
}
kbd kbd {
  padding: 0;
  font-weight: 600;
  font-size: 1em;
}

figure {
  --margin-bottom: var(--large);
  margin: 0 0 var(--margin-bottom, 1rem);
}

img,
svg {
  vertical-align: middle;
}

table {
  font-size: body;
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  --color: var(--neutral-dark);
  padding-top: var(--medium, 0.75rem);
  padding-bottom: var(--medium, 0.75rem);
  color: var(--color, #6e5987);
  text-align: left;
}

th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  --margin-bottom: var(--large);
  float: left;
  width: 100%;
  margin-bottom: var(--margin-bottom, 1rem);
  padding: 0;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --transparent: transparent;
  --neutral-start: #fff;
  --neutral-lightest: #f9f8fa;
  --neutral-light: #e9e6ed;
  --neutral-base: #ada5b6;
  --neutral-dark: #6e5987;
  --neutral-darkest: #492e69;
  --neutral-end: #301254;
  --primary-lightest: #faf7ff;
  --primary-light: #7878fa;
  --primary-base: #501e96;
  --primary-dark: #301254;
  --secondary-lightest: #ebfaf8;
  --secondary-light: #a0e1dc;
  --secondary-base: #28d7dc;
  --secondary-dark: #008c96;
  --tertiary-lightest: #fff8ed;
  --tertiary-light: #ffddab;
  --tertiary-base: #ffc878;
  --tertiary-dark: #fa8c50;
  --tertiary-darkest: #e84b5a;
  --success-light: #c6f1d7;
  --success-base: #1daf57;
  --success-dark: #148541;
  --success-darkest: #0f5c2e;
  --info-light: #d0e8fb;
  --info-base: #116cd6;
  --info-dark: #0c53a7;
  --info-darkest: #063974;
  --warning-light: #feecc3;
  --warning-base: #ed9424;
  --warning-dark: #d0790b;
  --warning-darkest: #6a471b;
  --danger-light: #fededc;
  --danger-base: #ea3e35;
  --danger-dark: #c0241b;
  --danger-darkest: #7d201c;
  --body-color: var(--neutral-end);
  --body-bg: var(--neutral-start);
  --color-transparent: transparent;
  --none: 0;
  --tiny: 0.25rem;
  --small: 0.5rem;
  --medium: 0.75rem;
  --large: 1rem;
  --huge: 1.5rem;
  --gigantic: 2rem;
  --huge2x: 3rem;
  --gigantic2x: 4rem;
  --gigantic4x: 8rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

figure {
  margin: 0;
}

form > *,
form fieldset > *,
form .col {
  margin-bottom: 1.5rem;
}
form fieldset,
form > button,
form > *:last-child,
form > .row {
  margin-bottom: inherit;
}

legend {
  color: neutral-end;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  text-transform: uppercase;
}

hr {
  color: var(--neutral-light, #e9e6ed);
}

mark {
  background-color: #7878fa;
}

summary {
  cursor: pointer;
}

svg {
  overflow: hidden;
  fill: currentColor;
}

caption {
  color: neutral-dark;
}

.blockquote {
  margin-bottom: 0.25rem;
  --font-size: 1.125rem;
  --line-height: 1.5555555556;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .blockquote {
    --font-size: 1.25rem;
    --line-height: 1.6;
  }
}

.btn-close {
  --size: 1em;
  --padding-y: 0.25em;
  --padding-x: 0.25em;
  box-sizing: content-box;
  width: var(--size, 1em);
  height: var(--size, 1em);
  padding: var(--padding-y, 0.25em) var(--padding-x, 0.25em);
  color: var(--color, );
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23301254'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 0.5;
}
.btn-close:hover {
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: none;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  opacity: 0.25;
  user-select: none;
  pointer-events: none;
}

.container,
.container-fluid,
.container-tv,
.container-desktop,
.container-laptop,
.container-tablet {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--none) * 0.5);
  padding-left: calc(var(--none) * 0.5);
}

@media (min-width: 600px) {
  .container-tablet, .container {
    max-width: 100%;
  }
}
@media (min-width: 900px) {
  .container-laptop, .container-tablet, .container {
    max-width: 1080px;
  }
}
@media (min-width: 1200px) {
  .container-desktop, .container-laptop, .container-tablet, .container {
    max-width: 1080px;
  }
}
@media (min-width: 1800px) {
  .container-tv, .container-desktop, .container-laptop, .container-tablet, .container {
    max-width: 1080px;
  }
}
.grid {
  --gap-y: var(--none);
  --gap-x: var(--none);
  display: grid;
  gap: var(--gap-y, 0) var(--gap-x, 0);
}

.cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

.cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.cols-5 {
  grid-template-columns: repeat(5, 1fr);
}

.cols-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (min-width: 600px) {
  .cols-tablet-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cols-tablet-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols-tablet-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols-tablet-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols-tablet-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .cols-tablet-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 900px) {
  .cols-laptop-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cols-laptop-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols-laptop-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols-laptop-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols-laptop-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .cols-laptop-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1200px) {
  .cols-desktop-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cols-desktop-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols-desktop-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols-desktop-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols-desktop-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .cols-desktop-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1800px) {
  .cols-tv-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cols-tv-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .cols-tv-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .cols-tv-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .cols-tv-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .cols-tv-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.row {
  --gap-y: var(--none);
  --gap-x: var(--none);
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap-y) * -1);
  margin-right: calc(var(--gap-x) / -2);
  margin-left: calc(var(--gap-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-top: var(--gap-y);
  padding-right: calc(var(--gap-x) / 2);
  padding-left: calc(var(--gap-x) / 2);
}

.col {
  flex: 1 0 0%;
}

.cols-auto > [class*=col] {
  flex: 0 0 auto;
  width: auto;
}

.cols-1 > [class*=col] {
  flex: 0 0 auto;
  width: 100%;
}

.cols-2 > [class*=col] {
  flex: 0 0 auto;
  width: 50%;
}

.cols-3 > [class*=col] {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.cols-4 > [class*=col] {
  flex: 0 0 auto;
  width: 25%;
}

.cols-5 > [class*=col] {
  flex: 0 0 auto;
  width: 20%;
}

.cols-6 > [class*=col] {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 600px) {
  .col-tablet {
    flex: 1 0 0%;
  }
  .cols-tablet-auto > [class*=col] {
    flex: 0 0 auto;
    width: auto;
  }
  .cols-tablet-1 > [class*=col] {
    flex: 0 0 auto;
    width: 100%;
  }
  .cols-tablet-2 > [class*=col] {
    flex: 0 0 auto;
    width: 50%;
  }
  .cols-tablet-3 > [class*=col] {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .cols-tablet-4 > [class*=col] {
    flex: 0 0 auto;
    width: 25%;
  }
  .cols-tablet-5 > [class*=col] {
    flex: 0 0 auto;
    width: 20%;
  }
  .cols-tablet-6 > [class*=col] {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-tablet-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-tablet-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-tablet-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-tablet-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-tablet-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-tablet-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-tablet-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-tablet-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-tablet-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-tablet-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-tablet-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-tablet-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-tablet-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-tablet-0 {
    margin-left: 0;
  }
  .offset-tablet-1 {
    margin-left: 8.3333333333%;
  }
  .offset-tablet-2 {
    margin-left: 16.6666666667%;
  }
  .offset-tablet-3 {
    margin-left: 25%;
  }
  .offset-tablet-4 {
    margin-left: 33.3333333333%;
  }
  .offset-tablet-5 {
    margin-left: 41.6666666667%;
  }
  .offset-tablet-6 {
    margin-left: 50%;
  }
  .offset-tablet-7 {
    margin-left: 58.3333333333%;
  }
  .offset-tablet-8 {
    margin-left: 66.6666666667%;
  }
  .offset-tablet-9 {
    margin-left: 75%;
  }
  .offset-tablet-10 {
    margin-left: 83.3333333333%;
  }
  .offset-tablet-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 900px) {
  .col-laptop {
    flex: 1 0 0%;
  }
  .cols-laptop-auto > [class*=col] {
    flex: 0 0 auto;
    width: auto;
  }
  .cols-laptop-1 > [class*=col] {
    flex: 0 0 auto;
    width: 100%;
  }
  .cols-laptop-2 > [class*=col] {
    flex: 0 0 auto;
    width: 50%;
  }
  .cols-laptop-3 > [class*=col] {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .cols-laptop-4 > [class*=col] {
    flex: 0 0 auto;
    width: 25%;
  }
  .cols-laptop-5 > [class*=col] {
    flex: 0 0 auto;
    width: 20%;
  }
  .cols-laptop-6 > [class*=col] {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-laptop-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-laptop-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-laptop-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-laptop-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-laptop-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-laptop-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-laptop-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-laptop-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-laptop-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-laptop-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-laptop-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-laptop-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-laptop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-laptop-0 {
    margin-left: 0;
  }
  .offset-laptop-1 {
    margin-left: 8.3333333333%;
  }
  .offset-laptop-2 {
    margin-left: 16.6666666667%;
  }
  .offset-laptop-3 {
    margin-left: 25%;
  }
  .offset-laptop-4 {
    margin-left: 33.3333333333%;
  }
  .offset-laptop-5 {
    margin-left: 41.6666666667%;
  }
  .offset-laptop-6 {
    margin-left: 50%;
  }
  .offset-laptop-7 {
    margin-left: 58.3333333333%;
  }
  .offset-laptop-8 {
    margin-left: 66.6666666667%;
  }
  .offset-laptop-9 {
    margin-left: 75%;
  }
  .offset-laptop-10 {
    margin-left: 83.3333333333%;
  }
  .offset-laptop-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-desktop {
    flex: 1 0 0%;
  }
  .cols-desktop-auto > [class*=col] {
    flex: 0 0 auto;
    width: auto;
  }
  .cols-desktop-1 > [class*=col] {
    flex: 0 0 auto;
    width: 100%;
  }
  .cols-desktop-2 > [class*=col] {
    flex: 0 0 auto;
    width: 50%;
  }
  .cols-desktop-3 > [class*=col] {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .cols-desktop-4 > [class*=col] {
    flex: 0 0 auto;
    width: 25%;
  }
  .cols-desktop-5 > [class*=col] {
    flex: 0 0 auto;
    width: 20%;
  }
  .cols-desktop-6 > [class*=col] {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-desktop-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-desktop-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-desktop-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-desktop-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-desktop-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-desktop-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-desktop-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-desktop-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-desktop-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-desktop-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-desktop-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-desktop-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-desktop-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-desktop-0 {
    margin-left: 0;
  }
  .offset-desktop-1 {
    margin-left: 8.3333333333%;
  }
  .offset-desktop-2 {
    margin-left: 16.6666666667%;
  }
  .offset-desktop-3 {
    margin-left: 25%;
  }
  .offset-desktop-4 {
    margin-left: 33.3333333333%;
  }
  .offset-desktop-5 {
    margin-left: 41.6666666667%;
  }
  .offset-desktop-6 {
    margin-left: 50%;
  }
  .offset-desktop-7 {
    margin-left: 58.3333333333%;
  }
  .offset-desktop-8 {
    margin-left: 66.6666666667%;
  }
  .offset-desktop-9 {
    margin-left: 75%;
  }
  .offset-desktop-10 {
    margin-left: 83.3333333333%;
  }
  .offset-desktop-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1800px) {
  .col-tv {
    flex: 1 0 0%;
  }
  .cols-tv-auto > [class*=col] {
    flex: 0 0 auto;
    width: auto;
  }
  .cols-tv-1 > [class*=col] {
    flex: 0 0 auto;
    width: 100%;
  }
  .cols-tv-2 > [class*=col] {
    flex: 0 0 auto;
    width: 50%;
  }
  .cols-tv-3 > [class*=col] {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .cols-tv-4 > [class*=col] {
    flex: 0 0 auto;
    width: 25%;
  }
  .cols-tv-5 > [class*=col] {
    flex: 0 0 auto;
    width: 20%;
  }
  .cols-tv-6 > [class*=col] {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-tv-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-tv-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-tv-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-tv-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-tv-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-tv-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-tv-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-tv-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-tv-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-tv-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-tv-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-tv-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-tv-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-tv-0 {
    margin-left: 0;
  }
  .offset-tv-1 {
    margin-left: 8.3333333333%;
  }
  .offset-tv-2 {
    margin-left: 16.6666666667%;
  }
  .offset-tv-3 {
    margin-left: 25%;
  }
  .offset-tv-4 {
    margin-left: 33.3333333333%;
  }
  .offset-tv-5 {
    margin-left: 41.6666666667%;
  }
  .offset-tv-6 {
    margin-left: 50%;
  }
  .offset-tv-7 {
    margin-left: 58.3333333333%;
  }
  .offset-tv-8 {
    margin-left: 66.6666666667%;
  }
  .offset-tv-9 {
    margin-left: 75%;
  }
  .offset-tv-10 {
    margin-left: 83.3333333333%;
  }
  .offset-tv-11 {
    margin-left: 91.6666666667%;
  }
}
.g-none,
.gx-none {
  --gap-x: var(--none);
}

.g-none,
.gy-none {
  --gap-y: var(--none);
}

.g-tiny,
.gx-tiny {
  --gap-x: var(--tiny);
}

.g-tiny,
.gy-tiny {
  --gap-y: var(--tiny);
}

.g-small,
.gx-small {
  --gap-x: var(--small);
}

.g-small,
.gy-small {
  --gap-y: var(--small);
}

.g-medium,
.gx-medium {
  --gap-x: var(--medium);
}

.g-medium,
.gy-medium {
  --gap-y: var(--medium);
}

.g-large,
.gx-large {
  --gap-x: var(--large);
}

.g-large,
.gy-large {
  --gap-y: var(--large);
}

.g-huge,
.gx-huge {
  --gap-x: var(--huge);
}

.g-huge,
.gy-huge {
  --gap-y: var(--huge);
}

.g-gigantic,
.gx-gigantic {
  --gap-x: var(--gigantic);
}

.g-gigantic,
.gy-gigantic {
  --gap-y: var(--gigantic);
}

.g-huge2x,
.gx-huge2x {
  --gap-x: var(--huge2x);
}

.g-huge2x,
.gy-huge2x {
  --gap-y: var(--huge2x);
}

.g-gigantic2x,
.gx-gigantic2x {
  --gap-x: var(--gigantic2x);
}

.g-gigantic2x,
.gy-gigantic2x {
  --gap-y: var(--gigantic2x);
}

.g-gigantic4x,
.gx-gigantic4x {
  --gap-x: var(--gigantic4x);
}

.g-gigantic4x,
.gy-gigantic4x {
  --gap-y: var(--gigantic4x);
}

@media (min-width: 600px) {
  .g-tablet-none,
  .gx-tablet-none {
    --gap-x: var(--none);
  }
  .g-tablet-none,
  .gy-tablet-none {
    --gap-y: var(--none);
  }
  .g-tablet-tiny,
  .gx-tablet-tiny {
    --gap-x: var(--tiny);
  }
  .g-tablet-tiny,
  .gy-tablet-tiny {
    --gap-y: var(--tiny);
  }
  .g-tablet-small,
  .gx-tablet-small {
    --gap-x: var(--small);
  }
  .g-tablet-small,
  .gy-tablet-small {
    --gap-y: var(--small);
  }
  .g-tablet-medium,
  .gx-tablet-medium {
    --gap-x: var(--medium);
  }
  .g-tablet-medium,
  .gy-tablet-medium {
    --gap-y: var(--medium);
  }
  .g-tablet-large,
  .gx-tablet-large {
    --gap-x: var(--large);
  }
  .g-tablet-large,
  .gy-tablet-large {
    --gap-y: var(--large);
  }
  .g-tablet-huge,
  .gx-tablet-huge {
    --gap-x: var(--huge);
  }
  .g-tablet-huge,
  .gy-tablet-huge {
    --gap-y: var(--huge);
  }
  .g-tablet-gigantic,
  .gx-tablet-gigantic {
    --gap-x: var(--gigantic);
  }
  .g-tablet-gigantic,
  .gy-tablet-gigantic {
    --gap-y: var(--gigantic);
  }
  .g-tablet-huge2x,
  .gx-tablet-huge2x {
    --gap-x: var(--huge2x);
  }
  .g-tablet-huge2x,
  .gy-tablet-huge2x {
    --gap-y: var(--huge2x);
  }
  .g-tablet-gigantic2x,
  .gx-tablet-gigantic2x {
    --gap-x: var(--gigantic2x);
  }
  .g-tablet-gigantic2x,
  .gy-tablet-gigantic2x {
    --gap-y: var(--gigantic2x);
  }
  .g-tablet-gigantic4x,
  .gx-tablet-gigantic4x {
    --gap-x: var(--gigantic4x);
  }
  .g-tablet-gigantic4x,
  .gy-tablet-gigantic4x {
    --gap-y: var(--gigantic4x);
  }
}
@media (min-width: 900px) {
  .g-laptop-none,
  .gx-laptop-none {
    --gap-x: var(--none);
  }
  .g-laptop-none,
  .gy-laptop-none {
    --gap-y: var(--none);
  }
  .g-laptop-tiny,
  .gx-laptop-tiny {
    --gap-x: var(--tiny);
  }
  .g-laptop-tiny,
  .gy-laptop-tiny {
    --gap-y: var(--tiny);
  }
  .g-laptop-small,
  .gx-laptop-small {
    --gap-x: var(--small);
  }
  .g-laptop-small,
  .gy-laptop-small {
    --gap-y: var(--small);
  }
  .g-laptop-medium,
  .gx-laptop-medium {
    --gap-x: var(--medium);
  }
  .g-laptop-medium,
  .gy-laptop-medium {
    --gap-y: var(--medium);
  }
  .g-laptop-large,
  .gx-laptop-large {
    --gap-x: var(--large);
  }
  .g-laptop-large,
  .gy-laptop-large {
    --gap-y: var(--large);
  }
  .g-laptop-huge,
  .gx-laptop-huge {
    --gap-x: var(--huge);
  }
  .g-laptop-huge,
  .gy-laptop-huge {
    --gap-y: var(--huge);
  }
  .g-laptop-gigantic,
  .gx-laptop-gigantic {
    --gap-x: var(--gigantic);
  }
  .g-laptop-gigantic,
  .gy-laptop-gigantic {
    --gap-y: var(--gigantic);
  }
  .g-laptop-huge2x,
  .gx-laptop-huge2x {
    --gap-x: var(--huge2x);
  }
  .g-laptop-huge2x,
  .gy-laptop-huge2x {
    --gap-y: var(--huge2x);
  }
  .g-laptop-gigantic2x,
  .gx-laptop-gigantic2x {
    --gap-x: var(--gigantic2x);
  }
  .g-laptop-gigantic2x,
  .gy-laptop-gigantic2x {
    --gap-y: var(--gigantic2x);
  }
  .g-laptop-gigantic4x,
  .gx-laptop-gigantic4x {
    --gap-x: var(--gigantic4x);
  }
  .g-laptop-gigantic4x,
  .gy-laptop-gigantic4x {
    --gap-y: var(--gigantic4x);
  }
}
@media (min-width: 1200px) {
  .g-desktop-none,
  .gx-desktop-none {
    --gap-x: var(--none);
  }
  .g-desktop-none,
  .gy-desktop-none {
    --gap-y: var(--none);
  }
  .g-desktop-tiny,
  .gx-desktop-tiny {
    --gap-x: var(--tiny);
  }
  .g-desktop-tiny,
  .gy-desktop-tiny {
    --gap-y: var(--tiny);
  }
  .g-desktop-small,
  .gx-desktop-small {
    --gap-x: var(--small);
  }
  .g-desktop-small,
  .gy-desktop-small {
    --gap-y: var(--small);
  }
  .g-desktop-medium,
  .gx-desktop-medium {
    --gap-x: var(--medium);
  }
  .g-desktop-medium,
  .gy-desktop-medium {
    --gap-y: var(--medium);
  }
  .g-desktop-large,
  .gx-desktop-large {
    --gap-x: var(--large);
  }
  .g-desktop-large,
  .gy-desktop-large {
    --gap-y: var(--large);
  }
  .g-desktop-huge,
  .gx-desktop-huge {
    --gap-x: var(--huge);
  }
  .g-desktop-huge,
  .gy-desktop-huge {
    --gap-y: var(--huge);
  }
  .g-desktop-gigantic,
  .gx-desktop-gigantic {
    --gap-x: var(--gigantic);
  }
  .g-desktop-gigantic,
  .gy-desktop-gigantic {
    --gap-y: var(--gigantic);
  }
  .g-desktop-huge2x,
  .gx-desktop-huge2x {
    --gap-x: var(--huge2x);
  }
  .g-desktop-huge2x,
  .gy-desktop-huge2x {
    --gap-y: var(--huge2x);
  }
  .g-desktop-gigantic2x,
  .gx-desktop-gigantic2x {
    --gap-x: var(--gigantic2x);
  }
  .g-desktop-gigantic2x,
  .gy-desktop-gigantic2x {
    --gap-y: var(--gigantic2x);
  }
  .g-desktop-gigantic4x,
  .gx-desktop-gigantic4x {
    --gap-x: var(--gigantic4x);
  }
  .g-desktop-gigantic4x,
  .gy-desktop-gigantic4x {
    --gap-y: var(--gigantic4x);
  }
}
@media (min-width: 1800px) {
  .g-tv-none,
  .gx-tv-none {
    --gap-x: var(--none);
  }
  .g-tv-none,
  .gy-tv-none {
    --gap-y: var(--none);
  }
  .g-tv-tiny,
  .gx-tv-tiny {
    --gap-x: var(--tiny);
  }
  .g-tv-tiny,
  .gy-tv-tiny {
    --gap-y: var(--tiny);
  }
  .g-tv-small,
  .gx-tv-small {
    --gap-x: var(--small);
  }
  .g-tv-small,
  .gy-tv-small {
    --gap-y: var(--small);
  }
  .g-tv-medium,
  .gx-tv-medium {
    --gap-x: var(--medium);
  }
  .g-tv-medium,
  .gy-tv-medium {
    --gap-y: var(--medium);
  }
  .g-tv-large,
  .gx-tv-large {
    --gap-x: var(--large);
  }
  .g-tv-large,
  .gy-tv-large {
    --gap-y: var(--large);
  }
  .g-tv-huge,
  .gx-tv-huge {
    --gap-x: var(--huge);
  }
  .g-tv-huge,
  .gy-tv-huge {
    --gap-y: var(--huge);
  }
  .g-tv-gigantic,
  .gx-tv-gigantic {
    --gap-x: var(--gigantic);
  }
  .g-tv-gigantic,
  .gy-tv-gigantic {
    --gap-y: var(--gigantic);
  }
  .g-tv-huge2x,
  .gx-tv-huge2x {
    --gap-x: var(--huge2x);
  }
  .g-tv-huge2x,
  .gy-tv-huge2x {
    --gap-y: var(--huge2x);
  }
  .g-tv-gigantic2x,
  .gx-tv-gigantic2x {
    --gap-x: var(--gigantic2x);
  }
  .g-tv-gigantic2x,
  .gy-tv-gigantic2x {
    --gap-y: var(--gigantic2x);
  }
  .g-tv-gigantic4x,
  .gx-tv-gigantic4x {
    --gap-x: var(--gigantic4x);
  }
  .g-tv-gigantic4x,
  .gy-tv-gigantic4x {
    --gap-y: var(--gigantic4x);
  }
}
.divider {
  height: 2rem;
  border-left: 1px solid #e9e6ed;
}

.filterbar.row {
  --gap-x: var(--small);
  --gap-y: var(--small);
}
.filterbar .form-control {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.filterbar .form-select {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.filterbar .btn {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.filterbar .btn .icon {
  --size: 1rem;
}

.icon {
  --size: 1.25rem;
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--size, 1.25rem);
  height: var(--size, 1.25rem);
  line-height: 0;
  vertical-align: middle;
}
.icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.icon-sm {
  --size: 1rem;
}

.icon-md {
  --size: 1.5rem;
}

.icon-lg {
  --size: 2rem;
}

.icon-loader {
  --size: 1.25rem;
  display: inline-block;
  width: var(--size, 1.25rem);
  height: var(--size, 1.25rem);
  vertical-align: middle;
  border: 3px solid rgba(48, 18, 84, 0.08);
  border-top: 3px solid currentColor;
  border-radius: 50%;
  animation: spin 0.54s linear infinite;
}
.icon .icon-loader {
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: small;
}

.list-info {
  display: grid;
  grid-template-columns: minmax(100px, min-content) auto;
}
.list-info dt {
  margin-right: 1rem;
  color: #492e69;
  font-weight: 400;
}
.list-info dd {
  color: #6e5987;
}
.list-info dt,
.list-info dd {
  margin-bottom: 1rem;
}
.list-info dt:last-of-type,
.list-info dd:last-of-type {
  margin-bottom: 0;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: inherit;
  vertical-align: middle;
  border-color: #e9e6ed;
}
.table > :not(caption) > * > * {
  padding: var(--medium, 0.75rem) var(--large, 1rem);
  background-color: var(--color-transparent, transparent);
  border-bottom-width: 1px;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > thead > * > * {
  color: #6e5987;
  font-weight: 600;
  font-size: font-scale(caption);
  vertical-align: bottom;
  background-color: #f9f8fa;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #e9e6ed;
}
.table tr.disabled td {
  background-color: #e9e6ed;
}

.caption-top {
  caption-side: top;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 599.98px) {
  .table-responsive-tablet {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 899.98px) {
  .table-responsive-laptop {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-desktop {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1799.98px) {
  .table-responsive-tv {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.table-sm > :not(caption) > * > * {
  padding: var(--small, 0.5rem) var(--large, 1rem);
}

.table-subtle > :not(caption) > * > * {
  background-color: #f9f8fa;
}

.table-col-actions {
  width: 1rem;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.accordion-header {
  outline: none;
}

.accordion-close {
  transform: rotate(-90deg);
}

.alert {
  --margin-bottom: var(--large);
  --padding-y: var(--large);
  --padding-x: var(--large);
  --color: var(--neutral-end);
  --bg: var(--neutral-light);
  --border-color: var(--neutral-light);
  --border-radius: 8px;
  position: relative;
  margin-bottom: var(--margin-bottom, 1rem);
  padding: var(--padding-y, 1rem) var(--padding-x, 1rem);
  color: var(--color, #301254);
  background: var(--bg, #e9e6ed);
  border-color: var(--border-color, #e9e6ed);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 8px);
}
.alert a:not(.btn) {
  color: inherit;
  text-decoration: underline;
}
.alert a:not(.btn):hover {
  text-decoration: none;
}
.alert .btn-close {
  --padding-y: var(--large);
  --padding-x: var(--large);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: calc(var(--padding-y, 1rem) * 1.25) var(--padding-x, 1rem);
}
.alert > .row {
  --gap-x: var(--large);
}

.alert-actions {
  margin-top: var(--large, 1rem);
}
.alert-actions .btn {
  --color: var(--neutral-end);
  --bg: var(--neutral-lightest);
  --border-color: var(--neutral-lightest);
  --box-shadow: none;
  --hover-border-color: var(--neutral-lightest);
  --hover-box-shadow: none;
  --active-border-color: var(--neutral-lightest);
  --active-box-shadow: none;
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.alert-info {
  --color: var(--neutral-start);
  --bg: var(--info-base);
  --border-color: var(--info-base);
}
.alert-info .alert-actions .btn {
  --color: var(--neutral-start);
  --bg: var(--info-dark);
  --border-color: var(--info-dark);
}

.alert-success {
  --color: var(--neutral-start);
  --bg: var(--success-base);
  --border-color: var(--success-base);
}
.alert-success .alert-actions .btn {
  --color: var(--neutral-start);
  --bg: var(--success-dark);
  --border-color: var(--success-dark);
}

.alert-warning {
  --color: var(--neutral-end);
  --bg: var(--warning-base);
  --border-color: var(--warning-base);
}
.alert-warning .alert-actions .btn {
  --color: var(--neutral-end);
  --bg: var(--warning-dark);
  --border-color: var(--warning-dark);
}

.alert-danger {
  --color: var(--neutral-start);
  --bg: var(--danger-base);
  --border-color: var(--danger-base);
}
.alert-danger .alert-actions .btn {
  --color: var(--neutral-start);
  --bg: var(--danger-dark);
  --border-color: var(--danger-dark);
}

.alert-dismissible {
  padding-right: 3rem;
}

.avatar {
  --size: 2rem;
  --color: var(--neutral-start);
  --bg: var(--tertiary-base);
  --font-scale: 0.4375;
  --border-radius: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size, 2rem);
  height: var(--size, 2rem);
  overflow: hidden;
  color: var(--color, #fff);
  font-size: calc(var(--size) * var(--font-scale));
  vertical-align: middle;
  background: var(--bg, #ffc878);
  border-radius: var(--border-radius, 50%);
}
.avatar img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar[data-initial]::before {
  z-index: 1;
  color: currentColor;
  content: attr(data-initial);
}

.avatar-sm {
  --size: 1.25rem;
}

.avatar-md {
  --size: 2.5rem;
}

.avatar-lg {
  --size: 3rem;
}

.avatar-company {
  --size: 2.5rem;
  --bg: var(--primary-base);
  --font-scale: 0.65;
  --border-radius: 8px;
}
.avatar-company.avatar-md {
  --size: 3rem;
}
.avatar-company.avatar-lg {
  --size: 4rem;
}

.badge {
  --padding-y: var(--tiny);
  --padding-x: var(--small);
  --color: var(--neutral-lightest);
  --bg: var(--neutral-dark);
  --border-radius: calc(var(--padding-y) * 2 + 1 * 1rem);
  display: inline-block;
  padding: var(--padding-y, 0.25rem) var(--padding-x, 0.5rem);
  color: var(--color, #f9f8fa);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  background: var(--bg, #6e5987);
  border-radius: var(--border-radius, calc(var(--padding-y) * 2 + 1 * 1rem));
}
.badge:empty {
  display: none;
}

.badge-primary {
  --color: var(--primary-lightest);
  --bg: var(--primary-dark);
}

.breadcrumb {
  --padding-y: var(--none);
  --padding-x: var(--none);
  --margin-bottom: var(--large);
  --bg: var(--color-transparent);
  --border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--margin-bottom, 1rem);
  padding: var(--padding-y, 0) var(--padding-x, 0);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  list-style: none;
  background: var(--bg, transparent);
  border-radius: var(--border-radius, 0);
}

.breadcrumb-item {
  --padding-x: var(--small);
  --active-color: var(--neutral-dark);
  --divider-color: var(--neutral-dark);
  display: flex;
  align-items: center;
  font-weight: 600;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--padding-x, 0.5rem);
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: var(--padding-x, 0.5rem);
  color: var(--divider-color, #6e5987);
  content: "/";
}
.breadcrumb-item.active {
  color: var(--active-color, #6e5987);
  font-weight: 400;
}
.breadcrumb-item .icon:first-child {
  margin-right: var(--small);
}

.btn {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-end);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 2rem;
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --transform: scale(1);
  --hover-color: var(--neutral-end);
  --hover-bg: var(--neutral-lightest);
  --hover-border-color: var(--neutral-base);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-end);
  --active-bg: var(--neutral-lightest);
  --active-border-color: var(--neutral-base);
  --active-box-shadow: 0 0 0 transparent;
  --active-transform: scale(0.96);
  --selected-color: var(--primary-base);
  --selected-bg: var(--primary-lightest);
  --selected-border-color: var(--primary-base);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, #301254);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background: var(--bg, #fff);
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 2rem);
  box-shadow: var(--box-shadow, 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02));
  transform: var(--transform, scale(1));
  cursor: pointer;
  user-select: none;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  --color: var(--hover-color);
  --bg: var(--hover-bg);
  --border-color: var(--hover-border-color);
  --box-shadow: var(--hover-box-shadow);
  text-decoration: none;
}
.btn:active {
  --color: var(--active-color);
  --bg: var(--active-bg);
  --border-color: var(--active-border-color);
  --box-shadow: var(--active-box-shadow);
  --transform: var(--active-transform);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn.selected, .btn.show {
  --color: var(--selected-color);
  --bg: var(--selected-bg);
  --border-color: var(--selected-border-color);
  --box-shadow: var(--selected-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  --color: var(--disabled-color);
  --bg: var(--disabled-bg);
  --border-color: var(--disabled-border-color);
  --box-shadow: var(--disabled-box-shadow);
  opacity: 1;
  pointer-events: none;
}
.btn .icon {
  margin-right: var(--small);
}
.btn.btn-square {
  --padding-x: var(--padding-y);
}
.btn.btn-square .icon {
  margin-right: 0;
}
.btn[class*=btn-b2b] {
  font-weight: 600;
}
.btn[class*=btn-b2c] {
  font-weight: 600;
}

.btn-primary {
  --color: var(--neutral-start);
  --bg: var(--primary-base);
  --border-color: var(--primary-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-start);
  --hover-bg: var(--primary-dark);
  --hover-border-color: var(--primary-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-start);
  --active-bg: var(--primary-dark);
  --active-border-color: var(--primary-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--primary-base);
  --selected-bg: var(--primary-lightest);
  --selected-border-color: var(--primary-base);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-subtle {
  --color: var(--neutral-dark);
  --bg: var(--color-transparent);
  --border-color: var(--color-transparent);
  --box-shadow: none;
  --hover-color: var(--neutral-end);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--color-transparent);
  --hover-box-shadow: none;
  --active-color: var(--neutral-end);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--color-transparent);
  --active-box-shadow: none;
  --selected-color: var(--primary-dark);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--color-transparent);
  --selected-box-shadow: none;
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--color-transparent);
  --disabled-border-color: var(--color-transparent);
  --disabled-box-shadow: none;
}

.btn-success {
  --color: var(--neutral-start);
  --bg: var(--success-base);
  --border-color: var(--success-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-start);
  --hover-bg: var(--success-dark);
  --hover-border-color: var(--success-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-start);
  --active-bg: var(--success-dark);
  --active-border-color: var(--success-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--success-dark);
  --selected-bg: var(--success-light);
  --selected-border-color: var(--success-base);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-warning {
  --color: var(--neutral-end);
  --bg: var(--warning-base);
  --border-color: var(--warning-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-end);
  --hover-bg: var(--warning-dark);
  --hover-border-color: var(--warning-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-end);
  --active-bg: var(--warning-dark);
  --active-border-color: var(--warning-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-end);
  --selected-bg: var(--warning-light);
  --selected-border-color: var(--warning-base);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-danger {
  --color: var(--neutral-start);
  --bg: var(--danger-base);
  --border-color: var(--danger-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-start);
  --hover-bg: var(--danger-dark);
  --hover-border-color: var(--danger-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-start);
  --active-bg: var(--danger-dark);
  --active-border-color: var(--danger-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--danger-dark);
  --selected-bg: var(--danger-light);
  --selected-border-color: var(--danger-base);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2c {
  --color: var(--primary-base);
  --bg: var(--color-transparent);
  --border-color: var(--primary-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--primary-dark);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--primary-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--primary-dark);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--primary-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--primary-dark);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2c-inverted {
  --color: var(--secondary-light);
  --bg: var(--color-transparent);
  --border-color: var(--secondary-light);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--secondary-base);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--secondary-base);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--secondary-base);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--secondary-base);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--secondary-base);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2c-primary {
  --color: var(--neutral-start);
  --bg: var(--primary-base);
  --border-color: var(--primary-base);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-start);
  --hover-bg: var(--primary-dark);
  --hover-border-color: var(--primary-dark);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-start);
  --active-bg: var(--primary-dark);
  --active-border-color: var(--primary-dark);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-start);
  --selected-bg: var(--primary-dark);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2c-primary-inverted {
  --color: var(--primary-base);
  --bg: var(--secondary-light);
  --border-color: var(--secondary-light);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--primary-base);
  --hover-bg: var(--secondary-base);
  --hover-border-color: var(--secondary-base);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--primary-base);
  --active-bg: var(--secondary-base);
  --active-border-color: var(--secondary-base);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--primary-base);
  --selected-bg: var(--secondary-base);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2b {
  --color: var(--neutral-darkest);
  --bg: var(--color-transparent);
  --border-color: var(--neutral-darkest);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-end);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--neutral-end);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-end);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--neutral-end);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-end);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2b-inverted {
  --color: var(--neutral-start);
  --bg: var(--color-transparent);
  --border-color: var(--neutral-start);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-lightest);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--neutral-lightest);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-lightest);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--neutral-lightest);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-lightest);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2b-primary {
  --color: var(--neutral-start);
  --bg: var(--neutral-end);
  --border-color: var(--neutral-end);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-start);
  --hover-bg: var(--neutral-darkest);
  --hover-border-color: var(--neutral-darkest);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-start);
  --active-bg: var(--neutral-darkest);
  --active-border-color: var(--neutral-darkest);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-start);
  --selected-bg: var(--neutral-darkest);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-b2b-primary-inverted {
  --color: var(--neutral-end);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-start);
  --box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --hover-color: var(--neutral-end);
  --hover-bg: var(--neutral-lightest);
  --hover-border-color: var(--neutral-lightest);
  --hover-box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --active-color: var(--neutral-end);
  --active-bg: var(--neutral-lightest);
  --active-border-color: var(--neutral-lightest);
  --active-box-shadow: 0 0 0 transparent;
  --selected-color: var(--neutral-end);
  --selected-bg: var(--neutral-lightest);
  --selected-border-color: var(--);
  --selected-box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--neutral-light);
  --disabled-border-color: var(--neutral-light);
  --disabled-box-shadow: 0 0 0 transparent;
}

.btn-link {
  --color: var(--primary-base);
  --bg: var(--color-transparent);
  --border-color: var(--color-transparent);
  --hover-color: var(--primary-dark);
  --hover-bg: var(--color-transparent);
  --hover-border-color: var(--color-transparent);
  --active-color: var(--hover-color);
  --active-bg: var(--color-transparent);
  --active-border-color: var(--color-transparent);
  --selected-color: var(--active-color);
  --selected-bg: var(--color-transparent);
  --selected-border-color: var(--color-transparent);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--color-transparent);
  --disabled-border-color: var(--color-transparent);
  text-decoration: none;
  box-shadow: none !important;
}
.btn-sm {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.btn-sm .icon {
  --size: 1rem;
}

.btn-lg {
  --padding-y: var(--large);
  --padding-x: var(--large);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.btn-lg .icon {
  --size: 1.5rem;
}

.btn-block {
  display: flex;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.selected,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.selected {
  z-index: 1;
}

.btn-group > .btn {
  margin-left: -1px;
  border-radius: 0;
}
.btn-group > .btn:first-of-type {
  margin-left: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.btn-group > .btn:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:first-child) > .btn:first-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn:last-of-type {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.btn-group-sm > .btn {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.btn-group-sm > .btn .icon {
  --size: 1rem;
}

.btn-group-lg > .btn {
  --padding-y: var(--large);
  --padding-x: var(--large);
  --border-radius: 2rem;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.btn-group-lg > .btn .icon {
  --size: 1.5rem;
}

.dropdown-toggle-split {
  padding-right: calc(var(--large) * 0.75);
  padding-left: calc(var(--large) * 0.75);
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split {
  padding-right: calc(var(--medium) * 0.75);
  padding-left: calc(var(--medium) * 0.75);
}

.btn-lg + .dropdown-toggle-split {
  padding-right: calc(var(--large) * 0.75);
  padding-left: calc(var(--large) * 0.75);
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 0 transparent;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card {
  --padding-y: var(--huge);
  --padding-x: var(--huge);
  --color: var(--);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-darkest);
  --border-radius: 8px;
  --box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
  --header-color: var(--);
  --header-bg: var(--);
  --subtitle-margin-top: var(--tiny);
  --subtitle-color: var(--neutral-dark);
  --footer-color: var(--);
  --footer-bg: var(--);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  color: var(--color, );
  word-wrap: break-word;
  background: var(--bg, #fff);
  border-color: var(--border-color, #492e69);
  border-style: solid;
  border-width: 0;
  border-radius: var(--border-radius, 8px);
  box-shadow: var(--box-shadow, 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02));
}
.card > :first-child {
  border-radius: calc(var(--border-radius) - 0) calc(var(--border-radius) - 0) 0 0;
}
.card > :first-child:last-child {
  border-radius: calc(var(--border-radius) - 0);
}

.card-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-y, 1.5rem) var(--padding-x, 1.5rem);
  color: var(--header-color, );
  background: var(--header-bg, );
}

.card-actions {
  display: flex;
  margin-top: calc(var(--padding-y) / 2 * -1);
  margin-right: calc(var(--padding-x) / 2 * -1);
}
.card-actions .dropdown-toggle::after {
  display: none;
}

.card-title {
  margin-bottom: 0;
  --font-size: 1.375rem;
  --line-height: 1.2727272727;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.card-subtitle {
  display: block;
  margin-bottom: 0;
  color: var(--subtitle-color, #6e5987);
}
.card-title + .card-subtitle {
  margin-top: var(--subtitle-margin-top, 0.25rem);
}

.card-body {
  flex: 1 1 auto;
  padding: var(--padding-y, 1.5rem) var(--padding-x, 1.5rem);
}
.card-header + .card-body {
  padding-top: 0;
}
.card-body > *:last-child {
  margin-bottom: var(--none);
}

.card-footer {
  padding: calc(var(--padding-y) / 2) var(--padding-x, 1.5rem);
  color: var(--footer-color, );
  background: var(--footer-bg, );
}
.card-footer:last-child {
  border-radius: 0 0 calc(var(--border-radius) - 0) calc(var(--border-radius) - 0);
}
.card-footer:last-child:first-child {
  border-radius: calc(var(--border-radius) - 0);
}

.card-subtle {
  --color: var(--);
  --bg: var(--neutral-lightest);
  --border-color: var(--neutral-lightest);
  --box-shadow: none;
  --header-color: var(--);
  --header-bg: var(--);
  --footer-color: var(--);
  --footer-bg: var(--);
}

.card-placeholder {
  --color: var(--);
  --bg: var(--neutral-light);
  --border-color: var(--neutral-light);
  --box-shadow: inset 0 0 1px #6e5987;
  --header-color: var(--);
  --header-bg: var(--);
  --footer-color: var(--);
  --footer-bg: var(--);
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-control {
  white-space: nowrap;
}
.dropdown-control::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.272rem;
  content: "";
  border-top: 0.32rem solid;
  border-right: 0.32rem solid transparent;
  border-bottom: 0;
  border-left: 0.32rem solid transparent;
}
.dropdown-control:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --margin-y: var(--none);
  --padding-y: var(--tiny);
  --color: var(--);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 8rem;
  max-width: 20rem;
  margin: var(--margin-y, 0) 0 0;
  padding: var(--padding-y, 0.25rem) 0;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color, );
  text-align: left;
  list-style: none;
  background: var(--bg, #fff);
  background-clip: padding-box;
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 8px);
  box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 8px 16px rgba(48, 18, 84, 0.08), 0 8px 24px rgba(48, 18, 84, 0.08);
}
.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.dropdown-menu.show {
  display: block;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 600px) {
  .dropdown-menu-tablet-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-tablet-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 900px) {
  .dropdown-menu-laptop-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-laptop-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-desktop-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-desktop-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1800px) {
  .dropdown-menu-tv-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-tv-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--margin-y, 0);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.272rem;
  content: "";
  border-top: 0;
  border-right: 0.32rem solid transparent;
  border-bottom: 0.32rem solid;
  border-left: 0.32rem solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--margin-y, 0);
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.272rem;
  content: "";
  border-top: 0.32rem solid transparent;
  border-right: 0;
  border-bottom: 0.32rem solid transparent;
  border-left: 0.32rem solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--margin-y, 0);
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: 0.272rem;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: 0.272rem;
  content: "";
  border-top: 0.32rem solid transparent;
  border-right: 0.32rem solid;
  border-bottom: 0.32rem solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  --margin-y: var(--tiny);
  --bg: var(--neutral-light);
  display: block;
  height: 0;
  margin: var(--margin-y, 0.25rem) 0;
  overflow: hidden;
  border-color: var(--bg, #e9e6ed);
  border-style: solid;
  border-width: 1px 0 0 0;
}

.dropdown-item {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-end);
  --hover-color: var(--neutral-end);
  --hover-bg: var(--neutral-lightest);
  --selected-color: var(--primary-base);
  --selected-bg: var(--primary-lightest);
  --disabled-color: var(--neutral-base);
  --disabled-bg: var(--color-transparent);
  display: flex;
  align-items: center;
  clear: both;
  width: 100%;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, #301254);
  font-weight: 400;
  white-space: nowrap;
  text-align: inherit;
  background: var(--color-transparent);
  border: 0;
}
.dropdown-item:hover {
  color: var(--hover-color, #301254);
  text-decoration: none;
  background: var(--hover-bg, #f9f8fa);
}
.dropdown-item.selected {
  color: var(--selected-color, #501e96);
  text-decoration: none;
  background: var(--selected-bg, #faf7ff);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--disabled-color, #ada5b6);
  background: var(--disabled-bg, transparent);
  background-image: none;
  pointer-events: none;
}
.dropdown-item > .dropdown-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-item > .icon:first-child {
  margin-right: var(--medium);
}
.dropdown-item > .icon-check {
  color: var(--neutral-base);
}
.dropdown-item > .form-check {
  width: 100%;
}

.dropdown-header {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-dark);
  display: block;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, #6e5987);
}

.dropdown-title {
  --margin-bottom: var(--none);
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-dark);
  display: block;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem) calc(var(--padding-y, 0.75rem) / 2);
  overflow: hidden;
  color: var(--color, #6e5987);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.empty-state {
  padding: 4rem 0;
}

.empty-state-figure {
  margin-bottom: 1.5rem;
  text-align: center;
}
.empty-state-figure img {
  width: 100%;
  max-width: 25rem;
  height: auto;
}

.empty-state-body {
  text-align: center;
}

.empty-state-action {
  margin-top: 1.5rem;
}

.flow {
  margin-bottom: 0;
  padding: 2rem 0;
  list-style: none;
}
.flow > *:first-child::before {
  content: none;
}

.flow-item {
  position: relative;
  max-width: 30rem;
  margin: 0 auto;
}
.flow-item::before {
  position: relative;
  left: 1.875rem;
  display: block;
  width: 0.25rem;
  height: 2rem;
  background-color: #ada5b6;
  content: "";
}

.flow-item-is-dragging::before {
  visibility: hidden;
}

.flow-action {
  cursor: grab;
}
.flow-action::before, .flow-action::after {
  position: absolute;
  left: 1.5rem;
  z-index: 2;
  width: 1rem;
  height: 1rem;
  background-color: #ada5b6;
  border: solid 3px #fff;
  border-radius: 50%;
  content: "";
}
.flow-action::before {
  top: -0.5rem;
}
.flow-item:first-of-type .flow-action::before {
  content: none;
}
.flow-action::after {
  bottom: -0.5rem;
}
.flow-item:last-of-type .flow-action::after {
  content: none;
}

.flow-action-is-dragging {
  cursor: grabbing;
}
.flow-action-is-dragging::before, .flow-action-is-dragging::after {
  visibility: hidden;
}

.flow-item-placeholder .flow-action {
  background-color: rgba(173, 165, 182, 0.25);
}
.flow-item-placeholder .flow-action::before, .flow-item-placeholder .flow-action::after {
  background-color: #fff;
  border-color: #ada5b6;
}

.form-control {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-end);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 4px;
  --box-shadow: 0 0 0 transparent;
  --focus-color: var(--neutral-end);
  --focus-bg: var(--neutral-start);
  --focus-border-color: var(--primary-base);
  --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
  --disabled-color: var(--neutral-end);
  --disabled-bg: var(--neutral-lightest);
  --disabled-border-color: var(--neutral-lightest);
  --placeholder-color: var(--neutral-base);
  --file-button-color: var(--neutral-end);
  --file-button-bg: var(--neutral-lightest);
  --file-button-hover-bg: var(--neutral-light);
  display: block;
  width: 100%;
  min-height: calc(var(--padding-y) * 2 + 1.5rem + 1px * 2);
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, #301254);
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 400;
  background: var(--bg, #fff);
  background-clip: padding-box;
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 4px);
  box-shadow: inset var(--box-shadow, 0 0 0 transparent);
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
  appearance: none;
  caret-color: var(--primary-base);
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5rem;
}
.form-control::placeholder {
  --color: var(--placeholder-color);
  opacity: 1;
}
.form-control:focus {
  --color: var(--focus-color);
  --bg: var(--focus-bg);
  --border-color: var(--focus-border-color);
  --box-shadow: var(--focus-box-shadow);
}
.form-control:disabled, .form-control.disabled {
  --color: var(--disabled-color);
  --bg: var(--disabled-bg);
  --border-color: var(--disabled-border-color);
  --box-shadow: none;
  opacity: 1;
}
.form-control[type=file]::file-selector-button {
  --color: var(--file-button-color);
  --bg: var(--file-button-bg);
  margin: calc(var(--padding-y, 0.75rem) / 2 * -1) calc(var(--padding-x, 1rem) / 2 * -1);
  padding: calc(var(--padding-y, 0.75rem) / 2) calc(var(--padding-x, 1rem) / 2);
  margin-inline-end: calc(var(--padding-x, 1rem) / 2);
  color: var(--color, #301254);
  background: var(--bg, #f9f8fa);
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  border-radius: var(--border-radius, 4px);
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
  pointer-events: none;
}
.form-control[type=file]:hover:not(:disabled):not([readonly])::file-selector-button, .form-control[type=file]:hover:not(.disabled):not([readonly])::file-selector-button {
  --bg: var(--file-button-hover-bg);
}
.form-control[type=file]::-webkit-file-upload-button {
  --color: var(--file-button-color);
  --bg: var(--file-button-bg);
  margin: calc(var(--padding-y) / 2 * -1) calc(var(--padding-x) / 2 * -1);
  padding: calc(var(--padding-y) / 2) calc(var(--padding-x) / 2);
  margin-inline-end: calc(var(--padding-x, 1rem) / 2);
  color: var(--color, #301254);
  background: var(--bg, #f9f8fa);
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-width: 0;
  border-radius: 0;
  border-radius: var(--border-radius, 4px);
  pointer-events: none;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .form-control[type=file]::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control[type=file]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .form-control[type=file]:hover:not(.disabled):not([readonly])::-webkit-file-upload-button {
  --bg: var(--file-button-hover-bg);
}

.form-control-sm {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.form-control-lg {
  --padding-y: var(--large);
  --padding-x: var(--large);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.form-control-color[type=color] {
  --focus-box-shadow: 0 0 0 1px var(--neutral-end);
  max-width: calc(var(--padding-y) * 2 + 1.5rem + 1px * 2);
  height: calc(var(--padding-y) * 2 + 1.5rem + 1px * 2);
  padding: 0;
  border-width: 0;
}
.form-control-color[type=color]:not(:disabled):not([readonly]), .form-control-color[type=color]:not(.disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color[type=color]::-moz-color-swatch {
  height: auto;
  border: none;
  border-radius: var(--border-radius, 4px);
}
.form-control-color[type=color]::-webkit-color-swatch {
  height: auto;
  border: none;
  border-radius: var(--border-radius, 4px);
}

::-webkit-datetime-edit-ampm-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-week-field,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-text {
  color: #301254;
  -webkit-text-fill-color: #301254;
  -webkit-opacity: 1;
}

.form-check {
  --size-x: 1.5rem;
  --size-y: 1.5rem;
  --padding-left: calc(var(--size-x, 1.5rem) + var(--medium));
  --color: var(--);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 4px;
  --box-shadow: 0 0 0 transparent;
  --focus-border-color: var(--);
  --focus-box-shadow: var(--);
  --checked-bg: var(--primary-base);
  --checked-border-color: var(--primary-base);
  --indeterminate-bg: var(--primary-base);
  --indeterminate-border-color: var(--primary-base);
  display: block;
  padding-left: var(--padding-left, 2rem);
}
.form-check + .form-check {
  margin-top: var(--medium, 0.75rem);
}
.form-check .form-check-input {
  float: left;
  margin-left: calc(var(--padding-left, 2rem) * -1);
}

.form-check-input {
  width: var(--size-x, 1.5rem);
  height: var(--size-y, 1.5rem);
  vertical-align: top;
  background-color: var(--bg, #fff);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  box-shadow: var(--box-shadow, 0 0 0 transparent);
  cursor: pointer;
  transition: color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease, background-position 0.25s ease;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: var(--border-radius, 4px);
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:focus {
  --border-color: var(--focus-border-color);
  --box-shadow: var(--focus-box-shadow);
}
.form-check-input:checked {
  --bg: var(--checked-bg);
  --border-color: var(--checked-border-color);
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23fff' d='M11.167 16.167a.833.833 0 01-.59-.245L6.655 12l1.178-1.178 3.334 3.333 6.666-6.667 1.179 1.179-7.256 7.255a.833.833 0 01-.59.245z'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  --bg: var(--indeterminate-bg);
  --border-color: var(--indeterminate-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input[disabled], .form-check-input:disabled, .form-check-input.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label, .form-check-input.disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  --color: var(--);
  display: block;
  color: var(--color, );
  cursor: pointer;
}

.form-toggle {
  --size-x: 2.5rem;
  --border-radius: 2.5rem;
}
.form-toggle .form-check-input {
  margin-left: calc(var(--padding-left) * -1);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ada5b6'/%3e%3c/svg%3e");
  background-position: left center;
  background-size: calc(var(--size-y) - 1px * 2);
}
.form-toggle .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ada5b6'/%3e%3c/svg%3e");
}
.form-toggle .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: right center;
}

.form-check-inline {
  --margin-right: var(--large);
  display: inline-block;
  margin-right: var(--margin-right, 1rem);
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn, .btn-check.disabled + .btn {
  opacity: 0.5;
  filter: none;
  pointer-events: none;
}

.form-check-selection {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 4px;
  --box-shadow: 0 0 0 transparent;
  --focus-border-color: var(--primary-base);
  --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
  --checked-bg: var(--primary-lightest);
  --checked-border-color: var(--primary-base);
  --indicator-size: 1.5rem;
  --indicator-bg: var(--neutral-light);
  --indicator-border-radius: 4px;
  --indicator-checked-bg: var(--primary-base);
  position: relative;
  display: block;
}
.form-check-selection + .form-check-selection {
  margin-top: var(--medium, 0.75rem);
}
.form-check-selection .alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}

.form-check-selection-input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-check-selection-label {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, );
  background: var(--bg, #fff);
  background-clip: padding-box;
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 4px);
  box-shadow: var(--box-shadow, 0 0 0 transparent);
  cursor: pointer;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
}
.form-check-selection-input:focus + .form-check-selection-label {
  --border-color: var(--focus-border-color);
  --box-shadow: var(--focus-box-shadow);
}
.form-check-selection-input:checked + .form-check-selection-label {
  --bg: var(--checked-bg);
  --border-color: var(--checked-border-color);
}
.form-check-selection-input:checked + .form-check-selection-label .form-check-selection-hidden {
  display: block;
}
.form-check-selection-input:disabled + .form-check-selection-label, .form-check-selection-input[disabled] + .form-check-selection-label {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-selection-label .form-check-selection-hidden {
  display: none;
  width: 100%;
  margin: var(--padding-y, 0.75rem) 0;
}

.form-check-selection-indicator {
  flex-shrink: 0;
  width: var(--indicator-size, 1.5rem);
  height: var(--indicator-size, 1.5rem);
  margin-right: var(--padding-y, 1rem);
  background-color: var(--indicator-bg, #e9e6ed);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
}
.form-check-selection-input[type=checkbox] ~ .form-check-selection-label .form-check-selection-indicator {
  border-radius: var(--indicator-border-radius, 4px);
}
.form-check-selection-input[type=radio] ~ .form-check-selection-label .form-check-selection-indicator {
  border-radius: 50%;
}
.form-check-selection-input:checked ~ .form-check-selection-label .form-check-selection-indicator {
  --indicator-bg: var(--indicator-checked-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23fff' d='M11.167 16.167a.833.833 0 01-.59-.245L6.655 12l1.178-1.178 3.334 3.333 6.666-6.667 1.179 1.179-7.256 7.255a.833.833 0 01-.59.245z'/%3e%3c/svg%3e");
}

.form-check-selection-body {
  flex: 1 1 0;
}

.form-check-selection-title,
.form-check-selection-subtitle {
  display: block;
}

.form-check-selection-title {
  margin-bottom: var(--none);
}

.form-check-selection-input:checked ~ .alert {
  opacity: 100%;
}

.form-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  background-color: var(--color-transparent);
  appearance: none;
}
.form-range:focus {
  outline: none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: primary-base;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: primary-base;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: var(--color-transparent);
  background-color: #ada5b6;
  border-color: var(--color-transparent);
  cursor: pointer;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: primary-base;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: primary-base;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  background-color: #ada5b6;
  border-color: transparent;
  cursor: pointer;
  border-radius: 1rem;
}
.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 1px;
  margin-left: 1px;
  background-color: primary-base;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}
.form-range::-ms-thumb:active {
  background-color: primary-base;
}
.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  cursor: pointer;
}
.form-range::-ms-fill-lower {
  background-color: #ada5b6;
  border-radius: 1rem;
}
.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #ada5b6;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #6e5987;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #6e5987;
}
.form-range:disabled::-ms-thumb {
  background-color: #6e5987;
}

.form-label {
  --color: var(--neutral-end);
  margin-bottom: var(--small, 0.5rem);
  color: var(--color, #301254);
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 600;
}

.form-select {
  --padding-y: var(--medium);
  --padding-x: var(--large);
  --color: var(--neutral-end);
  --bg: var(--neutral-start);
  --border-color: var(--neutral-base);
  --border-radius: 4px;
  --box-shadow: 0 0 0 transparent;
  --focus-color: var(--neutral-end);
  --focus-bg: var(--neutral-start);
  --focus-border-color: var(--primary-base);
  --focus-box-shadow: 0 0 0 1px var(--focus-border-color);
  --disabled-color: var(--neutral-end);
  --disabled-bg: var(--neutral-lightest);
  --disabled-border-color: var(--neutral-lightest);
  display: block;
  width: 100%;
  padding: var(--padding-y, 0.75rem) var(--padding-x, 1rem);
  color: var(--color, #301254);
  font-weight: 400;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  background-color: var(--bg, #fff);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpolygon fill='%236e5987' points='5 8 10 13 15 8'%3e%3c/polygon%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
  border-color: var(--border-color, #ada5b6);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border-radius, 4px);
  box-shadow: var(--box-shadow, 0 0 0 transparent);
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
  appearance: none;
}
.form-select:focus {
  --color: var(--focus-color);
  --bg: var(--focus-bg);
  --border-color: var(--focus-border-color);
  --box-shadow: var(--focus-box-shadow);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: var(--padding-y);
  background-image: none;
}
.form-select:disabled, .form-select.disabled {
  --color: var(--disabled-color);
  --bg: var(--disabled-bg);
  --border-color: var(--disabled-border-color);
  --box-shadow: none;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 neutral-end;
}

.form-select-sm {
  --padding-y: var(--small);
  --padding-x: var(--medium);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.form-select-lg {
  --padding-y: var(--large);
  --padding-x: var(--large);
  --border-radius: 4px;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.form-text {
  margin-top: 0.5rem;
  color: #ada5b6;
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: var(--small, 0.5rem);
  color: var(--success-base, #1daf57);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.was-validated :valid ~ .valid-feedback,
.is-valid ~ .valid-feedback {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  --border-color: var(--success-base);
  --focus-border-color: var(--success-base);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  --border-color: var(--success-base);
  --checked-bg: var(--success-base);
  --checked-border-color: var(--checked-bg);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: var(--small, 0.5rem);
  color: var(--danger-base, #ea3e35);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.was-validated :invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-feedback {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .form-select:invalid,
.form-select.is-invalid {
  --border-color: var(--danger-base);
  --focus-border-color: var(--danger-base);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  --border-color: var(--danger-base);
  --checked-bg: var(--danger-base);
  --checked-border-color: var(--checked-bg);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}
.input-group > .form-file > .form-file-input:focus {
  z-index: 4;
}
.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: medium large;
  color: neutral-end;
  font-weight: 400;
  line-height: 1.5rem;
  white-space: nowrap;
  text-align: center;
  background-color: #f9f8fa;
  border: 1px solid #f9f8fa;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  border-radius: 4px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: large large;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  border-radius: 4px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: small medium;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  border-radius: 4px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: large1.25rem;
}

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-header {
  margin-bottom: var(--gigantic);
}

.form-title {
  margin-bottom: var(--small);
  --font-size: 1.1875rem;
  --line-height: 1.2631578947;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.lozenge {
  --size: 1rem;
  --color: var(--neutral-dark);
  --bg: var(--neutral-dark);
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--color, #6e5987);
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.lozenge::before {
  display: block;
  width: var(--size, 1rem);
  height: var(--size, 1rem);
  margin-right: var(--small, 0.5rem);
  background: var(--bg, #6e5987);
  content: "";
}

.lozenge-info {
  --color: var(--info-base);
  --bg: var(--info-base);
}

.lozenge-success {
  --color: var(--success-base);
  --bg: var(--success-base);
}

.lozenge-warning {
  --color: var(--warning-dark);
  --bg: var(--warning-base);
}

.lozenge-danger {
  --color: var(--danger-base);
  --bg: var(--danger-base);
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #f9f8fa;
  outline: 0;
  pointer-events: auto;
  box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #ada5b6;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.6;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #f9f8fa;
}
.modal-header .close {
  margin: -1.5rem -2rem -1.5rem auto;
  padding: 1.5rem 2rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.2;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 1px solid #f9f8fa;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-dialog {
  max-width: 600px;
  margin: 1.75rem auto;
}

.modal-dialog-scrollable {
  height: calc(100% - 3.5rem);
}

.modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}

.modal-content {
  box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
}

.modal-sm {
  max-width: 300px;
}

.modal-lg,
.modal-xl {
  max-width: 900px;
}

.modal-xl {
  max-width: 1240px;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 599.98px) {
  .modal-fullscreen-tablet-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-tablet-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-tablet-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-tablet-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-tablet-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 899.98px) {
  .modal-fullscreen-laptop-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-laptop-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-laptop-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-laptop-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-laptop-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-desktop-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-desktop-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-desktop-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-desktop-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-desktop-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1799.98px) {
  .modal-fullscreen-tv-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-tv-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-tv-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-tv-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-tv-down .modal-footer {
    border-radius: 0;
  }
}
.modal-title {
  --font-size: 1.375rem;
  --line-height: 1.2727272727;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.modal-subtitle {
  color: #6e5987;
}
.modal-title + .modal-subtitle {
  margin-top: 0.5rem;
}

.modal-header + .modal-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.modal-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.modal-footer-subtle {
  background-color: #e9e6ed;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav-link {
  --padding-y: var(--small);
  --padding-x: var(--large);
  --color: var(--neutral-dark);
  --hover-color: var(--neutral-end);
  --selected-color: var(--primary-base);
  --disabled-color: var(--neutral-base);
  --icon-margin-x: var(--small);
  display: flex;
  align-items: center;
  padding: var(--padding-y, 0.5rem) var(--padding-x, 1rem);
  color: var(--color, #6e5987);
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 600;
  cursor: pointer;
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
}
.nav-link:hover, .nav-link:focus {
  --color: var(--hover-color);
}
.nav-link.selected {
  --color: var(--selected-color);
}
.nav-link.disabled {
  --color: var(--disabled-color);
  cursor: not-allowed;
  pointer-events: none;
}
.nav-link > .icon {
  margin-right: var(--icon-margin-x, 0.5rem);
}
.nav-link-square {
  --padding-x: var(--small);
}
.nav-link-square > .icon {
  --icon-margin-x: var(--none);
}

.nav-tabs {
  --border-color: var(--neutral-lightest);
  --selected-color: var(--primary-base);
  --selected-border-color: var(--primary-base);
  border-color: var(--border-color, #f9f8fa);
  border-style: solid;
  border-width: 0 0 2px;
}
.nav-tabs .nav-link {
  --border-color: var(--color-transparent);
  margin-bottom: -2px;
  border-color: var(--border-color);
  border-style: solid;
  border-width: 0 0 2px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  --border-color: var(--neutral-end);
}
.nav-tabs .nav-link.disabled {
  --border-color: var(--color-transparent);
}
.nav-tabs .nav-link.selected, .show .nav-tabs .nav-link {
  --border-color: var(--selected-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: -2px;
}

.nav-pills .nav-link {
  --border-radius: 8px;
  --selected-color: var(--primary-base);
  --selected-bg: var(--primary-lightest);
  border-radius: var(--border-radius, 8px);
}
.nav-pills .nav-link.selected,
.nav-pills .nav-link .show > .nav-link {
  background: var(--selected-bg, #faf7ff);
}

.nav-segmented .nav-link {
  flex-direction: column;
  align-items: start;
  padding: 1rem 1.5rem;
  color: #301254;
}
.nav-segmented .nav-link small, .nav-segmented .nav-link .small {
  margin-bottom: 0.25rem;
  color: #ada5b6;
}
.nav-segmented .nav-link.selected {
  color: white;
  background-color: #501e96;
}
.nav-segmented .nav-link.selected small, .nav-segmented .nav-link.selected .small {
  color: inherit;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
}
.nav-fill .nav-link {
  justify-content: center;
  text-align: center;
}

.nav-justified {
  align-items: flex-end;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
}
.nav-justified .nav-link {
  justify-content: center;
  text-align: center;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-tablet,
.navbar > .container-laptop,
.navbar > .container-desktop,
.navbar > .container-tv {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: small;
  padding-bottom: small;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: medium medium;
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2rem;
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 1px;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 600px) {
  .navbar-expand-tablet {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-tablet .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-tablet .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-tablet .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-tablet .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-tablet .navbar-toggler {
    display: none;
  }
}
@media (min-width: 900px) {
  .navbar-expand-laptop {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-laptop .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-laptop .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-laptop .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-laptop .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-laptop .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-desktop {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-desktop .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-desktop .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-desktop .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-desktop .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-desktop .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1800px) {
  .navbar-expand-tv {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-tv .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-tv .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-tv .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-tv .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-tv .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #6e5987;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #501e96;
}
.navbar-light .navbar-nav .nav-link {
  color: #6e5987;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(110, 89, 135, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(110, 89, 135, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #501e96;
}
.navbar-light .navbar-toggler {
  color: #6e5987;
  border-color: rgba(110, 89, 135, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%236e5987' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #6e5987;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #501e96;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.85);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.85%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.85);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar {
  min-height: 4rem;
  padding: 0 1rem;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e9e6ed;
}

.navbar-primary,
.navbar-secondary {
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .navbar-primary,
  .navbar-secondary {
    flex: 1 1 30%;
  }
}

.navbar-primary .btn-subtle.btn-square:first-of-type {
  margin-left: -medium;
}

.navbar-secondary .nav {
  flex-wrap: nowrap;
  align-items: center;
}
.navbar-secondary .nav-link {
  padding: small;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-secondary {
    justify-content: flex-end;
    order: 6;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-title {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.navbar-nav .active > .nav-link,
.navbar-nav .nav-link.active {
  font-weight: 700;
}

.navbar-toggler,
.navbar-toggler:focus {
  border-width: 0;
  box-shadow: none;
}

.navbar-light .nav-secondary .nav-link {
  color: #6e5987;
}

.navbar-dark .navbar-secondary .nav-link {
  color: rgba(255, 255, 255, 0.85);
}

.panel {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
}
.panel.disabled {
  background-color: #e9e6ed;
  box-shadow: none !important;
}

.panel-header,
.panel-body,
.panel-footer {
  padding: 1.5rem 2rem;
}

.panel-header,
.panel-footer {
  flex: 0 0 auto;
}

.panel-body {
  flex: 1 1 auto;
  overflow-y: auto;
}
.panel-header + .panel-body {
  padding-top: 0;
}

.panel-footer {
  border-top: 1px solid #e9e6ed;
}

.panel-title {
  margin-bottom: 0;
  --font-size: 1.375rem;
  --line-height: 1.2727272727;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.progress {
  --height: 0.5rem;
  --bg: var(--success-base);
  --border-radius: 8px;
  --bar-color: var(--neutral-start);
  --bar-bg: var(--success-base);
  display: flex;
  height: var(--height, 0.5rem);
  overflow: hidden;
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
  line-height: 1.3334;
  background: var(--bg, #1daf57);
  border-radius: var(--border-radius, 8px);
  box-shadow: var(--box-shadow, );
}

.progress-lg {
  --height: 1.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bar-color, #fff);
  white-space: nowrap;
  text-align: center;
  background: var(--bar-bg, #1daf57);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.toolbar {
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(173, 165, 182, 0.5), 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02);
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 4px 12px rgba(48, 18, 84, 0.02) !important;
}

.shadow-sm {
  box-shadow: 0 1px 3px rgba(48, 18, 84, 0.06), 0 2px 6px rgba(48, 18, 84, 0.02) !important;
}

.shadow-lg {
  box-shadow: 0 2px 4px rgba(48, 18, 84, 0.04), 0 4px 8px rgba(48, 18, 84, 0.04), 0 8px 16px rgba(48, 18, 84, 0.08), 0 8px 24px rgba(48, 18, 84, 0.08) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e9e6ed !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e9e6ed !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #e9e6ed !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e9e6ed !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #e9e6ed !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-neutral-start {
  border-color: #fff !important;
}

.border-neutral-lightest {
  border-color: #f9f8fa !important;
}

.border-neutral-light {
  border-color: #e9e6ed !important;
}

.border-neutral {
  border-color: #ada5b6 !important;
}

.border-neutral-dark {
  border-color: #6e5987 !important;
}

.border-neutral-darkest {
  border-color: #492e69 !important;
}

.border-neutral-end {
  border-color: #301254 !important;
}

.border-primary-lightest {
  border-color: #faf7ff !important;
}

.border-primary-light {
  border-color: #7878fa !important;
}

.border-primary {
  border-color: #501e96 !important;
}

.border-primary-dark {
  border-color: #301254 !important;
}

.border-secondary-lightest {
  border-color: #ebfaf8 !important;
}

.border-secondary-light {
  border-color: #a0e1dc !important;
}

.border-secondary {
  border-color: #28d7dc !important;
}

.border-secondary-dark {
  border-color: #008c96 !important;
}

.border-tertiary-light {
  border-color: #ffddab !important;
}

.border-tertiary {
  border-color: #ffc878 !important;
}

.border-tertiary-dark {
  border-color: #fa8c50 !important;
}

.border-success-light {
  border-color: #c6f1d7 !important;
}

.border-success {
  border-color: #1daf57 !important;
}

.border-success-dark {
  border-color: #148541 !important;
}

.border-info-light {
  border-color: #d0e8fb !important;
}

.border-info {
  border-color: #116cd6 !important;
}

.border-info-dark {
  border-color: #0c53a7 !important;
}

.border-warning-light {
  border-color: #feecc3 !important;
}

.border-warning {
  border-color: #ed9424 !important;
}

.border-warning-dark {
  border-color: #d0790b !important;
}

.border-danger-light {
  border-color: #fededc !important;
}

.border-danger {
  border-color: #ea3e35 !important;
}

.border-danger-dark {
  border-color: #c0241b !important;
}

.border-subtle {
  border-color: #6e5987 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-body-color {
  border-color: var(--neutral-end) !important;
}

.border-body-bg {
  border-color: var(--neutral-start) !important;
}

.border-muted {
  border-color: #ada5b6 !important;
}

.border-reset {
  border-color: inherit !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-none {
  gap: 0 !important;
}

.gap-tiny {
  gap: 0.25rem !important;
}

.gap-small {
  gap: 0.5rem !important;
}

.gap-medium {
  gap: 0.75rem !important;
}

.gap-large {
  gap: 1rem !important;
}

.gap-huge {
  gap: 1.5rem !important;
}

.gap-gigantic {
  gap: 2rem !important;
}

.gap-huge2x {
  gap: 3rem !important;
}

.gap-gigantic2x {
  gap: 4rem !important;
}

.gap-gigantic4x {
  gap: 8rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-none {
  margin: 0 !important;
}

.m-tiny {
  margin: 0.25rem !important;
}

.m-small {
  margin: 0.5rem !important;
}

.m-medium {
  margin: 0.75rem !important;
}

.m-large {
  margin: 1rem !important;
}

.m-huge {
  margin: 1.5rem !important;
}

.m-gigantic {
  margin: 2rem !important;
}

.m-huge2x {
  margin: 3rem !important;
}

.m-gigantic2x {
  margin: 4rem !important;
}

.m-gigantic4x {
  margin: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-none {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-tiny {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-small {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-medium {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-large {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-huge {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-gigantic {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-huge2x {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-gigantic2x {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-gigantic4x {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-tiny {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-small {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-medium {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-large {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-huge {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-gigantic {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-huge2x {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-gigantic2x {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-gigantic4x {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-none {
  margin-top: 0 !important;
}

.mt-tiny {
  margin-top: 0.25rem !important;
}

.mt-small {
  margin-top: 0.5rem !important;
}

.mt-medium {
  margin-top: 0.75rem !important;
}

.mt-large {
  margin-top: 1rem !important;
}

.mt-huge {
  margin-top: 1.5rem !important;
}

.mt-gigantic {
  margin-top: 2rem !important;
}

.mt-huge2x {
  margin-top: 3rem !important;
}

.mt-gigantic2x {
  margin-top: 4rem !important;
}

.mt-gigantic4x {
  margin-top: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-none {
  margin-right: 0 !important;
}

.mr-tiny {
  margin-right: 0.25rem !important;
}

.mr-small {
  margin-right: 0.5rem !important;
}

.mr-medium {
  margin-right: 0.75rem !important;
}

.mr-large {
  margin-right: 1rem !important;
}

.mr-huge {
  margin-right: 1.5rem !important;
}

.mr-gigantic {
  margin-right: 2rem !important;
}

.mr-huge2x {
  margin-right: 3rem !important;
}

.mr-gigantic2x {
  margin-right: 4rem !important;
}

.mr-gigantic4x {
  margin-right: 8rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-none {
  margin-bottom: 0 !important;
}

.mb-tiny {
  margin-bottom: 0.25rem !important;
}

.mb-small {
  margin-bottom: 0.5rem !important;
}

.mb-medium {
  margin-bottom: 0.75rem !important;
}

.mb-large {
  margin-bottom: 1rem !important;
}

.mb-huge {
  margin-bottom: 1.5rem !important;
}

.mb-gigantic {
  margin-bottom: 2rem !important;
}

.mb-huge2x {
  margin-bottom: 3rem !important;
}

.mb-gigantic2x {
  margin-bottom: 4rem !important;
}

.mb-gigantic4x {
  margin-bottom: 8rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-none {
  margin-left: 0 !important;
}

.ml-tiny {
  margin-left: 0.25rem !important;
}

.ml-small {
  margin-left: 0.5rem !important;
}

.ml-medium {
  margin-left: 0.75rem !important;
}

.ml-large {
  margin-left: 1rem !important;
}

.ml-huge {
  margin-left: 1.5rem !important;
}

.ml-gigantic {
  margin-left: 2rem !important;
}

.ml-huge2x {
  margin-left: 3rem !important;
}

.ml-gigantic2x {
  margin-left: 4rem !important;
}

.ml-gigantic4x {
  margin-left: 8rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-none {
  padding: 0 !important;
}

.p-tiny {
  padding: 0.25rem !important;
}

.p-small {
  padding: 0.5rem !important;
}

.p-medium {
  padding: 0.75rem !important;
}

.p-large {
  padding: 1rem !important;
}

.p-huge {
  padding: 1.5rem !important;
}

.p-gigantic {
  padding: 2rem !important;
}

.p-huge2x {
  padding: 3rem !important;
}

.p-gigantic2x {
  padding: 4rem !important;
}

.p-gigantic4x {
  padding: 8rem !important;
}

.px-none {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-tiny {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-small {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-medium {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-large {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-huge {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-gigantic {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-huge2x {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-gigantic2x {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-gigantic4x {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.py-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-tiny {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-small {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-medium {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-large {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-huge {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-gigantic {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-huge2x {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-gigantic2x {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-gigantic4x {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-none {
  padding-top: 0 !important;
}

.pt-tiny {
  padding-top: 0.25rem !important;
}

.pt-small {
  padding-top: 0.5rem !important;
}

.pt-medium {
  padding-top: 0.75rem !important;
}

.pt-large {
  padding-top: 1rem !important;
}

.pt-huge {
  padding-top: 1.5rem !important;
}

.pt-gigantic {
  padding-top: 2rem !important;
}

.pt-huge2x {
  padding-top: 3rem !important;
}

.pt-gigantic2x {
  padding-top: 4rem !important;
}

.pt-gigantic4x {
  padding-top: 8rem !important;
}

.pr-none {
  padding-right: 0 !important;
}

.pr-tiny {
  padding-right: 0.25rem !important;
}

.pr-small {
  padding-right: 0.5rem !important;
}

.pr-medium {
  padding-right: 0.75rem !important;
}

.pr-large {
  padding-right: 1rem !important;
}

.pr-huge {
  padding-right: 1.5rem !important;
}

.pr-gigantic {
  padding-right: 2rem !important;
}

.pr-huge2x {
  padding-right: 3rem !important;
}

.pr-gigantic2x {
  padding-right: 4rem !important;
}

.pr-gigantic4x {
  padding-right: 8rem !important;
}

.pb-none {
  padding-bottom: 0 !important;
}

.pb-tiny {
  padding-bottom: 0.25rem !important;
}

.pb-small {
  padding-bottom: 0.5rem !important;
}

.pb-medium {
  padding-bottom: 0.75rem !important;
}

.pb-large {
  padding-bottom: 1rem !important;
}

.pb-huge {
  padding-bottom: 1.5rem !important;
}

.pb-gigantic {
  padding-bottom: 2rem !important;
}

.pb-huge2x {
  padding-bottom: 3rem !important;
}

.pb-gigantic2x {
  padding-bottom: 4rem !important;
}

.pb-gigantic4x {
  padding-bottom: 8rem !important;
}

.pl-none {
  padding-left: 0 !important;
}

.pl-tiny {
  padding-left: 0.25rem !important;
}

.pl-small {
  padding-left: 0.5rem !important;
}

.pl-medium {
  padding-left: 0.75rem !important;
}

.pl-large {
  padding-left: 1rem !important;
}

.pl-huge {
  padding-left: 1.5rem !important;
}

.pl-gigantic {
  padding-left: 2rem !important;
}

.pl-huge2x {
  padding-left: 3rem !important;
}

.pl-gigantic2x {
  padding-left: 4rem !important;
}

.pl-gigantic4x {
  padding-left: 8rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.3334 !important;
}

.lh-base {
  line-height: 1.43 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.color-neutral-start {
  color: #fff !important;
}

.color-neutral-lightest {
  color: #f9f8fa !important;
}

.color-neutral-light {
  color: #e9e6ed !important;
}

.color-neutral {
  color: #ada5b6 !important;
}

.color-neutral-dark {
  color: #6e5987 !important;
}

.color-neutral-darkest {
  color: #492e69 !important;
}

.color-neutral-end {
  color: #301254 !important;
}

.color-primary-lightest {
  color: #faf7ff !important;
}

.color-primary-light {
  color: #7878fa !important;
}

.color-primary {
  color: #501e96 !important;
}

.color-primary-dark {
  color: #301254 !important;
}

.color-secondary-lightest {
  color: #ebfaf8 !important;
}

.color-secondary-light {
  color: #a0e1dc !important;
}

.color-secondary {
  color: #28d7dc !important;
}

.color-secondary-dark {
  color: #008c96 !important;
}

.color-tertiary-light {
  color: #ffddab !important;
}

.color-tertiary {
  color: #ffc878 !important;
}

.color-tertiary-dark {
  color: #fa8c50 !important;
}

.color-success-light {
  color: #c6f1d7 !important;
}

.color-success {
  color: #1daf57 !important;
}

.color-success-dark {
  color: #148541 !important;
}

.color-info-light {
  color: #d0e8fb !important;
}

.color-info {
  color: #116cd6 !important;
}

.color-info-dark {
  color: #0c53a7 !important;
}

.color-warning-light {
  color: #feecc3 !important;
}

.color-warning {
  color: #ed9424 !important;
}

.color-warning-dark {
  color: #d0790b !important;
}

.color-danger-light {
  color: #fededc !important;
}

.color-danger {
  color: #ea3e35 !important;
}

.color-danger-dark {
  color: #c0241b !important;
}

.color-subtle {
  color: #6e5987 !important;
}

.color-white {
  color: #fff !important;
}

.color-transparent {
  color: transparent !important;
}

.color-body-color {
  color: var(--neutral-end) !important;
}

.color-body-bg {
  color: var(--neutral-start) !important;
}

.color-muted {
  color: #ada5b6 !important;
}

.color-reset {
  color: inherit !important;
}

.bg-neutral-start {
  background-color: #fff !important;
}

.bg-neutral-lightest {
  background-color: #f9f8fa !important;
}

.bg-neutral-light {
  background-color: #e9e6ed !important;
}

.bg-neutral {
  background-color: #ada5b6 !important;
}

.bg-neutral-dark {
  background-color: #6e5987 !important;
}

.bg-neutral-darkest {
  background-color: #492e69 !important;
}

.bg-neutral-end {
  background-color: #301254 !important;
}

.bg-primary-lightest {
  background-color: #faf7ff !important;
}

.bg-primary-light {
  background-color: #7878fa !important;
}

.bg-primary {
  background-color: #501e96 !important;
}

.bg-primary-dark {
  background-color: #301254 !important;
}

.bg-secondary-lightest {
  background-color: #ebfaf8 !important;
}

.bg-secondary-light {
  background-color: #a0e1dc !important;
}

.bg-secondary {
  background-color: #28d7dc !important;
}

.bg-secondary-dark {
  background-color: #008c96 !important;
}

.bg-tertiary-light {
  background-color: #ffddab !important;
}

.bg-tertiary {
  background-color: #ffc878 !important;
}

.bg-tertiary-dark {
  background-color: #fa8c50 !important;
}

.bg-success-light {
  background-color: #c6f1d7 !important;
}

.bg-success {
  background-color: #1daf57 !important;
}

.bg-success-dark {
  background-color: #148541 !important;
}

.bg-info-light {
  background-color: #d0e8fb !important;
}

.bg-info {
  background-color: #116cd6 !important;
}

.bg-info-dark {
  background-color: #0c53a7 !important;
}

.bg-warning-light {
  background-color: #feecc3 !important;
}

.bg-warning {
  background-color: #ed9424 !important;
}

.bg-warning-dark {
  background-color: #d0790b !important;
}

.bg-danger-light {
  background-color: #fededc !important;
}

.bg-danger {
  background-color: #ea3e35 !important;
}

.bg-danger-dark {
  background-color: #c0241b !important;
}

.bg-subtle {
  background-color: #f9f8fa !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-body-color {
  background-color: var(--neutral-end) !important;
}

.bg-body-bg {
  background-color: var(--neutral-start) !important;
}

.bg-muted {
  background-color: #ada5b6 !important;
}

.bg-reset {
  background-color: inherit !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-gradient {
  background-image: var(gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 8px !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 4px !important;
}

.rounded-2 {
  border-radius: 8px !important;
}

.rounded-3 {
  border-radius: 16px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-top {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.rounded-end {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.rounded-start {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 600px) {
  .float-tablet-start {
    float: left !important;
  }
  .float-tablet-end {
    float: right !important;
  }
  .float-tablet-none {
    float: none !important;
  }
  .d-tablet-inline {
    display: inline !important;
  }
  .d-tablet-inline-block {
    display: inline-block !important;
  }
  .d-tablet-block {
    display: block !important;
  }
  .d-tablet-grid {
    display: grid !important;
  }
  .d-tablet-table {
    display: table !important;
  }
  .d-tablet-table-row {
    display: table-row !important;
  }
  .d-tablet-table-cell {
    display: table-cell !important;
  }
  .d-tablet-flex {
    display: flex !important;
  }
  .d-tablet-inline-flex {
    display: inline-flex !important;
  }
  .d-tablet-none {
    display: none !important;
  }
  .flex-tablet-fill {
    flex: 1 1 auto !important;
  }
  .flex-tablet-row {
    flex-direction: row !important;
  }
  .flex-tablet-column {
    flex-direction: column !important;
  }
  .flex-tablet-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-tablet-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-tablet-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-tablet-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-tablet-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-tablet-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-tablet-wrap {
    flex-wrap: wrap !important;
  }
  .flex-tablet-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-tablet-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-tablet-none {
    gap: 0 !important;
  }
  .gap-tablet-tiny {
    gap: 0.25rem !important;
  }
  .gap-tablet-small {
    gap: 0.5rem !important;
  }
  .gap-tablet-medium {
    gap: 0.75rem !important;
  }
  .gap-tablet-large {
    gap: 1rem !important;
  }
  .gap-tablet-huge {
    gap: 1.5rem !important;
  }
  .gap-tablet-gigantic {
    gap: 2rem !important;
  }
  .gap-tablet-huge2x {
    gap: 3rem !important;
  }
  .gap-tablet-gigantic2x {
    gap: 4rem !important;
  }
  .gap-tablet-gigantic4x {
    gap: 8rem !important;
  }
  .justify-content-tablet-start {
    justify-content: flex-start !important;
  }
  .justify-content-tablet-end {
    justify-content: flex-end !important;
  }
  .justify-content-tablet-center {
    justify-content: center !important;
  }
  .justify-content-tablet-between {
    justify-content: space-between !important;
  }
  .justify-content-tablet-around {
    justify-content: space-around !important;
  }
  .justify-content-tablet-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-tablet-start {
    align-items: flex-start !important;
  }
  .align-items-tablet-end {
    align-items: flex-end !important;
  }
  .align-items-tablet-center {
    align-items: center !important;
  }
  .align-items-tablet-baseline {
    align-items: baseline !important;
  }
  .align-items-tablet-stretch {
    align-items: stretch !important;
  }
  .align-content-tablet-start {
    align-content: flex-start !important;
  }
  .align-content-tablet-end {
    align-content: flex-end !important;
  }
  .align-content-tablet-center {
    align-content: center !important;
  }
  .align-content-tablet-between {
    align-content: space-between !important;
  }
  .align-content-tablet-around {
    align-content: space-around !important;
  }
  .align-content-tablet-stretch {
    align-content: stretch !important;
  }
  .align-self-tablet-auto {
    align-self: auto !important;
  }
  .align-self-tablet-start {
    align-self: flex-start !important;
  }
  .align-self-tablet-end {
    align-self: flex-end !important;
  }
  .align-self-tablet-center {
    align-self: center !important;
  }
  .align-self-tablet-baseline {
    align-self: baseline !important;
  }
  .align-self-tablet-stretch {
    align-self: stretch !important;
  }
  .order-tablet-first {
    order: -1 !important;
  }
  .order-tablet-0 {
    order: 0 !important;
  }
  .order-tablet-1 {
    order: 1 !important;
  }
  .order-tablet-2 {
    order: 2 !important;
  }
  .order-tablet-3 {
    order: 3 !important;
  }
  .order-tablet-4 {
    order: 4 !important;
  }
  .order-tablet-5 {
    order: 5 !important;
  }
  .order-tablet-last {
    order: 6 !important;
  }
  .m-tablet-none {
    margin: 0 !important;
  }
  .m-tablet-tiny {
    margin: 0.25rem !important;
  }
  .m-tablet-small {
    margin: 0.5rem !important;
  }
  .m-tablet-medium {
    margin: 0.75rem !important;
  }
  .m-tablet-large {
    margin: 1rem !important;
  }
  .m-tablet-huge {
    margin: 1.5rem !important;
  }
  .m-tablet-gigantic {
    margin: 2rem !important;
  }
  .m-tablet-huge2x {
    margin: 3rem !important;
  }
  .m-tablet-gigantic2x {
    margin: 4rem !important;
  }
  .m-tablet-gigantic4x {
    margin: 8rem !important;
  }
  .m-tablet-auto {
    margin: auto !important;
  }
  .mx-tablet-none {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-tablet-tiny {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-tablet-small {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-tablet-medium {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-tablet-large {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-tablet-huge {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-tablet-gigantic {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-tablet-huge2x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-tablet-gigantic2x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-tablet-gigantic4x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-tablet-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-tablet-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-tablet-tiny {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-tablet-small {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-tablet-medium {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-tablet-large {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-tablet-huge {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-tablet-gigantic {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-tablet-huge2x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-tablet-gigantic2x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-tablet-gigantic4x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-tablet-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-tablet-none {
    margin-top: 0 !important;
  }
  .mt-tablet-tiny {
    margin-top: 0.25rem !important;
  }
  .mt-tablet-small {
    margin-top: 0.5rem !important;
  }
  .mt-tablet-medium {
    margin-top: 0.75rem !important;
  }
  .mt-tablet-large {
    margin-top: 1rem !important;
  }
  .mt-tablet-huge {
    margin-top: 1.5rem !important;
  }
  .mt-tablet-gigantic {
    margin-top: 2rem !important;
  }
  .mt-tablet-huge2x {
    margin-top: 3rem !important;
  }
  .mt-tablet-gigantic2x {
    margin-top: 4rem !important;
  }
  .mt-tablet-gigantic4x {
    margin-top: 8rem !important;
  }
  .mt-tablet-auto {
    margin-top: auto !important;
  }
  .mr-tablet-none {
    margin-right: 0 !important;
  }
  .mr-tablet-tiny {
    margin-right: 0.25rem !important;
  }
  .mr-tablet-small {
    margin-right: 0.5rem !important;
  }
  .mr-tablet-medium {
    margin-right: 0.75rem !important;
  }
  .mr-tablet-large {
    margin-right: 1rem !important;
  }
  .mr-tablet-huge {
    margin-right: 1.5rem !important;
  }
  .mr-tablet-gigantic {
    margin-right: 2rem !important;
  }
  .mr-tablet-huge2x {
    margin-right: 3rem !important;
  }
  .mr-tablet-gigantic2x {
    margin-right: 4rem !important;
  }
  .mr-tablet-gigantic4x {
    margin-right: 8rem !important;
  }
  .mr-tablet-auto {
    margin-right: auto !important;
  }
  .mb-tablet-none {
    margin-bottom: 0 !important;
  }
  .mb-tablet-tiny {
    margin-bottom: 0.25rem !important;
  }
  .mb-tablet-small {
    margin-bottom: 0.5rem !important;
  }
  .mb-tablet-medium {
    margin-bottom: 0.75rem !important;
  }
  .mb-tablet-large {
    margin-bottom: 1rem !important;
  }
  .mb-tablet-huge {
    margin-bottom: 1.5rem !important;
  }
  .mb-tablet-gigantic {
    margin-bottom: 2rem !important;
  }
  .mb-tablet-huge2x {
    margin-bottom: 3rem !important;
  }
  .mb-tablet-gigantic2x {
    margin-bottom: 4rem !important;
  }
  .mb-tablet-gigantic4x {
    margin-bottom: 8rem !important;
  }
  .mb-tablet-auto {
    margin-bottom: auto !important;
  }
  .ml-tablet-none {
    margin-left: 0 !important;
  }
  .ml-tablet-tiny {
    margin-left: 0.25rem !important;
  }
  .ml-tablet-small {
    margin-left: 0.5rem !important;
  }
  .ml-tablet-medium {
    margin-left: 0.75rem !important;
  }
  .ml-tablet-large {
    margin-left: 1rem !important;
  }
  .ml-tablet-huge {
    margin-left: 1.5rem !important;
  }
  .ml-tablet-gigantic {
    margin-left: 2rem !important;
  }
  .ml-tablet-huge2x {
    margin-left: 3rem !important;
  }
  .ml-tablet-gigantic2x {
    margin-left: 4rem !important;
  }
  .ml-tablet-gigantic4x {
    margin-left: 8rem !important;
  }
  .ml-tablet-auto {
    margin-left: auto !important;
  }
  .p-tablet-none {
    padding: 0 !important;
  }
  .p-tablet-tiny {
    padding: 0.25rem !important;
  }
  .p-tablet-small {
    padding: 0.5rem !important;
  }
  .p-tablet-medium {
    padding: 0.75rem !important;
  }
  .p-tablet-large {
    padding: 1rem !important;
  }
  .p-tablet-huge {
    padding: 1.5rem !important;
  }
  .p-tablet-gigantic {
    padding: 2rem !important;
  }
  .p-tablet-huge2x {
    padding: 3rem !important;
  }
  .p-tablet-gigantic2x {
    padding: 4rem !important;
  }
  .p-tablet-gigantic4x {
    padding: 8rem !important;
  }
  .px-tablet-none {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-tablet-tiny {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-tablet-small {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-tablet-medium {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-tablet-large {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-tablet-huge {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-tablet-gigantic {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-tablet-huge2x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-tablet-gigantic2x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-tablet-gigantic4x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-tablet-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-tablet-tiny {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-tablet-small {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-tablet-medium {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-tablet-large {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-tablet-huge {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-tablet-gigantic {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-tablet-huge2x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-tablet-gigantic2x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-tablet-gigantic4x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-tablet-none {
    padding-top: 0 !important;
  }
  .pt-tablet-tiny {
    padding-top: 0.25rem !important;
  }
  .pt-tablet-small {
    padding-top: 0.5rem !important;
  }
  .pt-tablet-medium {
    padding-top: 0.75rem !important;
  }
  .pt-tablet-large {
    padding-top: 1rem !important;
  }
  .pt-tablet-huge {
    padding-top: 1.5rem !important;
  }
  .pt-tablet-gigantic {
    padding-top: 2rem !important;
  }
  .pt-tablet-huge2x {
    padding-top: 3rem !important;
  }
  .pt-tablet-gigantic2x {
    padding-top: 4rem !important;
  }
  .pt-tablet-gigantic4x {
    padding-top: 8rem !important;
  }
  .pr-tablet-none {
    padding-right: 0 !important;
  }
  .pr-tablet-tiny {
    padding-right: 0.25rem !important;
  }
  .pr-tablet-small {
    padding-right: 0.5rem !important;
  }
  .pr-tablet-medium {
    padding-right: 0.75rem !important;
  }
  .pr-tablet-large {
    padding-right: 1rem !important;
  }
  .pr-tablet-huge {
    padding-right: 1.5rem !important;
  }
  .pr-tablet-gigantic {
    padding-right: 2rem !important;
  }
  .pr-tablet-huge2x {
    padding-right: 3rem !important;
  }
  .pr-tablet-gigantic2x {
    padding-right: 4rem !important;
  }
  .pr-tablet-gigantic4x {
    padding-right: 8rem !important;
  }
  .pb-tablet-none {
    padding-bottom: 0 !important;
  }
  .pb-tablet-tiny {
    padding-bottom: 0.25rem !important;
  }
  .pb-tablet-small {
    padding-bottom: 0.5rem !important;
  }
  .pb-tablet-medium {
    padding-bottom: 0.75rem !important;
  }
  .pb-tablet-large {
    padding-bottom: 1rem !important;
  }
  .pb-tablet-huge {
    padding-bottom: 1.5rem !important;
  }
  .pb-tablet-gigantic {
    padding-bottom: 2rem !important;
  }
  .pb-tablet-huge2x {
    padding-bottom: 3rem !important;
  }
  .pb-tablet-gigantic2x {
    padding-bottom: 4rem !important;
  }
  .pb-tablet-gigantic4x {
    padding-bottom: 8rem !important;
  }
  .pl-tablet-none {
    padding-left: 0 !important;
  }
  .pl-tablet-tiny {
    padding-left: 0.25rem !important;
  }
  .pl-tablet-small {
    padding-left: 0.5rem !important;
  }
  .pl-tablet-medium {
    padding-left: 0.75rem !important;
  }
  .pl-tablet-large {
    padding-left: 1rem !important;
  }
  .pl-tablet-huge {
    padding-left: 1.5rem !important;
  }
  .pl-tablet-gigantic {
    padding-left: 2rem !important;
  }
  .pl-tablet-huge2x {
    padding-left: 3rem !important;
  }
  .pl-tablet-gigantic2x {
    padding-left: 4rem !important;
  }
  .pl-tablet-gigantic4x {
    padding-left: 8rem !important;
  }
  .text-tablet-start {
    text-align: left !important;
  }
  .text-tablet-end {
    text-align: right !important;
  }
  .text-tablet-center {
    text-align: center !important;
  }
}
@media (min-width: 900px) {
  .float-laptop-start {
    float: left !important;
  }
  .float-laptop-end {
    float: right !important;
  }
  .float-laptop-none {
    float: none !important;
  }
  .d-laptop-inline {
    display: inline !important;
  }
  .d-laptop-inline-block {
    display: inline-block !important;
  }
  .d-laptop-block {
    display: block !important;
  }
  .d-laptop-grid {
    display: grid !important;
  }
  .d-laptop-table {
    display: table !important;
  }
  .d-laptop-table-row {
    display: table-row !important;
  }
  .d-laptop-table-cell {
    display: table-cell !important;
  }
  .d-laptop-flex {
    display: flex !important;
  }
  .d-laptop-inline-flex {
    display: inline-flex !important;
  }
  .d-laptop-none {
    display: none !important;
  }
  .flex-laptop-fill {
    flex: 1 1 auto !important;
  }
  .flex-laptop-row {
    flex-direction: row !important;
  }
  .flex-laptop-column {
    flex-direction: column !important;
  }
  .flex-laptop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-laptop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-laptop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-laptop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-laptop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-laptop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-laptop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-laptop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-laptop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-laptop-none {
    gap: 0 !important;
  }
  .gap-laptop-tiny {
    gap: 0.25rem !important;
  }
  .gap-laptop-small {
    gap: 0.5rem !important;
  }
  .gap-laptop-medium {
    gap: 0.75rem !important;
  }
  .gap-laptop-large {
    gap: 1rem !important;
  }
  .gap-laptop-huge {
    gap: 1.5rem !important;
  }
  .gap-laptop-gigantic {
    gap: 2rem !important;
  }
  .gap-laptop-huge2x {
    gap: 3rem !important;
  }
  .gap-laptop-gigantic2x {
    gap: 4rem !important;
  }
  .gap-laptop-gigantic4x {
    gap: 8rem !important;
  }
  .justify-content-laptop-start {
    justify-content: flex-start !important;
  }
  .justify-content-laptop-end {
    justify-content: flex-end !important;
  }
  .justify-content-laptop-center {
    justify-content: center !important;
  }
  .justify-content-laptop-between {
    justify-content: space-between !important;
  }
  .justify-content-laptop-around {
    justify-content: space-around !important;
  }
  .justify-content-laptop-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-laptop-start {
    align-items: flex-start !important;
  }
  .align-items-laptop-end {
    align-items: flex-end !important;
  }
  .align-items-laptop-center {
    align-items: center !important;
  }
  .align-items-laptop-baseline {
    align-items: baseline !important;
  }
  .align-items-laptop-stretch {
    align-items: stretch !important;
  }
  .align-content-laptop-start {
    align-content: flex-start !important;
  }
  .align-content-laptop-end {
    align-content: flex-end !important;
  }
  .align-content-laptop-center {
    align-content: center !important;
  }
  .align-content-laptop-between {
    align-content: space-between !important;
  }
  .align-content-laptop-around {
    align-content: space-around !important;
  }
  .align-content-laptop-stretch {
    align-content: stretch !important;
  }
  .align-self-laptop-auto {
    align-self: auto !important;
  }
  .align-self-laptop-start {
    align-self: flex-start !important;
  }
  .align-self-laptop-end {
    align-self: flex-end !important;
  }
  .align-self-laptop-center {
    align-self: center !important;
  }
  .align-self-laptop-baseline {
    align-self: baseline !important;
  }
  .align-self-laptop-stretch {
    align-self: stretch !important;
  }
  .order-laptop-first {
    order: -1 !important;
  }
  .order-laptop-0 {
    order: 0 !important;
  }
  .order-laptop-1 {
    order: 1 !important;
  }
  .order-laptop-2 {
    order: 2 !important;
  }
  .order-laptop-3 {
    order: 3 !important;
  }
  .order-laptop-4 {
    order: 4 !important;
  }
  .order-laptop-5 {
    order: 5 !important;
  }
  .order-laptop-last {
    order: 6 !important;
  }
  .m-laptop-none {
    margin: 0 !important;
  }
  .m-laptop-tiny {
    margin: 0.25rem !important;
  }
  .m-laptop-small {
    margin: 0.5rem !important;
  }
  .m-laptop-medium {
    margin: 0.75rem !important;
  }
  .m-laptop-large {
    margin: 1rem !important;
  }
  .m-laptop-huge {
    margin: 1.5rem !important;
  }
  .m-laptop-gigantic {
    margin: 2rem !important;
  }
  .m-laptop-huge2x {
    margin: 3rem !important;
  }
  .m-laptop-gigantic2x {
    margin: 4rem !important;
  }
  .m-laptop-gigantic4x {
    margin: 8rem !important;
  }
  .m-laptop-auto {
    margin: auto !important;
  }
  .mx-laptop-none {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-laptop-tiny {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-laptop-small {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-laptop-medium {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-laptop-large {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-laptop-huge {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-laptop-gigantic {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-laptop-huge2x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-laptop-gigantic2x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-laptop-gigantic4x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-laptop-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-laptop-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-laptop-tiny {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-laptop-small {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-laptop-medium {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-laptop-large {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-laptop-huge {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-laptop-gigantic {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-laptop-huge2x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-laptop-gigantic2x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-laptop-gigantic4x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-laptop-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-laptop-none {
    margin-top: 0 !important;
  }
  .mt-laptop-tiny {
    margin-top: 0.25rem !important;
  }
  .mt-laptop-small {
    margin-top: 0.5rem !important;
  }
  .mt-laptop-medium {
    margin-top: 0.75rem !important;
  }
  .mt-laptop-large {
    margin-top: 1rem !important;
  }
  .mt-laptop-huge {
    margin-top: 1.5rem !important;
  }
  .mt-laptop-gigantic {
    margin-top: 2rem !important;
  }
  .mt-laptop-huge2x {
    margin-top: 3rem !important;
  }
  .mt-laptop-gigantic2x {
    margin-top: 4rem !important;
  }
  .mt-laptop-gigantic4x {
    margin-top: 8rem !important;
  }
  .mt-laptop-auto {
    margin-top: auto !important;
  }
  .mr-laptop-none {
    margin-right: 0 !important;
  }
  .mr-laptop-tiny {
    margin-right: 0.25rem !important;
  }
  .mr-laptop-small {
    margin-right: 0.5rem !important;
  }
  .mr-laptop-medium {
    margin-right: 0.75rem !important;
  }
  .mr-laptop-large {
    margin-right: 1rem !important;
  }
  .mr-laptop-huge {
    margin-right: 1.5rem !important;
  }
  .mr-laptop-gigantic {
    margin-right: 2rem !important;
  }
  .mr-laptop-huge2x {
    margin-right: 3rem !important;
  }
  .mr-laptop-gigantic2x {
    margin-right: 4rem !important;
  }
  .mr-laptop-gigantic4x {
    margin-right: 8rem !important;
  }
  .mr-laptop-auto {
    margin-right: auto !important;
  }
  .mb-laptop-none {
    margin-bottom: 0 !important;
  }
  .mb-laptop-tiny {
    margin-bottom: 0.25rem !important;
  }
  .mb-laptop-small {
    margin-bottom: 0.5rem !important;
  }
  .mb-laptop-medium {
    margin-bottom: 0.75rem !important;
  }
  .mb-laptop-large {
    margin-bottom: 1rem !important;
  }
  .mb-laptop-huge {
    margin-bottom: 1.5rem !important;
  }
  .mb-laptop-gigantic {
    margin-bottom: 2rem !important;
  }
  .mb-laptop-huge2x {
    margin-bottom: 3rem !important;
  }
  .mb-laptop-gigantic2x {
    margin-bottom: 4rem !important;
  }
  .mb-laptop-gigantic4x {
    margin-bottom: 8rem !important;
  }
  .mb-laptop-auto {
    margin-bottom: auto !important;
  }
  .ml-laptop-none {
    margin-left: 0 !important;
  }
  .ml-laptop-tiny {
    margin-left: 0.25rem !important;
  }
  .ml-laptop-small {
    margin-left: 0.5rem !important;
  }
  .ml-laptop-medium {
    margin-left: 0.75rem !important;
  }
  .ml-laptop-large {
    margin-left: 1rem !important;
  }
  .ml-laptop-huge {
    margin-left: 1.5rem !important;
  }
  .ml-laptop-gigantic {
    margin-left: 2rem !important;
  }
  .ml-laptop-huge2x {
    margin-left: 3rem !important;
  }
  .ml-laptop-gigantic2x {
    margin-left: 4rem !important;
  }
  .ml-laptop-gigantic4x {
    margin-left: 8rem !important;
  }
  .ml-laptop-auto {
    margin-left: auto !important;
  }
  .p-laptop-none {
    padding: 0 !important;
  }
  .p-laptop-tiny {
    padding: 0.25rem !important;
  }
  .p-laptop-small {
    padding: 0.5rem !important;
  }
  .p-laptop-medium {
    padding: 0.75rem !important;
  }
  .p-laptop-large {
    padding: 1rem !important;
  }
  .p-laptop-huge {
    padding: 1.5rem !important;
  }
  .p-laptop-gigantic {
    padding: 2rem !important;
  }
  .p-laptop-huge2x {
    padding: 3rem !important;
  }
  .p-laptop-gigantic2x {
    padding: 4rem !important;
  }
  .p-laptop-gigantic4x {
    padding: 8rem !important;
  }
  .px-laptop-none {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-laptop-tiny {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-laptop-small {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-laptop-medium {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-laptop-large {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-laptop-huge {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-laptop-gigantic {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-laptop-huge2x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-laptop-gigantic2x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-laptop-gigantic4x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-laptop-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-laptop-tiny {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-laptop-small {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-laptop-medium {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-laptop-large {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-laptop-huge {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-laptop-gigantic {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-laptop-huge2x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-laptop-gigantic2x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-laptop-gigantic4x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-laptop-none {
    padding-top: 0 !important;
  }
  .pt-laptop-tiny {
    padding-top: 0.25rem !important;
  }
  .pt-laptop-small {
    padding-top: 0.5rem !important;
  }
  .pt-laptop-medium {
    padding-top: 0.75rem !important;
  }
  .pt-laptop-large {
    padding-top: 1rem !important;
  }
  .pt-laptop-huge {
    padding-top: 1.5rem !important;
  }
  .pt-laptop-gigantic {
    padding-top: 2rem !important;
  }
  .pt-laptop-huge2x {
    padding-top: 3rem !important;
  }
  .pt-laptop-gigantic2x {
    padding-top: 4rem !important;
  }
  .pt-laptop-gigantic4x {
    padding-top: 8rem !important;
  }
  .pr-laptop-none {
    padding-right: 0 !important;
  }
  .pr-laptop-tiny {
    padding-right: 0.25rem !important;
  }
  .pr-laptop-small {
    padding-right: 0.5rem !important;
  }
  .pr-laptop-medium {
    padding-right: 0.75rem !important;
  }
  .pr-laptop-large {
    padding-right: 1rem !important;
  }
  .pr-laptop-huge {
    padding-right: 1.5rem !important;
  }
  .pr-laptop-gigantic {
    padding-right: 2rem !important;
  }
  .pr-laptop-huge2x {
    padding-right: 3rem !important;
  }
  .pr-laptop-gigantic2x {
    padding-right: 4rem !important;
  }
  .pr-laptop-gigantic4x {
    padding-right: 8rem !important;
  }
  .pb-laptop-none {
    padding-bottom: 0 !important;
  }
  .pb-laptop-tiny {
    padding-bottom: 0.25rem !important;
  }
  .pb-laptop-small {
    padding-bottom: 0.5rem !important;
  }
  .pb-laptop-medium {
    padding-bottom: 0.75rem !important;
  }
  .pb-laptop-large {
    padding-bottom: 1rem !important;
  }
  .pb-laptop-huge {
    padding-bottom: 1.5rem !important;
  }
  .pb-laptop-gigantic {
    padding-bottom: 2rem !important;
  }
  .pb-laptop-huge2x {
    padding-bottom: 3rem !important;
  }
  .pb-laptop-gigantic2x {
    padding-bottom: 4rem !important;
  }
  .pb-laptop-gigantic4x {
    padding-bottom: 8rem !important;
  }
  .pl-laptop-none {
    padding-left: 0 !important;
  }
  .pl-laptop-tiny {
    padding-left: 0.25rem !important;
  }
  .pl-laptop-small {
    padding-left: 0.5rem !important;
  }
  .pl-laptop-medium {
    padding-left: 0.75rem !important;
  }
  .pl-laptop-large {
    padding-left: 1rem !important;
  }
  .pl-laptop-huge {
    padding-left: 1.5rem !important;
  }
  .pl-laptop-gigantic {
    padding-left: 2rem !important;
  }
  .pl-laptop-huge2x {
    padding-left: 3rem !important;
  }
  .pl-laptop-gigantic2x {
    padding-left: 4rem !important;
  }
  .pl-laptop-gigantic4x {
    padding-left: 8rem !important;
  }
  .text-laptop-start {
    text-align: left !important;
  }
  .text-laptop-end {
    text-align: right !important;
  }
  .text-laptop-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-desktop-start {
    float: left !important;
  }
  .float-desktop-end {
    float: right !important;
  }
  .float-desktop-none {
    float: none !important;
  }
  .d-desktop-inline {
    display: inline !important;
  }
  .d-desktop-inline-block {
    display: inline-block !important;
  }
  .d-desktop-block {
    display: block !important;
  }
  .d-desktop-grid {
    display: grid !important;
  }
  .d-desktop-table {
    display: table !important;
  }
  .d-desktop-table-row {
    display: table-row !important;
  }
  .d-desktop-table-cell {
    display: table-cell !important;
  }
  .d-desktop-flex {
    display: flex !important;
  }
  .d-desktop-inline-flex {
    display: inline-flex !important;
  }
  .d-desktop-none {
    display: none !important;
  }
  .flex-desktop-fill {
    flex: 1 1 auto !important;
  }
  .flex-desktop-row {
    flex-direction: row !important;
  }
  .flex-desktop-column {
    flex-direction: column !important;
  }
  .flex-desktop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-desktop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-desktop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-desktop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-desktop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-desktop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-desktop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-desktop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-desktop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-desktop-none {
    gap: 0 !important;
  }
  .gap-desktop-tiny {
    gap: 0.25rem !important;
  }
  .gap-desktop-small {
    gap: 0.5rem !important;
  }
  .gap-desktop-medium {
    gap: 0.75rem !important;
  }
  .gap-desktop-large {
    gap: 1rem !important;
  }
  .gap-desktop-huge {
    gap: 1.5rem !important;
  }
  .gap-desktop-gigantic {
    gap: 2rem !important;
  }
  .gap-desktop-huge2x {
    gap: 3rem !important;
  }
  .gap-desktop-gigantic2x {
    gap: 4rem !important;
  }
  .gap-desktop-gigantic4x {
    gap: 8rem !important;
  }
  .justify-content-desktop-start {
    justify-content: flex-start !important;
  }
  .justify-content-desktop-end {
    justify-content: flex-end !important;
  }
  .justify-content-desktop-center {
    justify-content: center !important;
  }
  .justify-content-desktop-between {
    justify-content: space-between !important;
  }
  .justify-content-desktop-around {
    justify-content: space-around !important;
  }
  .justify-content-desktop-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-desktop-start {
    align-items: flex-start !important;
  }
  .align-items-desktop-end {
    align-items: flex-end !important;
  }
  .align-items-desktop-center {
    align-items: center !important;
  }
  .align-items-desktop-baseline {
    align-items: baseline !important;
  }
  .align-items-desktop-stretch {
    align-items: stretch !important;
  }
  .align-content-desktop-start {
    align-content: flex-start !important;
  }
  .align-content-desktop-end {
    align-content: flex-end !important;
  }
  .align-content-desktop-center {
    align-content: center !important;
  }
  .align-content-desktop-between {
    align-content: space-between !important;
  }
  .align-content-desktop-around {
    align-content: space-around !important;
  }
  .align-content-desktop-stretch {
    align-content: stretch !important;
  }
  .align-self-desktop-auto {
    align-self: auto !important;
  }
  .align-self-desktop-start {
    align-self: flex-start !important;
  }
  .align-self-desktop-end {
    align-self: flex-end !important;
  }
  .align-self-desktop-center {
    align-self: center !important;
  }
  .align-self-desktop-baseline {
    align-self: baseline !important;
  }
  .align-self-desktop-stretch {
    align-self: stretch !important;
  }
  .order-desktop-first {
    order: -1 !important;
  }
  .order-desktop-0 {
    order: 0 !important;
  }
  .order-desktop-1 {
    order: 1 !important;
  }
  .order-desktop-2 {
    order: 2 !important;
  }
  .order-desktop-3 {
    order: 3 !important;
  }
  .order-desktop-4 {
    order: 4 !important;
  }
  .order-desktop-5 {
    order: 5 !important;
  }
  .order-desktop-last {
    order: 6 !important;
  }
  .m-desktop-none {
    margin: 0 !important;
  }
  .m-desktop-tiny {
    margin: 0.25rem !important;
  }
  .m-desktop-small {
    margin: 0.5rem !important;
  }
  .m-desktop-medium {
    margin: 0.75rem !important;
  }
  .m-desktop-large {
    margin: 1rem !important;
  }
  .m-desktop-huge {
    margin: 1.5rem !important;
  }
  .m-desktop-gigantic {
    margin: 2rem !important;
  }
  .m-desktop-huge2x {
    margin: 3rem !important;
  }
  .m-desktop-gigantic2x {
    margin: 4rem !important;
  }
  .m-desktop-gigantic4x {
    margin: 8rem !important;
  }
  .m-desktop-auto {
    margin: auto !important;
  }
  .mx-desktop-none {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-desktop-tiny {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-desktop-small {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-desktop-medium {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-desktop-large {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-desktop-huge {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-desktop-gigantic {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-desktop-huge2x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-desktop-gigantic2x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-desktop-gigantic4x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-desktop-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-desktop-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-desktop-tiny {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-desktop-small {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-desktop-medium {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-desktop-large {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-desktop-huge {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-desktop-gigantic {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-desktop-huge2x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-desktop-gigantic2x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-desktop-gigantic4x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-desktop-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-desktop-none {
    margin-top: 0 !important;
  }
  .mt-desktop-tiny {
    margin-top: 0.25rem !important;
  }
  .mt-desktop-small {
    margin-top: 0.5rem !important;
  }
  .mt-desktop-medium {
    margin-top: 0.75rem !important;
  }
  .mt-desktop-large {
    margin-top: 1rem !important;
  }
  .mt-desktop-huge {
    margin-top: 1.5rem !important;
  }
  .mt-desktop-gigantic {
    margin-top: 2rem !important;
  }
  .mt-desktop-huge2x {
    margin-top: 3rem !important;
  }
  .mt-desktop-gigantic2x {
    margin-top: 4rem !important;
  }
  .mt-desktop-gigantic4x {
    margin-top: 8rem !important;
  }
  .mt-desktop-auto {
    margin-top: auto !important;
  }
  .mr-desktop-none {
    margin-right: 0 !important;
  }
  .mr-desktop-tiny {
    margin-right: 0.25rem !important;
  }
  .mr-desktop-small {
    margin-right: 0.5rem !important;
  }
  .mr-desktop-medium {
    margin-right: 0.75rem !important;
  }
  .mr-desktop-large {
    margin-right: 1rem !important;
  }
  .mr-desktop-huge {
    margin-right: 1.5rem !important;
  }
  .mr-desktop-gigantic {
    margin-right: 2rem !important;
  }
  .mr-desktop-huge2x {
    margin-right: 3rem !important;
  }
  .mr-desktop-gigantic2x {
    margin-right: 4rem !important;
  }
  .mr-desktop-gigantic4x {
    margin-right: 8rem !important;
  }
  .mr-desktop-auto {
    margin-right: auto !important;
  }
  .mb-desktop-none {
    margin-bottom: 0 !important;
  }
  .mb-desktop-tiny {
    margin-bottom: 0.25rem !important;
  }
  .mb-desktop-small {
    margin-bottom: 0.5rem !important;
  }
  .mb-desktop-medium {
    margin-bottom: 0.75rem !important;
  }
  .mb-desktop-large {
    margin-bottom: 1rem !important;
  }
  .mb-desktop-huge {
    margin-bottom: 1.5rem !important;
  }
  .mb-desktop-gigantic {
    margin-bottom: 2rem !important;
  }
  .mb-desktop-huge2x {
    margin-bottom: 3rem !important;
  }
  .mb-desktop-gigantic2x {
    margin-bottom: 4rem !important;
  }
  .mb-desktop-gigantic4x {
    margin-bottom: 8rem !important;
  }
  .mb-desktop-auto {
    margin-bottom: auto !important;
  }
  .ml-desktop-none {
    margin-left: 0 !important;
  }
  .ml-desktop-tiny {
    margin-left: 0.25rem !important;
  }
  .ml-desktop-small {
    margin-left: 0.5rem !important;
  }
  .ml-desktop-medium {
    margin-left: 0.75rem !important;
  }
  .ml-desktop-large {
    margin-left: 1rem !important;
  }
  .ml-desktop-huge {
    margin-left: 1.5rem !important;
  }
  .ml-desktop-gigantic {
    margin-left: 2rem !important;
  }
  .ml-desktop-huge2x {
    margin-left: 3rem !important;
  }
  .ml-desktop-gigantic2x {
    margin-left: 4rem !important;
  }
  .ml-desktop-gigantic4x {
    margin-left: 8rem !important;
  }
  .ml-desktop-auto {
    margin-left: auto !important;
  }
  .p-desktop-none {
    padding: 0 !important;
  }
  .p-desktop-tiny {
    padding: 0.25rem !important;
  }
  .p-desktop-small {
    padding: 0.5rem !important;
  }
  .p-desktop-medium {
    padding: 0.75rem !important;
  }
  .p-desktop-large {
    padding: 1rem !important;
  }
  .p-desktop-huge {
    padding: 1.5rem !important;
  }
  .p-desktop-gigantic {
    padding: 2rem !important;
  }
  .p-desktop-huge2x {
    padding: 3rem !important;
  }
  .p-desktop-gigantic2x {
    padding: 4rem !important;
  }
  .p-desktop-gigantic4x {
    padding: 8rem !important;
  }
  .px-desktop-none {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-desktop-tiny {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-desktop-small {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-desktop-medium {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-desktop-large {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-desktop-huge {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-desktop-gigantic {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-desktop-huge2x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-desktop-gigantic2x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-desktop-gigantic4x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-desktop-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-desktop-tiny {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-desktop-small {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-desktop-medium {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-desktop-large {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-desktop-huge {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-desktop-gigantic {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-desktop-huge2x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-desktop-gigantic2x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-desktop-gigantic4x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-desktop-none {
    padding-top: 0 !important;
  }
  .pt-desktop-tiny {
    padding-top: 0.25rem !important;
  }
  .pt-desktop-small {
    padding-top: 0.5rem !important;
  }
  .pt-desktop-medium {
    padding-top: 0.75rem !important;
  }
  .pt-desktop-large {
    padding-top: 1rem !important;
  }
  .pt-desktop-huge {
    padding-top: 1.5rem !important;
  }
  .pt-desktop-gigantic {
    padding-top: 2rem !important;
  }
  .pt-desktop-huge2x {
    padding-top: 3rem !important;
  }
  .pt-desktop-gigantic2x {
    padding-top: 4rem !important;
  }
  .pt-desktop-gigantic4x {
    padding-top: 8rem !important;
  }
  .pr-desktop-none {
    padding-right: 0 !important;
  }
  .pr-desktop-tiny {
    padding-right: 0.25rem !important;
  }
  .pr-desktop-small {
    padding-right: 0.5rem !important;
  }
  .pr-desktop-medium {
    padding-right: 0.75rem !important;
  }
  .pr-desktop-large {
    padding-right: 1rem !important;
  }
  .pr-desktop-huge {
    padding-right: 1.5rem !important;
  }
  .pr-desktop-gigantic {
    padding-right: 2rem !important;
  }
  .pr-desktop-huge2x {
    padding-right: 3rem !important;
  }
  .pr-desktop-gigantic2x {
    padding-right: 4rem !important;
  }
  .pr-desktop-gigantic4x {
    padding-right: 8rem !important;
  }
  .pb-desktop-none {
    padding-bottom: 0 !important;
  }
  .pb-desktop-tiny {
    padding-bottom: 0.25rem !important;
  }
  .pb-desktop-small {
    padding-bottom: 0.5rem !important;
  }
  .pb-desktop-medium {
    padding-bottom: 0.75rem !important;
  }
  .pb-desktop-large {
    padding-bottom: 1rem !important;
  }
  .pb-desktop-huge {
    padding-bottom: 1.5rem !important;
  }
  .pb-desktop-gigantic {
    padding-bottom: 2rem !important;
  }
  .pb-desktop-huge2x {
    padding-bottom: 3rem !important;
  }
  .pb-desktop-gigantic2x {
    padding-bottom: 4rem !important;
  }
  .pb-desktop-gigantic4x {
    padding-bottom: 8rem !important;
  }
  .pl-desktop-none {
    padding-left: 0 !important;
  }
  .pl-desktop-tiny {
    padding-left: 0.25rem !important;
  }
  .pl-desktop-small {
    padding-left: 0.5rem !important;
  }
  .pl-desktop-medium {
    padding-left: 0.75rem !important;
  }
  .pl-desktop-large {
    padding-left: 1rem !important;
  }
  .pl-desktop-huge {
    padding-left: 1.5rem !important;
  }
  .pl-desktop-gigantic {
    padding-left: 2rem !important;
  }
  .pl-desktop-huge2x {
    padding-left: 3rem !important;
  }
  .pl-desktop-gigantic2x {
    padding-left: 4rem !important;
  }
  .pl-desktop-gigantic4x {
    padding-left: 8rem !important;
  }
  .text-desktop-start {
    text-align: left !important;
  }
  .text-desktop-end {
    text-align: right !important;
  }
  .text-desktop-center {
    text-align: center !important;
  }
}
@media (min-width: 1800px) {
  .float-tv-start {
    float: left !important;
  }
  .float-tv-end {
    float: right !important;
  }
  .float-tv-none {
    float: none !important;
  }
  .d-tv-inline {
    display: inline !important;
  }
  .d-tv-inline-block {
    display: inline-block !important;
  }
  .d-tv-block {
    display: block !important;
  }
  .d-tv-grid {
    display: grid !important;
  }
  .d-tv-table {
    display: table !important;
  }
  .d-tv-table-row {
    display: table-row !important;
  }
  .d-tv-table-cell {
    display: table-cell !important;
  }
  .d-tv-flex {
    display: flex !important;
  }
  .d-tv-inline-flex {
    display: inline-flex !important;
  }
  .d-tv-none {
    display: none !important;
  }
  .flex-tv-fill {
    flex: 1 1 auto !important;
  }
  .flex-tv-row {
    flex-direction: row !important;
  }
  .flex-tv-column {
    flex-direction: column !important;
  }
  .flex-tv-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-tv-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-tv-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-tv-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-tv-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-tv-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-tv-wrap {
    flex-wrap: wrap !important;
  }
  .flex-tv-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-tv-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-tv-none {
    gap: 0 !important;
  }
  .gap-tv-tiny {
    gap: 0.25rem !important;
  }
  .gap-tv-small {
    gap: 0.5rem !important;
  }
  .gap-tv-medium {
    gap: 0.75rem !important;
  }
  .gap-tv-large {
    gap: 1rem !important;
  }
  .gap-tv-huge {
    gap: 1.5rem !important;
  }
  .gap-tv-gigantic {
    gap: 2rem !important;
  }
  .gap-tv-huge2x {
    gap: 3rem !important;
  }
  .gap-tv-gigantic2x {
    gap: 4rem !important;
  }
  .gap-tv-gigantic4x {
    gap: 8rem !important;
  }
  .justify-content-tv-start {
    justify-content: flex-start !important;
  }
  .justify-content-tv-end {
    justify-content: flex-end !important;
  }
  .justify-content-tv-center {
    justify-content: center !important;
  }
  .justify-content-tv-between {
    justify-content: space-between !important;
  }
  .justify-content-tv-around {
    justify-content: space-around !important;
  }
  .justify-content-tv-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-tv-start {
    align-items: flex-start !important;
  }
  .align-items-tv-end {
    align-items: flex-end !important;
  }
  .align-items-tv-center {
    align-items: center !important;
  }
  .align-items-tv-baseline {
    align-items: baseline !important;
  }
  .align-items-tv-stretch {
    align-items: stretch !important;
  }
  .align-content-tv-start {
    align-content: flex-start !important;
  }
  .align-content-tv-end {
    align-content: flex-end !important;
  }
  .align-content-tv-center {
    align-content: center !important;
  }
  .align-content-tv-between {
    align-content: space-between !important;
  }
  .align-content-tv-around {
    align-content: space-around !important;
  }
  .align-content-tv-stretch {
    align-content: stretch !important;
  }
  .align-self-tv-auto {
    align-self: auto !important;
  }
  .align-self-tv-start {
    align-self: flex-start !important;
  }
  .align-self-tv-end {
    align-self: flex-end !important;
  }
  .align-self-tv-center {
    align-self: center !important;
  }
  .align-self-tv-baseline {
    align-self: baseline !important;
  }
  .align-self-tv-stretch {
    align-self: stretch !important;
  }
  .order-tv-first {
    order: -1 !important;
  }
  .order-tv-0 {
    order: 0 !important;
  }
  .order-tv-1 {
    order: 1 !important;
  }
  .order-tv-2 {
    order: 2 !important;
  }
  .order-tv-3 {
    order: 3 !important;
  }
  .order-tv-4 {
    order: 4 !important;
  }
  .order-tv-5 {
    order: 5 !important;
  }
  .order-tv-last {
    order: 6 !important;
  }
  .m-tv-none {
    margin: 0 !important;
  }
  .m-tv-tiny {
    margin: 0.25rem !important;
  }
  .m-tv-small {
    margin: 0.5rem !important;
  }
  .m-tv-medium {
    margin: 0.75rem !important;
  }
  .m-tv-large {
    margin: 1rem !important;
  }
  .m-tv-huge {
    margin: 1.5rem !important;
  }
  .m-tv-gigantic {
    margin: 2rem !important;
  }
  .m-tv-huge2x {
    margin: 3rem !important;
  }
  .m-tv-gigantic2x {
    margin: 4rem !important;
  }
  .m-tv-gigantic4x {
    margin: 8rem !important;
  }
  .m-tv-auto {
    margin: auto !important;
  }
  .mx-tv-none {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-tv-tiny {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-tv-small {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-tv-medium {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-tv-large {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-tv-huge {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-tv-gigantic {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-tv-huge2x {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-tv-gigantic2x {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-tv-gigantic4x {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-tv-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-tv-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-tv-tiny {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-tv-small {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-tv-medium {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-tv-large {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-tv-huge {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-tv-gigantic {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-tv-huge2x {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-tv-gigantic2x {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-tv-gigantic4x {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-tv-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-tv-none {
    margin-top: 0 !important;
  }
  .mt-tv-tiny {
    margin-top: 0.25rem !important;
  }
  .mt-tv-small {
    margin-top: 0.5rem !important;
  }
  .mt-tv-medium {
    margin-top: 0.75rem !important;
  }
  .mt-tv-large {
    margin-top: 1rem !important;
  }
  .mt-tv-huge {
    margin-top: 1.5rem !important;
  }
  .mt-tv-gigantic {
    margin-top: 2rem !important;
  }
  .mt-tv-huge2x {
    margin-top: 3rem !important;
  }
  .mt-tv-gigantic2x {
    margin-top: 4rem !important;
  }
  .mt-tv-gigantic4x {
    margin-top: 8rem !important;
  }
  .mt-tv-auto {
    margin-top: auto !important;
  }
  .mr-tv-none {
    margin-right: 0 !important;
  }
  .mr-tv-tiny {
    margin-right: 0.25rem !important;
  }
  .mr-tv-small {
    margin-right: 0.5rem !important;
  }
  .mr-tv-medium {
    margin-right: 0.75rem !important;
  }
  .mr-tv-large {
    margin-right: 1rem !important;
  }
  .mr-tv-huge {
    margin-right: 1.5rem !important;
  }
  .mr-tv-gigantic {
    margin-right: 2rem !important;
  }
  .mr-tv-huge2x {
    margin-right: 3rem !important;
  }
  .mr-tv-gigantic2x {
    margin-right: 4rem !important;
  }
  .mr-tv-gigantic4x {
    margin-right: 8rem !important;
  }
  .mr-tv-auto {
    margin-right: auto !important;
  }
  .mb-tv-none {
    margin-bottom: 0 !important;
  }
  .mb-tv-tiny {
    margin-bottom: 0.25rem !important;
  }
  .mb-tv-small {
    margin-bottom: 0.5rem !important;
  }
  .mb-tv-medium {
    margin-bottom: 0.75rem !important;
  }
  .mb-tv-large {
    margin-bottom: 1rem !important;
  }
  .mb-tv-huge {
    margin-bottom: 1.5rem !important;
  }
  .mb-tv-gigantic {
    margin-bottom: 2rem !important;
  }
  .mb-tv-huge2x {
    margin-bottom: 3rem !important;
  }
  .mb-tv-gigantic2x {
    margin-bottom: 4rem !important;
  }
  .mb-tv-gigantic4x {
    margin-bottom: 8rem !important;
  }
  .mb-tv-auto {
    margin-bottom: auto !important;
  }
  .ml-tv-none {
    margin-left: 0 !important;
  }
  .ml-tv-tiny {
    margin-left: 0.25rem !important;
  }
  .ml-tv-small {
    margin-left: 0.5rem !important;
  }
  .ml-tv-medium {
    margin-left: 0.75rem !important;
  }
  .ml-tv-large {
    margin-left: 1rem !important;
  }
  .ml-tv-huge {
    margin-left: 1.5rem !important;
  }
  .ml-tv-gigantic {
    margin-left: 2rem !important;
  }
  .ml-tv-huge2x {
    margin-left: 3rem !important;
  }
  .ml-tv-gigantic2x {
    margin-left: 4rem !important;
  }
  .ml-tv-gigantic4x {
    margin-left: 8rem !important;
  }
  .ml-tv-auto {
    margin-left: auto !important;
  }
  .p-tv-none {
    padding: 0 !important;
  }
  .p-tv-tiny {
    padding: 0.25rem !important;
  }
  .p-tv-small {
    padding: 0.5rem !important;
  }
  .p-tv-medium {
    padding: 0.75rem !important;
  }
  .p-tv-large {
    padding: 1rem !important;
  }
  .p-tv-huge {
    padding: 1.5rem !important;
  }
  .p-tv-gigantic {
    padding: 2rem !important;
  }
  .p-tv-huge2x {
    padding: 3rem !important;
  }
  .p-tv-gigantic2x {
    padding: 4rem !important;
  }
  .p-tv-gigantic4x {
    padding: 8rem !important;
  }
  .px-tv-none {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-tv-tiny {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-tv-small {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-tv-medium {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-tv-large {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-tv-huge {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-tv-gigantic {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-tv-huge2x {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-tv-gigantic2x {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-tv-gigantic4x {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-tv-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-tv-tiny {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-tv-small {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-tv-medium {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-tv-large {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-tv-huge {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-tv-gigantic {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-tv-huge2x {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-tv-gigantic2x {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-tv-gigantic4x {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .pt-tv-none {
    padding-top: 0 !important;
  }
  .pt-tv-tiny {
    padding-top: 0.25rem !important;
  }
  .pt-tv-small {
    padding-top: 0.5rem !important;
  }
  .pt-tv-medium {
    padding-top: 0.75rem !important;
  }
  .pt-tv-large {
    padding-top: 1rem !important;
  }
  .pt-tv-huge {
    padding-top: 1.5rem !important;
  }
  .pt-tv-gigantic {
    padding-top: 2rem !important;
  }
  .pt-tv-huge2x {
    padding-top: 3rem !important;
  }
  .pt-tv-gigantic2x {
    padding-top: 4rem !important;
  }
  .pt-tv-gigantic4x {
    padding-top: 8rem !important;
  }
  .pr-tv-none {
    padding-right: 0 !important;
  }
  .pr-tv-tiny {
    padding-right: 0.25rem !important;
  }
  .pr-tv-small {
    padding-right: 0.5rem !important;
  }
  .pr-tv-medium {
    padding-right: 0.75rem !important;
  }
  .pr-tv-large {
    padding-right: 1rem !important;
  }
  .pr-tv-huge {
    padding-right: 1.5rem !important;
  }
  .pr-tv-gigantic {
    padding-right: 2rem !important;
  }
  .pr-tv-huge2x {
    padding-right: 3rem !important;
  }
  .pr-tv-gigantic2x {
    padding-right: 4rem !important;
  }
  .pr-tv-gigantic4x {
    padding-right: 8rem !important;
  }
  .pb-tv-none {
    padding-bottom: 0 !important;
  }
  .pb-tv-tiny {
    padding-bottom: 0.25rem !important;
  }
  .pb-tv-small {
    padding-bottom: 0.5rem !important;
  }
  .pb-tv-medium {
    padding-bottom: 0.75rem !important;
  }
  .pb-tv-large {
    padding-bottom: 1rem !important;
  }
  .pb-tv-huge {
    padding-bottom: 1.5rem !important;
  }
  .pb-tv-gigantic {
    padding-bottom: 2rem !important;
  }
  .pb-tv-huge2x {
    padding-bottom: 3rem !important;
  }
  .pb-tv-gigantic2x {
    padding-bottom: 4rem !important;
  }
  .pb-tv-gigantic4x {
    padding-bottom: 8rem !important;
  }
  .pl-tv-none {
    padding-left: 0 !important;
  }
  .pl-tv-tiny {
    padding-left: 0.25rem !important;
  }
  .pl-tv-small {
    padding-left: 0.5rem !important;
  }
  .pl-tv-medium {
    padding-left: 0.75rem !important;
  }
  .pl-tv-large {
    padding-left: 1rem !important;
  }
  .pl-tv-huge {
    padding-left: 1.5rem !important;
  }
  .pl-tv-gigantic {
    padding-left: 2rem !important;
  }
  .pl-tv-huge2x {
    padding-left: 3rem !important;
  }
  .pl-tv-gigantic2x {
    padding-left: 4rem !important;
  }
  .pl-tv-gigantic4x {
    padding-left: 8rem !important;
  }
  .text-tv-start {
    text-align: left !important;
  }
  .text-tv-end {
    text-align: right !important;
  }
  .text-tv-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.color-muted a {
  color: primary-base;
}

.text-caption {
  --font-size: 0.75rem;
  --line-height: 1.3333333333;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-body {
  --font-size: 1rem;
  --line-height: 1.5;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-body-large {
  --font-size: 1.125rem;
  --line-height: 1.5555555556;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .text-body-large {
    --font-size: 1.25rem;
    --line-height: 1.6;
  }
}

.text-heading-small {
  --font-size: 1.1875rem;
  --line-height: 1.2631578947;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-heading-medium {
  --font-size: 1.375rem;
  --line-height: 1.2727272727;
  font-weight: 600;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.text-heading-large {
  --font-size: 1.5rem;
  --line-height: 1.3333333333;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .text-heading-large {
    --font-size: 2rem;
    --line-height: 1.25;
  }
}

.text-display-small {
  --font-size: 1.5rem;
  --line-height: 1.3333333333;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .text-display-small {
    --font-size: 2.75rem;
    --line-height: 1.0909090909;
  }
}

.text-display-medium {
  --font-size: 2rem;
  --line-height: 1.25;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .text-display-medium {
    --font-size: 3.125rem;
    --line-height: 1.04;
  }
}

.text-display-large {
  --font-size: 2.5rem;
  --line-height: 1.2;
  font-weight: 700;
  font-size: var(--font-size);
  line-height: var(--line-height);
}
@media (min-width: 1200px) {
  .text-display-large {
    --font-size: 4.5rem;
    --line-height: 1;
  }
}