@import "../../00-settings/index";
@import "../../01-tools/index";

// Empty State
//
// Markup: empty-state.hbs
//
// Style guide: components.empty-state

.empty-state {
    padding: $empty-state-padding;
}

.empty-state-figure {
    margin-bottom: spacer(huge);
    text-align: center;

    img {
        width: 100%;
        max-width: rem(400px);
        height: auto;
    }
}

.empty-state-body {
    text-align: center;
}

.empty-state-action {
    margin-top: spacer(huge);
}
