@import "../../00-settings/index";
@import "../../01-tools/index";

// Dropdown
//
// A dropdown menu displays a list of actions or options to a user. They're used for navigation or command menus, where an action is initiated based on the selection.
//
// Markup: dropdown.html
//
// Style guide: components.dropdown

// Usage
//
// Dropdown menus are typically used when you have more than 2 items to choose from. Menu items can include links, buttons, radio buttons and checkboxes.
//
// Uses for dropdown menus:
//
// <ul><li>A "more" menu, where the control contains an icon.</li>
// <li>For user profile and settings, where the control is an avatar.</li>
// <li>A submenu for a nav item.</li></ul>
//
// Once the dropdown menu is displayed onscreen, it remains open until the user chooses a menu item, clicks outside of the menu, or until we show a <code>.toast</code> with a danger alert.
//
// <b>Behavior</b>
//
// In cases where a menu item is longer than the button's text label, the menu will grow to the width of the longest item listed. However, there is a maximum width specified by the component. If the menu item exceeds the maximum width it will be truncated.
//
// <b>Best practices</b>
//
// <ul><li>A number of components can be used as a dropdown menu trigger to give users the ability to select options: nav items, action buttons, searchboxes, filter buttons.</li>
// <li>When organizing dropdown menu items, sort the list in a logical order by putting the most selected option at the top, if known. Test and refine over time to re-evaluate if all menu items are needed.</li>
// <li>For long lists, group related menu items. If including radio buttons and checkboxes as menu items, try grouping related actions.</li>
// <li>Grouped items are separated by a short, uppercase title <code>.dropdown-title</code> that describes the options in that sub-category.</li>
// <li>For more than 2 groups, or too many items in a menu, add a divider line <code>.dropdown-divider</code> to create structure.</li></ul>
//
// Markup: dropdown-menu.html
//
// Style guide: components.dropdown.usage

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-control {
    white-space: nowrap;

    // Generate the caret automatically
    @include caret();
}

// The dropdown menu
.dropdown-menu {
    --margin-y: var(--#{$dropdown-margin-y});
    --padding-y: var(--#{$dropdown-padding-y});
    --color: var(--#{$dropdown-color});
    --bg: var(--#{$dropdown-bg});
    --border-color: var(--#{$dropdown-border-color});
    --border-radius: #{$dropdown-border-radius};

    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    min-width: $dropdown-min-width;
    max-width: $dropdown-max-width;
    margin: var(--margin-y, spacer(#{$dropdown-margin-y})) 0 0; // override default ul
    padding: var(--padding-y, spacer(#{$dropdown-padding-y})) 0;
    @include font-scale($dropdown-font-scale);
    color: var(--color, ui-color(#{$dropdown-color}));
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background: var(--bg, ui-color(#{$dropdown-bg}));
    background-clip: padding-box; // to make the alpha border color sit on top of canvas, not the .dropdown-menu
    border-color: var(--border-color, ui-color(#{$dropdown-border-color}));
    border-style: solid;
    border-width: $dropdown-border-width;
    border-radius: var(--border-radius, #{$dropdown-border-radius});
    box-shadow: $dropdown-box-shadow;

    // When Popper is enabled, reset the basic dropdown position
    &[x-placement^="top"],
    &[x-placement^="right"],
    &[x-placement^="bottom"],
    &[x-placement^="left"] {
        right: auto;
        bottom: auto;
    }

    &.show {
        display: block;
    }
}

// Create responsive positioning
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dropdown-menu#{$infix}-left {
            right: auto;
            left: 0;
        }

        .dropdown-menu#{$infix}-right {
            right: 0;
            left: auto;
        }
    }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: var(--margin-y, spacer(#{$dropdown-margin-y}));
    }

    .dropdown-toggle {
        @include caret(up);
    }
}

.dropright {
    .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: var(--margin-y, spacer(#{$dropdown-margin-y}));
    }

    .dropdown-toggle {
        @include caret(right);
        &::after {
            vertical-align: 0;
        }
    }
}

.dropleft {
    .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: var(--margin-y, spacer(#{$dropdown-margin-y}));
    }

    .dropdown-toggle {
        @include caret(left);
        &::before {
            vertical-align: 0;
        }
    }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
    --margin-y: var(--#{$dropdown-divider-margin-y});
    --bg: var(--#{$dropdown-divider-bg});

    display: block;
    height: 0;
    margin: var(--margin-y, spacer(#{$dropdown-divider-margin-y})) 0;
    overflow: hidden;
    border-color: var(--bg, ui-color(#{$dropdown-divider-bg}));
    border-style: solid;
    border-width: 1px 0 0 0;
}

// Menu item
//
// A dropdown menu item can:
//
// <ul><li>Be a link to an external page.</li>
// <li>Be a link to an ID inside the current page.</li>
// <li>Be a button that triggers a user action.</li>
// <li>Be a checkbox or a radio button to select an option.</li>
// <li>Have an <code>.icon</code>.</li></ul>
//
// <b>HTML tags</b>
//
// Markup: dropdown-item.html
//
// Weight: 1
//
// Style guide: components.dropdown.menu-item

// Menu item states
//
// A dropdown menu item can have 3 states:
//
// <ol><li><b>Default</b> state is the normal one</li>
// <li><b>Selected</b> state is triggered when the item is active(aka current page in nav) or checked.</li>
// <li><b>Disabled</b> state is triggered when the user cannot interact with the item.</li></ul>
//
// Markup:
// <div class="dropdown-menu show" aria-labelledby="dropdownControl" style="position: static;">
//     <button type="button" class="dropdown-item {{modifier_class}}">
//         <span class="dropdown-label">Menu item</span>
//     </button>
// </div>
//
// .selected        - Selected state
// .disabled        - Disabled state
//
// Weight: 2
//
// Style guide: components.dropdown.menu-item-states

.dropdown-item {
    --padding-y: var(--#{$dropdown-item-padding-y});
    --padding-x: var(--#{$dropdown-item-padding-x});
    --color: var(--#{$dropdown-item-color});
    --hover-color: var(--#{$dropdown-item-hover-color});
    --hover-bg: var(--#{$dropdown-item-hover-bg});
    --selected-color: var(--#{$dropdown-item-selected-color});
    --selected-bg: var(--#{$dropdown-item-selected-bg});
    --disabled-color: var(--#{$dropdown-item-disabled-color});
    --disabled-bg: var(--color-transparent);

    display: flex;
    align-items: center;
    clear: both;
    width: 100%; // for `<button>`s
    padding: var(--padding-y, spacer(#{$dropdown-item-padding-y})) var(--padding-x, spacer(#{$dropdown-item-padding-x}));
    color: var(--color, ui-color(#{$dropdown-item-color}));
    font-weight: $font-weight-normal;
    white-space: nowrap; // prevent randomly breaking onto new lines
    text-align: inherit; // for `<button>`s
    text-decoration: if($link-decoration == none, null, none);
    background: var(--color-transparent); // for `<button>`s
    border: 0; // for `<button>`s

    // Prevent dropdown overflow if there's no padding
    @if $dropdown-padding-y == none {
        &:first-child {
            border-radius: calc(var(--border-radius) - #{$dropdown-border-width}) calc(var(--border-radius) - #{$dropdown-border-width}) 0 0;

            &:last-child {
                border-radius: calc(var(--border-radius) - #{$dropdown-border-width});
            }
        }

        &:last-child {
            border-radius: 0 0 calc(var(--border-radius) - #{$dropdown-border-width}) calc(var(--border-radius) - #{$dropdown-border-width});
        }
    }

    &:hover {
        color: var(--hover-color, ui-color(#{$dropdown-item-hover-color}));
        text-decoration: none;
        background: var(--hover-bg, ui-color(#{$dropdown-item-hover-bg}));
    }

    &.selected {
        color: var(--selected-color, ui-color(#{$dropdown-item-selected-color}));
        text-decoration: none;
        background: var(--selected-bg, ui-color(#{$dropdown-item-selected-bg}));
    }

    &.disabled,
    &:disabled {
        color: var(--disabled-color, ui-color(#{$dropdown-item-disabled-color}));
        background: var(--disabled-bg, transparent);
        // Remove CSS gradients if they're enabled
        background-image: none;
        pointer-events: none;
    }

    // truncate label if it's too long
    > .dropdown-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    // add gap-x between icon and label
    > .icon:first-child {
        margin-right: var(--medium);
    }

    > .icon-check {
        color: var(--neutral-base);
    }

    // make the .form-check child fit the full width
    > .form-check {
        width: 100%;
    }
}

// Menu header
//
// Use <code>.dropdown-header</code> class to create a container for the dropdown menu header. It needs to be always first element.
//
// Markup:
// <div class="dropdown-menu show" role="menu" aria-labelledby="userMenu" style="position: static;">
//     <div class="dropdown-header">
//         <h3 class="text-heading-small mb-none">Raz Tirboaca</h3>
//         <small>razvan.tirboaca@wefox.com</small>
//     </div>
// </div>
//
// Weight: 3
//
// Style guide: components.dropdown.menu-header

.dropdown-header {
    --padding-y: var(--#{$dropdown-header-padding-y});
    --padding-x: var(--#{$dropdown-header-padding-x});
    --color: var(--#{$dropdown-header-color});

    display: block;
    padding: var(--padding-y, spacer(#{$dropdown-header-padding-y})) var(--padding-x, spacer(#{$dropdown-header-padding-x}));
    color: var(--color, ui-color(#{$dropdown-header-color}));
}

// Menu title
//
// Use <code>.dropdown-title</code> class to add a title for grouped items.
//
// Markup:
// <div class="dropdown-menu show" role="menu" aria-labelledby="userMenu" style="position: static;">
//     <h5 class="dropdown-title">Profile</h5>
// </div>
//
// Weight: 4
//
// Style guide: components.dropdown.menu-title

.dropdown-title {
    --margin-bottom: var(--none); // for heading elements
    --padding-y: var(--#{$dropdown-item-padding-y});
    --padding-x: var(--#{$dropdown-item-padding-x});
    --color: var(--#{$dropdown-title-color});

    display: block;
    padding: var(--padding-y, spacer(#{$dropdown-item-padding-y})) var(--padding-x, spacer(#{$dropdown-item-padding-x})) calc(var(--padding-y, spacer(#{$dropdown-item-padding-y})) / 2);
    overflow: hidden;
    color: var(--color, ui-color(#{$dropdown-title-color}));
    @include font-scale(caption);
    font-weight: $font-weight-bold;
    white-space: nowrap;
    text-transform: uppercase;
    text-overflow: ellipsis; // truncate text if it's too long
}

// Config options
//
// <pre><code class="sass">$dropdown-min-width:                    spacer(small) * 16 !default; // 128px
// $dropdown-max-width:                    spacer(small) * 40 !default; // 320px
// $dropdown-padding-y:                    tiny !default;
// $dropdown-margin-y:                     none !default;
// $dropdown-font-size:                    null !default;
// $dropdown-color:                        null !default;
// $dropdown-bg:                           neutral-start !default;
// $dropdown-border-color:                 neutral-base !default;
// $dropdown-border-radius:                $border-radius !default;
// $dropdown-border-width:                 1px !default;
// $dropdown-box-shadow:                   $box-shadow-lg !default;
// $dropdown-divider-bg:                   neutral-light !default;
// $dropdown-divider-margin-y:             $dropdown-padding-y !default;
//
// $dropdown-item-padding-y:               medium !default;
// $dropdown-item-padding-x:               large !default;
// $dropdown-item-color:                   neutral-end !default;
// $dropdown-item-hover-color:             $dropdown-item-color !default;
// $dropdown-item-hover-bg:                neutral-lightest !default;
// $dropdown-item-selected-color:            primary-base !default;
// $dropdown-item-selected-bg:               primary-lightest !default;
// $dropdown-item-disabled-color:          neutral-base !default;
//
// $dropdown-header-padding-y:             $dropdown-item-padding-y;
// $dropdown-header-padding-x:             $dropdown-item-padding-x !default;
// $dropdown-header-color:                 neutral-dark !default;
//
// $dropdown-title-color:                  neutral-dark !default;</code></pre>
//
// Weight: 6
//
// Style guide: components.dropdown.config
