@mixin action-block {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  text-align: center;
}

.c-action-block {
  @include action-block();
}
