// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
    font-size: $font-size-root;

    @if $enable-smooth-scroll {
        @media (prefers-reduced-motion: no-preference) {
            scroll-behavior: smooth;
        }
    }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

body {
    margin: 0; // 1
    color: var(--body-color, #{ui-color(body-color)});
    @include font-scale(body);
    font-weight: $font-weight-base;
    font-family: $font-family-base;
    text-align: $body-text-align;
    background: var(--body-bg, #{ui-color(body-bg)}); // 2
    -webkit-text-size-adjust: 100%; // 3
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 4
}

// Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
// on elements that programmatically receive focus but wouldn't normally show a visible
// focus outline. In general, this would mean that the outline is only applied if the
// interaction that led to the element receiving programmatic focus was a keyboard interaction,
// or the browser has somehow determined that the user is primarily a keyboard user and/or
// wants focus outlines to always be presented.
// See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
// and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/

:focus,
:focus-visible {
    outline: 0 !important;
}

:focus-visible {
    --focus-box-shadow: #{$focus-box-shadow};

    box-shadow: none;
}

// Content grouping
//
// 1. Reset Firefox's gray color
// 2. Set correct height and prevent the `size` attribute to make the `hr` look like an input field

hr {
    --margin-y: var(--#{$hr-margin-y});
    --color: var(--#{$hr-color});

    margin: var(--margin-y, spacer(#{$hr-margin-y})) 0;
    color: var(--color, ui-color(#{$hr-color})); // 1
    background-color: currentColor;
    border: 0;
    opacity: $hr-opacity;
}

hr:not([size]) {
    height: $hr-height; // 2
}

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
    --margin-bottom: var(--#{$headings-margin-b});

    margin-top: 0; // 1
    margin-bottom: var(--margin-bottom, spacer(#{$headings-margin-b}));
    font-family: $headings-font-family;
    font-style: $headings-font-style;
}

h1 {
    @extend %heading;
    @include font-scale($h1-font-scale);
}

h2 {
    @extend %heading;
    @include font-scale($h2-font-scale);
}

h3 {
    @extend %heading;
    @include font-scale($h3-font-scale);
}

h4 {
    @extend %heading;
    @include font-scale($h4-font-scale);
}

h5 {
    @extend %heading;
    @include font-scale($h5-font-scale);
}

h6 {
    @extend %heading;
    @include font-scale($h6-font-scale);
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
    --margin-bottom: var(--#{$paragraph-margin-b});

    margin-top: 0; // 1
    margin-bottom: var(--margin-bottom, spacer(#{$paragraph-margin-b}));
}

// Abbreviations
//
// 1. Duplicate behavior to the data-bs-* attribute for our tooltip plugin
// 2. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Prevent the text-decoration to be skipped.

abbr[title],
abbr[data-bs-original-title] { // 1
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
    cursor: help; // 3
    text-decoration-skip-ink: none; // 4
}

// Address

address {
    margin-bottom: var(--large, spacer(large));
    font-style: normal;
    line-height: inherit;
}

// Lists

ol,
ul {
    padding-left: var(--gigantic, spacer(gigantic));
}

ol,
ul,
dl {
    --margin-bottom: var(--large);

    margin-top: 0;
    margin-bottom: var(--margin-bottom, spacer(large));
}

ol ol,
ul ul,
ol ul,
ul ol {
    --margin-bottom: var(--none);
}

dt {
    font-weight: $dt-font-weight;
}

// 1. Undo browser default

dd {
    --margin-bottom: var(--small);

    margin-bottom: var(--margin-bottom, spacer(small));
    margin-left: 0; // 1
}

// Blockquote

blockquote {
    --margin-bottom: var(--large);

    margin: 0 0 var(--margin-bottom, spacer(large));
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
    font-weight: $font-weight-semibold;
}

// Small
//
// Add the correct font size in all browsers

small {
    @include font-scale(caption);
}

// Mark

mark {
    padding: var(#{$mark-padding}, spacer(#{$mark-padding}));
    background: var(--#{$mark-bg}, ui-color(#{$mark-bg}));
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
    position: relative;
    font-size: .5em;
    line-height: 0;
    vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }

// Links

a {
    --color: var(--#{$link-color});
    --hover-color: var(--#{$link-hover-color});

    color: var(--color, ui-color(#{$link-color}));
    text-decoration: $link-decoration;

    &:hover {
        --color: var(--hover-color);

        text-decoration: $link-hover-decoration;
    }
}

// And undo these styles for placeholder links/named anchors (without href)
a:not([href]):not([class]) {
    &,
    &:hover {
        --color: var(--);

        text-decoration: none;
    }
}

// Code

pre,
code,
kbd,
samp {
    font-size: 1em; // Correct the odd `em` font sizing in all browsers.
    font-family: $font-family-code;
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside

pre {
    --margin-bottom: var(--large);
    --color: var(--#{$pre-color});
    --bg: var(--#{$pre-bg});

    display: block;
    margin-top: 0; // 1
    margin-bottom: var(--margin-bottom, spacer(large)); // 2
    overflow: auto; // 3
    @include font-scale($code-font-scale);
    color: var(--color, ui-color(#{$pre-color}));
    background: var(--bg, ui-color(#{$pre-bg}));

    // Account for some code outputs that place code tags in pre tags
    code {
        color: inherit;
        font-size: inherit;
        word-break: normal;
    }
}

code {
    --color: var(--#{$code-color});

    @include font-scale($code-font-scale);
    color: var(--color, ui-color(#{$code-color}));
    word-wrap: break-word;

    // Streamline the style when inside anchors to avoid broken underline and more
    a > & {
        color: inherit;
    }
}

kbd {
    padding: var(--$kbd-padding-y, spacer(#{$kbd-padding-y})) var(--$kbd-padding-x, spacer(#{$kbd-padding-x}));
    @include font-scale($kbd-font-scale);
    color: var(--#{$kbd-color}, ui-color(#{$kbd-color}));
    background: var(--#{$kbd-bg}, ui-color(#{$kbd-bg}));
    border-radius: $border-radius-sm;

    kbd {
        padding: 0;
        font-weight: $nested-kbd-font-weight;
        font-size: 1em;
    }
}

// Figures
//
// Apply a consistent margin strategy (matches our type styles).

figure {
    --margin-bottom: var(--large);

    margin: 0 0 var(--margin-bottom, spacer(large));
}

// Images and content

img,
svg {
    vertical-align: middle;
}

// Tables
//
// Prevent double borders

table {
    font-size: $font-size-base;
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    --color: var(--#{$table-caption-color});

    padding-top: var(--#{$table-cell-padding-y}, spacer($table-cell-padding-y));
    padding-bottom: var(--#{$table-cell-padding-y}, spacer($table-cell-padding-y));
    color: var(--color, ui-color(#{$table-caption-color}));
    text-align: left;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
    font-weight: $table-th-font-weight; // 1
    text-align: inherit; // 2
    text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
    display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
    margin: 0; // 1
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

// Remove the inheritance of text transform in Firefox

button,
select {
    text-transform: none;
}

// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role="button"] {
    cursor: pointer;
}

// Remove the inheritance of word-wrap in Safari.
// See https://github.com/twbs/bootstrap/issues/24990

select {
    word-wrap: normal;
}

// Remove the dropdown arrow in Chrome from inputs built with datalists.
// See https://stackoverflow.com/a/54997118

[list]::-webkit-calendar-picker-indicator {
    display: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
    @if $enable-button-pointers {
        &:not(:disabled) {
            cursor: pointer; // 2
        }
    }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
    resize: vertical; // 1
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
    min-width: 0; // 1
    margin: 0; // 2
    padding: 0; // 2
    border: 0; // 2
}

// 1. By using `float: left`, the legend will behave like a block element.
//    This way the border of a fieldset wraps around the legend if present.
// 2. Fix wrapping bug.
//    See https://github.com/twbs/bootstrap/issues/29712

legend {
    --margin-bottom: var(--#{$legend-margin-bottom});

    float: left; // 1
    width: 100%;
    margin-bottom: var(--margin-bottom, spacer(#{$legend-margin-bottom}));
    padding: 0;
    @include font-scale($legend-font-scale);

    + * {
        clear: left; // 2
    }
}

// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type="search"] {
    outline-offset: -2px; // 1
    -webkit-appearance: textfield; // 2
}

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
    -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
    padding: 0;
}

// Inherit font family and line height for file input buttons

// stylelint-disable-next-line selector-pseudo-element-no-unknown
::file-selector-button {
    font: inherit;
}

// 1. Change font properties to `inherit`
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
    font: inherit; // 1
    -webkit-appearance: button; // 2
}

// Correct element displays

output {
    display: inline-block;
}

// Remove border from iframe

iframe {
    border: 0;
}

// Summary
//
// 1. Add the correct display in all browsers

summary {
    display: list-item; // 1
    cursor: pointer;
}

// Progress
//
// Add the correct vertical alignment in Chrome, Firefox, and Opera.

progress {
    vertical-align: baseline;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
    display: none !important;
}
