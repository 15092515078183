// Grid
//
// The grid is the foundation for positioning elements onscreen. Designing to the grid helps create seamless, easy to follow experiences.
//
// Use our powerful mobile-first grid to layout components, organisms inside sections, and anything between. For each component that uses the grid, the Figma files should mention the number of columns and gap token.
//
// Markup:
// <div class="grid cols-3 g-huge">
//     <div>
//         <h5 class="text-heading-medium">Wefox connect for Insurers</h5>
//         <p>Our suite of APIs and our SaaS offering allows insurers to create, publish, and distribute their products in real-time, while reducing workload on IT. Built by us, so you don’t have to.</p>
//     </div>
//     <div>
//         <h5 class="text-heading-medium">Wefox connect for Distributors</h5>
//         <p>Wefox connect APIs are maintained as a product, with a platform service model to match. This enables us to bridge the gap between your tech team and the IT departments of insurers.</p>
//     </div>
//     <div>
//         <h5 class="text-heading-medium">Wefox connect for Developers</h5>
//         <p>We think, breathe and live developer-first. Our REST API integrates with any modern tech stack and empowers developers to drive innovation and business growth.</p>
//     </div>
// </div>
//
// Style guide: objects.grid

// GRID object
//
// The <code>.grid</code> is powered by <code>display: grid</code>, it creates a max of 6 equal-width columns from its direct children by adding <code>cols-*</code> classes. The benefit is that we don't need to specify any classes on the children.
//
// The grid is mobile-first and fully-responsive following our breakpoints tokens. By default, <code>.grid</code> without any <code>cols-*</code> will make children have a width of 100%.
//
// <b>Columns</b>
//
// <ul><li><code>.cols-2</code> creates 2 equal-width columns.</li>
// <li><code>.cols-3</code> creates 3 equal-width columns.</li>
// <li><code>.cols-4</code> creates 4 equal-width columns.</li>
// <li><code>.cols-5</code> creates 5 equal-width columns.</li>
// <li><code>.cols-6</code> creates 6 equal-width columns.</li></ul>
//
// <b>Responsive</b>
//
// Use the breakpoint tokens to control responsiveness across devices, for ex: <code>cols-tablet-2</code> if you need 2 columns only for tablet and up.
//
// <ul><li><code>cols-tablet-*</code> for tablet and up</li>
// <li><code>cols-laptop-*</code> for laptop and up</li>
// <li><code>cols-desktop-*</code> for desktop and up</li></ul>
//
// <b>Gaps</b>
//
// To learn how to use our spacing tokens for grid <a href="#kssref-foundations-grid-gaps"> read Gaps section</a> as it is the same for <code>.grid</code> and <code>.row</code>.
//
// <b>When to use</b>
//
// <ul><li>When you want to create equal-width columns.</li>
// <li>And when you don't want to add additional classes on the children.</li></ul>
//
// A good use case is when you need a grid view for an organism with encapsulated children, like a grid of <code>.card</code> components.
//
// Markup: _grid.grid-object.html
//
// Weight: 1
//
// Style guide: objects.grid.grid-object

// ROW object
//
// The <code>.row</code> is powered by <code>display: flex</code> based on 12 columns. The main difference between <code>.grid</code> is that enables full column-width control for each children elements. The children need to have <code>.col-*</code> class.
//
// The row is mobile-first and fully-responsive following our breakpoints tokens.
//
// <b>Columns</b>
//
// <ul><li><code>.col</code> takes all the available space. 1 x <code>.col</code> = 100% width, 2 x <code>.col</code> = 50% each, etc.</li>
// <li><code>.col-auto</code> takes the space determined by the content.</li>
// <li><code>.col-1</code> creates 1/12 column width.</li>
// <li><code>.col-2</code> creates 2/12 column width.</li>
// <li><code>.col-3</code> creates 3/12 (25%) column width.</li>
// <li>...</li>
// <li><code>.col-6</code> creates 6/12 (50%) column width.</li>
// <li>...</li>
// <li><code>.col-12</code> creates 12/12 (100%) column width.</li></ul>
//
// <b>Responsive</b>
//
// Use the breakpoint tokens to control responsiveness across devices, for ex: <code>.col-6 .col-tablet-3</code> if you need 50% width on mobile and 25% for tablet and up.
//
// <ul><li><code>col-tablet-*</code> for tablet and up</li>
// <li><code>col-laptop-*</code> for laptop and up</li>
// <li><code>col-desktop-*</code> for desktop and up</li></ul>
//
// <b>Gaps</b>
//
// Read the <a href="#kssref-foundations-grid-gaps">Gaps</a> section as it is the same for <code>.grid</code> and <code>.row</code>.
//
// <b>When to use</b>
//
// <ul><li>When you want to create a layout that is changing between breakpoints.</li>
// <li>And when you don't want to add additional classes on the children.</li></ul>
//
// A good use case is when you need to structure atoms and molecules inside a component, like a form. Or a marketing component that has different column-width for each breakpoint.
//
// <div class="alert alert-warning"><b>Important!</b> If a child doesn't have a <code>.col</code> class, it will automatically take 100% of the space (full row). See example below on mobile for the first 2 fields.</div>
//
// Markup: _grid.row-object.html
//
// Weight: 2
//
// Style guide: objects.grid.row-object

@if $enable-grid {

    // Create .grid object
    .grid {
        @include make-grid();
    }

    // Create .cols-* template column
    @include make-grid-columns();

    // Create .row object
    .row {
        @include make-row();

        > * {
            @include make-row-col-ready();
        }
    }

    // Create .row > .col-* children
    @include make-row-columns();

    // Create .g-*, .gy-*, and .gx-* classes
    @include make-gaps();
}

// Gaps
//
// Gaps are the spacing between the columns, used to responsively space and align content in our grid grid system.
//
// They work the same for both grid objects (<code>.grid</code> and <code>.row</code>), and are powered by our spacing tokens. In Figma designs, every component should mention the gap tokens for each device.
//
// Create gaps by adding the <code>g-token</code> classes.
//
// <ul><li><code>.g-tiny</code> creates the space between columns equivalent to the <code>tiny</code> space token.</li>
// <li><code>.g-small</code> creates the space between columns equivalent to the <code>small</code> space token.</li>
// <li><code>.g-medium</code> creates the space between columns equivalent to the <code>medium</code> space token.</li>
// <li><code>.g-large</code> creates the space between columns equivalent to the <code>large</code> space token.</li>
// <li><code>.g-huge</code> creates the space between columns equivalent to the <code>huge</code> space token.</li>
// <li><code>.g-gigantic</code> creates the space between columns equivalent to the <code>gigantic</code> space token.</li></ul>
//
// The <code>g-token</code> classes create the same horizontal and vertical gap. You can control the direction by using the <code>gx-token</code> and <code>gy-token</code> instead of just <code>g-token</code>.
//
// <b>Responsive</b>
//
// Use the breakpoint tokens to control responsiveness across devices, for ex: <code>.g-small g-tablet-large .g-laptop-huge .g-desktop-gigantic</code> if you need different gaps.
//
// <ul><li><code>g-tablet-*</code> for tablet and up</li>
// <li><code>g-laptop-*</code> for laptop and up</li>
// <li><code>g-desktop-*</code> for desktop and up</li></ul>
//
// Markup: _grid.gap.html
//
// Weight: 3
//
// Style guide: objects.grid.gaps
