@import "../../00-settings/index";
@import "../../01-tools/index";

// Card
//
// A card is a container used to group related information and actions about a single subject.
//
// Style guide: components.card

// Usage
//
// A card is made up of 3 sections:
// <ol><li><b>Header</b> section is optional and has limitations.</li>
// <li><b>Body</b> section is optional and can accommodate any layout of related information.</li>
// <li><b>Footer</b> section is optional and has limitations.</li></ol>
//
// When the card doesn't include a <code>.card-header</code> section, a title/heading should exist in the <code>.card-body</code> section.
// You can use the class <code>.card-actions</code> inside <code>.card-header</code> to set a dropdown on the top right of the card
//
// Markup: card.html
//
// Weight: 1
//
// Style guide: components.card.usage

//
// Base styles
//
.card {
    --padding-y: var(--#{$card-padding-y});
    --padding-x: var(--#{$card-padding-x});
    --color: var(--#{$card-color});
    --bg: var(--#{$card-bg});
    --border-color: var(--#{$card-border-color});
    --border-radius: #{$card-border-radius};
    --box-shadow: #{$card-box-shadow};
    --header-color: var(--#{$card-header-color});
    --header-bg: var(--#{$card-header-bg});
    --subtitle-margin-top: var(--#{$card-subtitle-margin-t});
    --subtitle-color: var(--#{$card-subtitle-color});
    --footer-color: var(--#{$card-footer-color});
    --footer-bg: var(--#{$card-footer-bg});

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    color: var(--color, ui-color(#{$card-color}));
    word-wrap: break-word;
    background: var(--bg, ui-color(#{$card-bg}));
    border-color: var(--border-color, ui-color(#{$card-border-color}));
    border-style: solid;
    border-width: $card-border-width;
    border-radius: var(--border-radius, #{$card-border-radius});
    box-shadow: var(--box-shadow, #{$card-box-shadow});

    > :first-child {
        // TODO calc() doesn't work when card-border-width = 0, needs rethinking
        border-radius: calc(var(--border-radius) - #{$card-border-width}) calc(var(--border-radius) - #{$card-border-width}) 0 0;

        &:last-child {
            border-radius: calc(var(--border-radius) - #{$card-border-width});
        }
    }
}

.card-header {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--padding-y, spacer(#{$card-padding-y})) var(--padding-x, spacer(#{$card-padding-x}));
    color: var(--header-color, ui-color(#{$card-header-color}));
    background: var(--header-bg, ui-color(#{$card-header-bg}));
}

.card-actions {
    display: flex;
    margin-top: calc(var(--padding-y) / 2 * -1);
    margin-right: calc(var(--padding-x) / 2 * -1);

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}

.card-title {
    margin-bottom: 0; // removes headings margin-bottom
    @include font-scale(heading-medium);
}

.card-subtitle {
    display: block;
    margin-bottom: 0; // removes headings margin-bottom
    color: var(--subtitle-color, ui-color(#{$card-subtitle-color}));

    .card-title + & {
        margin-top: var(--subtitle-margin-top, spacer(#{$card-subtitle-margin-t}));
    }
}

.card-body {
    flex: 1 1 auto;
    padding: var(--padding-y, spacer(#{$card-padding-y})) var(--padding-x, spacer(#{$card-padding-x}));

    .card-header + & {
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: var(--none);
    }
}

.card-footer {
    padding: calc(var(--padding-y) / 2) var(--padding-x, spacer(#{$card-padding-x}));
    color: var(--footer-color, ui-color(#{$card-footer-color}));
    background: var(--footer-bg, ui-color(#{$card-footer-bg}));

    &:last-child {
        border-radius: 0 0 calc(var(--border-radius) - #{$card-border-width}) calc(var(--border-radius) - #{$card-border-width});

        &:first-child {
            border-radius: calc(var(--border-radius) - #{$card-border-width});
        }
    }
}

// Generate contextual modifier classes for colorizing the card.
@each $variant, $property in $card-variants {
    .card-#{$variant} {
        --color: var(--#{map-get($property, color)});
        --bg: var(--#{map-get($property, bg)});
        --border-color: var(--#{map-get($property, border-color)});
        --box-shadow: #{map-get($property, box-shadow)};
        --header-color: var(--#{map-get($property, header-color)});
        --header-bg: var(--#{map-get($property, header-bg)});
        --footer-color: var(--#{map-get($property, footer-color)});
        --footer-bg: var(--#{map-get($property, footer-bg)});
    }
}

// Subtle
//
// Markup: card-subtle.html
//
// Weight: 2
//
// Style guide: components.card.subtle

// Placeholder
//
// Markup: card-placeholder.html
//
// Weight: 2
//
// Style guide: components.card.placeholder

// Sizing
//
// Cards assume no specific width to start, so they’ll be 100% wide unless otherwise stated. You can change this as needed with custom CSS, grid classes, grid Sass mixins, or utilities.
//
// Weight: 3
//
// Style guide: components.card.sizing

// Examples
//
// Markup: card-examples.html
//
// Weight: 4
//
// Style guide: components.card.examples

// Config options
//
// <pre><code class="sass">$card-padding-y:                        huge !default;
// $card-padding-x:                        huge !default;
// $card-color:                            null !default;
// $card-bg:                               neutral-start !default;
// $card-border-width:                     0 !default;
// $card-border-color:                     neutral-darkest !default;
// $card-border-radius:                    $border-radius !default;
// $card-box-shadow:                       $box-shadow !default;
//
// $card-header-color:                     null !default;
// $card-header-bg:                        null !default;
// $card-subtitle-margin-t:                tiny !default;
// $card-subtitle-color:                   neutral-dark !default;
//
// $card-footer-color:                     null !default;
// $card-footer-bg:                        null !default;
//
// $card-variants: (
//     subtle: (
//         color: null,
//         bg: neutral-lightest,
//         border-color: neutral-lightest,
//         box-shadow: none,
//         header-color: null,
//         header-bg: null,
//         footer-color: null,
//         footer-bg: null
//     ),
//     placeholder: (
//         color: null,
//         bg: neutral-light,
//         border-color: neutral-light,
//         box-shadow: inset 0 0 1px ui-color(neutral-dark),
//         header-color: null,
//         header-bg: null,
//         footer-color: null,
//         footer-bg: null
//     )
// ) !default;</code></pre>
//
// Weight: 5
//
// Style guide: components.card.config
