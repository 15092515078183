// Typography
//
// The typography system is managed using typo tokens.
//
// The typo tokens are variables that contain font properties for each token and each breakpoint, if needed. The same typography map and token naming convention is used in the design tools.
//
//  <table class="table table-sm">
//      <thead>
//          <tr><th>Class name</th><th>Preview</th>
//      </thead>
//      <tbody>
//          <tr><td>.text-display-large</td><td><span class="text-display-large">This is .text-display-large</span></td></tr>
//          <tr><td>.text-display-medium</td><td><span class="text-display-medium">This is .text-display-medium</span></td></tr>
//          <tr><td>.text-display-small</td><td><span class="text-display-small">This is .text-display-small</span></td></tr>
//          <tr><td>.text-heading-large</td><td><span class="text-heading-large">This is .text-heading-large</span></td></tr>
//          <tr><td>.text-heading-medium</td><td><span class="text-heading-medium">This is .text-heading-medium</span></td></tr>
//          <tr><td>.text-heading-small</td><td><span class="text-heading-small">This is .text-heading-small</span></td></tr>
//          <tr><td>.text-body-large</td><td><span class="text-body-large">This is .text-body-large</span></td></tr>
//          <tr><td>.text-body</td><td><span class="text-body">This is .text-body</span></td></tr>
//          <tr><td>.text-caption</td><td><span class="text-caption">This is .text-caption</span></td></tr>
//      </tbody>
//  </table>
//
// Style guide: tokens.typography

// Config and update
//
// <pre><code class="sass">$font-scale: (
//     caption: (
//          null: (
//              font-size:      12,
//              line-height:    16
//          )
//      ),
//      body: (
//          null: (
//              font-size:      16,
//              line-height:    24
//          ),
//          laptop: (
//              font-size:      15,
//              line-height:    24
//          )
//      )
//      ...
// ) !default;</code></pre>
//
// Weight: 1
//
// Style guide: tokens.typography.config

// Usage
//
// Since our design system is developed to be mobile first, we use a handful of media queries to create sensible breakpoints for our structures.
//
// <div class="alert alert-warning"><b>!Important:</b> We prioritize the use of typo token classes over pure HTML tags.</div>
//
//
// We use HTML tags (<code>h1-h6</code>) for page structure and SEO reasons, but always declare the typo token class for visual reasons.
//
// Markup:
// <h1 class="text-display-small">H1 with the .text-display-small token applied</h1>
//
// Weight: 2
//
// Style guide: tokens.typography.usage

// Config font-weight values
$font-weight-normal:           400 !default;
$font-weight-semibold:         600 !default;
$font-weight-bold:             700 !default;

// Typography tokens
$font-scale: (
    caption: (
        null: (
            font-size:      12,
            line-height:    16,
        ),
    ),
    body: (
        null: (
            font-size:      16,
            line-height:    24,
        ),
    ),
    body-large: (
        null: (
            font-size:      18,
            line-height:    28,
        ),
        desktop: (
            font-size:      20,
            line-height:    32,
        ),
    ),
    heading-small: (
        null: (
            font-size:      19,
            line-height:    24,
            font-weight:    $font-weight-semibold,
        ),
    ),
    heading-medium: (
        null: (
            font-size:      22,
            line-height:    28,
            font-weight:    $font-weight-semibold,
        ),
    ),
    heading-large: (
        null: (
            font-size:      24,
            line-height:    32,
            font-weight:    $font-weight-bold,
        ),
        desktop: (
            font-size:      32,
            line-height:    40,
        ),
    ),
    display-small: (
        null: (
            font-size:      24,
            line-height:    32,
            font-weight:    $font-weight-bold,
        ),
        desktop: (
            font-size:      44,
            line-height:    48,
        ),
    ),
    display-medium: (
        null: (
            font-size:      32,
            line-height:    40,
            font-weight:    $font-weight-bold,
        ),
        desktop: (
            font-size:      50,
            line-height:    52,
        ),
    ),
    display-large: (
        null: (
            font-size:      40,
            line-height:    48,
            font-weight:    $font-weight-bold,
        ),
        desktop: (
            font-size:      72,
            line-height:    72,
        ),
    ),
) !default;
