/* TODO: Use info banner */
.c-formInformation {
  &.-confirmation,
  &.-info,
  &.-warning,
  &.-danger,
  &.-base {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-size: 14px;
    line-height: 19px;
    padding: 16px;
  }

  &.-danger {
    background-color: var(--danger-light);
    color: var(--danger-darkest);
  }

  &.-warning {
    background-color: var(--warning-light);
    color: var(--warning-darkest);
  }

  &.-warningImage {
    background-image: linear-gradient(74deg, var(--warning-light) -1%, var(--neutral-start) 75%);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 5%);
    padding: 24px 48px;
  }

  &.-confirmation {
    background-color: var(--info-light);
    color: var(--info-darkest);
  }

  &.-info {
    background-color: var(--info-light);
    color: var(--info-darkest);
  }

  &.-base {
    background-color: var(--neutral-base);
  }
}

.c-form__separator {
  border-bottom: 1px solid var(--neutral-base);
}

.c-formLogin {
  max-width: 500px;
}

.c-formThin {
  margin: 0 auto;
  max-width: 900px;

  &.-public {
    flex-grow: inherit;
    margin: 48px auto;
  }
}

.-public {
  flex-grow: inherit;
  margin: 48px auto;
  max-width: 900px;
}
