[type='submit'] {
  appearance: none;
}

input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button,
input::-webkit-search-cancel-button {
  opacity: 0;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
