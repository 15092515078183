// Utility function for mixin font-size
@use "sass:math";

@mixin make-font-scale($token, $breakpoint) {
    --font-size: #{rem(font-scale-value($token, $breakpoint, font-size) * 1px)};
    --line-height: #{math.div(font-scale-value($token, $breakpoint, line-height), font-scale-value($token, $breakpoint, font-size))};
    font-weight: font-scale-value($token, $breakpoint, font-weight);
    letter-spacing: font-scale-value($token, $breakpoint, letter-spacing);
}

// Iterate through the breakpoints and output the font-sizes for each one of them
@mixin font-scale($token) {
    $token-breakpoints: map-get($font-scale, $token);
    $breakpoints: map-keys($token-breakpoints);
    @each $breakpoint in $breakpoints {
        @if $breakpoint == null {
            @include make-font-scale($token, $breakpoint);
        }
        @else {
            @include media-breakpoint-up($breakpoint) {
                @include make-font-scale($token, $breakpoint);
            }
        }
    }
    font-size: var(--font-size);
    line-height: var(--line-height);
}
