@import "../../utilities.scss";
//
// Form text
//

.form-text {
    margin-top: $form-text-margin-top;
    color: $form-text-color;
    font-weight: $form-text-font-weight;
    font-style: $form-text-font-style;
    @include font-scale($form-text-font-scale);
}
