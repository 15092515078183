@import "../../00-settings/index";
@import "../../01-tools/index";

.form-label {
    --color: var(--#{$form-label-color});

    margin-bottom: var(--#{$form-label-margin-bottom}, spacer(#{$form-label-margin-bottom}));
    color: var(--color, ui-color(#{$form-label-color}));
    @include font-scale($form-label-font-scale);
    font-weight: $form-label-font-weight;
    font-style: $form-label-font-style;
}
