@import "../../00-settings/index";
@import "../../01-tools/index";

// Alerts
//
// Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
// The component is the foundation for confirmations, alerts, toasts and acknowledgments that require minimal user interaction.
//
// Alert messages come in the form of <code>.alert</code>, <code>.toast</code>, and <code>.topbar</code> components.
// Each component has a specific function and functionality as described in the documentation.
//
// <b>Styling:</b> Use the states color map (<code>info-shades</code>, <code>success-shades</code>, <code>warning-shades</code>, <code>danger-shades</code>) to style the alert types, and keep a consistent visual language.
//
// Markup: alert.html
//
// .alert-info          - When we communicate an info message.
// .alert-success       - When we communicate a success message.
// .alert-warning       - When we communicate a warning message.
// .alert-danger        - When we communicate a danger message.
//
// Style guide: components.alert

// Usage
//
// They can be dismissible or permanent depending on the situation, and optionally can support user actions.
//
// <b>Best practices</b>
//
// <ul><li>Add actions only if the user is required to follow up.</li>
// <li>The actions should require minimal user effort.</li></ul>
//
// Style guide: components.alert.usage

.alert {
    --margin-bottom: var(--#{$alert-margin-bottom});
    --padding-y: var(--#{$alert-padding-y});
    --padding-x: var(--#{$alert-padding-x});
    --color: var(--#{$alert-color});
    --bg: var(--#{$alert-bg});
    --border-color: var(--#{$alert-border-color});
    --border-radius: #{$alert-border-radius};

    position: relative;
    margin-bottom: var(--margin-bottom, spacer(#{$alert-margin-bottom}));
    padding: var(--padding-y, spacer(#{$alert-padding-y})) var(--padding-x, spacer(#{$alert-padding-x}));
    color: var(--color, ui-color(#{$alert-color}));
    background: var(--bg, ui-color(#{$alert-bg}));
    border-color: var(--border-color, ui-color(#{$alert-border-color}));
    border-style: solid;
    border-width: $border-width;
    border-radius: var(--border-radius, $alert-border-radius);

    a:not(.btn) {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .btn-close {
        --padding-y: var(--#{$alert-padding-y});
        --padding-x: var(--#{$alert-padding-x});

        position: absolute;
        top: 0;
        right: 0;
        z-index: $stretched-link-z-index + 1;
        padding: calc(var(--padding-y, spacer(#{$alert-padding-y})) * 1.25) var(--padding-x, spacer(#{$alert-padding-x}));
    }

    > .row {
        --gap-x: var(--large);
    }
}

.alert-actions {
    margin-top: var(--#{$alert-actions-margin-t}, spacer(#{$alert-actions-margin-t}));

    .btn {
        --color: var(--#{$alert-btn-color});
        --bg: var(--#{$alert-btn-bg});
        --border-color: var(--#{$alert-btn-bg});
        --box-shadow: none;
        --hover-border-color: var(--#{$alert-btn-bg});
        --hover-box-shadow: none;
        --active-border-color: var(--#{$alert-btn-bg});
        --active-box-shadow: none;
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-scale-sm, $btn-border-radius-sm);
    }
}

// Generate contextual modifier classes for colorizing the alert.
@each $variant, $property in $alert-variants {
    .alert-#{$variant} {
        --color: var(--#{map-get($property, color)});
        --bg: var(--#{map-get($property, bg)});
        --border-color: var(--#{map-get($property, bg)});

        .alert-actions .btn {
            --color: var(--#{map-get($property, btn-color)});
            --bg: var(--#{map-get($property, btn-bg)});
            --border-color: var(--#{map-get($property, btn-bg)});
        }
    }
}

// Alert actions
//
// The links and buttons automatically inherit the parent color.
// The buttons use the alert's <code>color-dark</code> token for the background color set by <code>$alert-btn-bg</code> variable in <code>alert-variants</code> map.
//
// Markup: alert-actions.html
//
// .alert-info          - When we communicate an info message.
// .alert-success       - When we communicate a success message.
// .alert-warning       - When we communicate a warning message.
// .alert-danger        - When we communicate a danger message.
//
// Weight: 1
//
// Style guide: components.alert.actions

// Alert dismissible
//
// Using the Bootstrap alert JavaScript plugin (or write your own Angular/React version), it’s possible to dismiss any alert inline.
//
// Here’s how:
//
// <ul><li>Be sure you’ve loaded the alert plugin, or the compiled Bootstrap JavaScript.</li>
// <li>Add a close button and the .alert-dismissible class, which adds extra padding to the right of the alert and positions the close button.</li>
// <li>On the close button, add the <code>data-dismiss="alert"</code> attribute, which triggers the JavaScript functionality. Be sure to use the <code>button</code> element with it for proper behavior across all devices.</li>
// <li>To animate alerts when dismissing them, be sure to add the .fade and .show classes.</li></ul>
//
// Markup: alert-dismissible.html
//
// .alert-info          - When we communicate an info message.
// .alert-success       - When we communcate a success message.
// .alert-warning       - When we communcate a warning message.
// .alert-danger        - When we communcate a danger message.
//
// Weight: 2
//
// Style guide: components.alert.dismissible

.alert-dismissible {
    padding-right: $alert-dismissible-padding-r;
}

// Config options
//
// <pre><code class="sass">$alert-padding-y:                   spacer(medium) !default;
// $alert-padding-x:                   spacer(large) !default;
// $alert-margin-bottom:               spacer(large) !default;
// $alert-color:                       ui-color(neutral-dark);
// $alert-link-font-weight:            $font-weight-bold !default;
// $alert-bg:                          ui-color(neutral-light) !default;
// $alert-border-width:                0 !default;
// $alert-border-color:                $alert-bg !default;
// $alert-border-radius:               $border-radius !default;
//
// $alert-variants: (
//     info: (
//         bg: info-base,
//         border-color: info-base,
//         color: neutral-start,
//         link-color: neutral-start
//     ),
//     success: (
//         bg: success-base,
//         border-color: success-base,
//         color: neutral-start,
//         link-color: neutral-start
//     ),
//     warning: (
//         bg: warning-base,
//         border-color: warning-base,
//         color: neutral-end,
//         link-color: neutral-end
//     ),
//     danger: (
//         bg: danger-base,
//         border-color: danger-base,
//         color: neutral-start,
//         link-color: neutral-start
//     )
// ) !default;</code></pre>
//
// Weight: 3
//
// Style guide: components.alert.config

// Custom
//
// Using other atoms and utilities classes we can create custom alert components.
//
// Here are some examples:
//
// Markup: alert-custom.html
//
// Weight: 4
//
// Style guide: components.alert.custom
