@font-face {
    font-weight: bold;
    font-family: "Kyn";
    font-style: normal;
    src: url("../../fonts/Kyn/Kyn-Bold.eot");
    src: url("../../fonts/Kyn/Kyn-Bold.woff") format("woff");
    src: url("../../fonts/Kyn/Kyn-Bold.woff2") format("woff2");
    font-display: auto;
}
@font-face {
    font-weight: 500;
    font-family: "Kyn";
    font-style: normal;
    src: url("../../fonts/Kyn/Kyn-Medium.eot");
    src: url("../../fonts/Kyn/Kyn-Medium.woff") format("woff");
    src: url("../../fonts/Kyn/Kyn-Medium.woff2") format("woff2");
    font-display: auto;
}
@font-face {
    font-weight: normal;
    font-family: "Kyn";
    font-style: normal;
    src: url("../../fonts/Kyn/Kyn-Regular.eot");
    src: url("../../fonts/Kyn/Kyn-Regular.woff") format("woff");
    src: url("../../fonts/Kyn/Kyn-Regular.woff2") format("woff2");
    font-display: auto;
}
@font-face {
    font-weight: 600;
    font-family: "Kyn";
    font-style: normal;
    src: url("../../fonts/Kyn/Kyn-Semibold.eot");
    src: url("../../fonts/Kyn/Kyn-Semibold.woff") format("woff");
    src: url("../../fonts/Kyn/Kyn-Semibold.woff2") format("woff2");
    font-display: auto;
}
