@import "../00-settings/index";
@import "../01-tools/index";

@import "../bootstrap/modal";

// Modal
//
// Markup: modal.hbs
//
// Style guide: components.modal

// - Sizes
//
//  Below is a static modal example (meaning its <b>position and display have been overridden</b>).
//
// .modal-lg - large
//
// Markup: modal-sizes.hbs
//
// Weight: 1
//
// Style guide: components.modal.sizes

// - Scroll inside
//
//  Below is a static modal example (meaning its <b>position and display have been overridden</b>).
//
// Markup: modal-scroll.hbs
//
// Weight: 2
//
// Style guide: components.modal.scroll

.modal-title {
    @include font-scale(heading-medium);
}

.modal-subtitle {
    color: ui-color(neutral-dark);

    .modal-title + & {
        margin-top: spacer(small);
    }
}

.modal-body {
    .modal-header + & {
        padding-top: $modal-header-padding-y;
        padding-bottom: $modal-header-padding-y;
    }
}

.modal-footer {
    padding-top: $modal-inner-padding * .5;
    padding-bottom: $modal-inner-padding * .5;
}

.modal-footer-subtle {
    background-color: ui-color(neutral-light);
}
