/* stylelint-disable no-descending-specificity, no-duplicate-selectors */
.c-input__text {
  align-self: center;
  background-color: var(--neutral-start);
  border: solid 1px var(--neutral-base);
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  height: 50px;
  min-height: 50px;
  padding-left: 16px;
  transition: border-color 0.2s ease, border-width 0.2s ease;
  width: 100%;

  &:focus,
  &.-focus {
    border-color: var(--primary-base);
    border-width: 2px;
    box-shadow: 0 0 4px 10px var(--neutral-start);
    outline: 1px solid transparent;
  }

  &.-text-area {
    height: inherit;
    padding-top: 24px;
  }
}

.c-input__text {
  &:disabled {
    background-color: var(--neutral-light);
    border-color: var(--neutral-dark);
    color: var(--neutral-dark);
    position: relative;
  }
}

.c-input__text:focus + .c-label__text,
.c-input__text.-focus + .c-label__text {
  color: var(--primary-base);
}

.c-input__text:focus + .c-label__text,
.c-input__text.-activeLabel + .c-label__text,
.c-input__text.-activeLabel.-focus + .c-label__text,
.c-input__text.ng-valid:not(.ng-pristine) + .c-label__text,
.c-input__text.ng-valid.ng-touched + .c-label__text,
.c-input__text.ng-invalid.ng-touched + .c-label__text,
.c-input__text:disabled + .c-label__text {
  font-size: 13px;
  transform: translate3d(0, -25px, 0);
}

.c-input__text:not(.ng-untouched).ng-invalid {
  border-color: var(--danger-base);

  .-submitted.ng-invalid & {
    border-color: var(--danger-base);
  }
}

.c-input__text.ng-invalid {
  .-submitted.ng-invalid & {
    border-color: var(--danger-base);
    color: var(--danger-base);
  }
}

.c-input__text.ng-invalid + .c-label__text,
.c-input__text.ng-invalid:focus + .c-label__text {
  .-submitted.ng-invalid & {
    color: var(--danger-base);
  }
}

.c-input__text:not(.ng-untouched).ng-invalid + .c-label__text {
  color: var(--danger-base);
}

.form-control {
  &:disabled {
    background-color: var(--disabled-bg);
    border: none;
    color: var(--disabled-color);
    padding-left: 16px;
  }
}

/* stylelint-enable no-descending-specificity, no-duplicate-selectors */

input.c-input__text:-webkit-autofill,
input.c-input__text:-webkit-autofill:hover,
input.c-input__text:-webkit-autofill:focus,
input.c-input__text:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--neutral-start) inset !important;
}
