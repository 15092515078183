@mixin input-size($padding-y, $padding-x, $font-scale, $border-radius) {
    --padding-y: var(--#{$padding-y});
    --padding-x: var(--#{$padding-x});
    --border-radius: #{$border-radius};

    @include font-scale($font-scale);
}

// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details
@mixin form-validation-state-selector($state) {
    @if ($state == "valid" or $state == "invalid") {
        .was-validated #{if(&, "&", "")}:#{$state},
        #{if(&, "&", "")}.is-#{$state} {
            @content;
        }
    }
    @else {
        #{if(&, "&", "")}.is-#{$state} {
            @content;
        }
    }
}

@mixin form-validation-state($state, $color, $icon) {
    .#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: var(--#{$form-feedback-margin-top}, spacer(#{$form-feedback-margin-top}));
        color: var(--#{$color}, ui-color(#{$color}));
        @include font-scale($form-feedback-font-scale);
        font-style: $form-feedback-font-style;
    }

    @include form-validation-state-selector($state) {
        ~ .#{$state}-feedback {
            display: block;
        }
    }

    .form-control,
    .form-select {
        @include form-validation-state-selector($state) {
            --border-color: var(--#{$color});
            --focus-border-color: var(--#{$color});
        }
    }

    .form-check-input {
        @include form-validation-state-selector($state) {
            --border-color: var(--#{$color});
            --checked-bg: var(--#{$color});
            --checked-border-color: var(--checked-bg);
        }
    }

    .form-check-inline .form-check-input {
        ~ .#{$state}-feedback {
            margin-left: .5em;
        }
    }
}
