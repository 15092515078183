@import "../../00-settings/index";
@import "../../01-tools/index";

// Checkbox
//
// A checkbox is an input control that allows a user to select one or more options from a number of choices.
//
// Markup:
// <div class="form-check">
//     <input class="form-check-input" type="checkbox" value="" id="checkboxDemo">
//     <label class="form-check-label" for="checkboxDemo">I agree subscribing to product news</label>
// </div>
//
// Style guide: forms.form-checkbox

// Usage
//
// Primarily for use in forms, checkboxes are used to collect input from users. Users can select a number of options ranging from zero to multiple options.
//
// <b>Best practices</b>
//
// <ul><li>Use checkboxes when users have to select one or more options from a list of related items.</li>
// <li>Or an explicit action is required to apply settings, ex: get consent.</li></ul>
//
// <b>Content guidelines</b>
//
// <ul><li>Keep checkbox labels short and descriptive.</li>
// <li>Start all checkbox labels with a capital letter.</li>
// <li>Don't include punctuation after checkbox labels.</li></ul>
//
// Markup:
// <div>
//     <p class="form-label">Your policy renewal settings</p>
//     <div class="form-check">
//         <input class="form-check-input" type="checkbox" value="" id="checkboxDemo1">
//         <label class="form-check-label" for="checkboxDemo1">Automatically renew every 12 months</label>
//     </div>
//     <div class="form-check">
//         <input class="form-check-input" type="checkbox" value="" id="checkboxDemo2">
//         <label class="form-check-label" for="checkboxDemo2">Send me an email 30 days in advance</label>
//     </div>
// </div>
//
// Style guide: forms.form-checkbox.usage

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it depends on another selection.
//
// Markup:
// <div class="form-check">
//     <input class="form-check-input" type="checkbox" value="" id="checkboxDemo3" disabled>
//     <label class="form-check-label" for="checkboxDemo3">Automatically renew every 12 months</label>
// </div>
//
// Weight: 1
//
// Style guide: forms.form-checkbox.disabled

// Radio
//
// A radio input allows people to select only one option from a number of choices. Radio is generally displayed in a radio group.
//
// Markup:
// <div class="form-check">
//     <input class="form-check-input" type="radio" name="radio-group" id="radioDemo1">
//     <label class="form-check-label" for="radioDemo1">Read</label>
// </div>
// <div class="form-check">
//     <input class="form-check-input" type="radio" name="radio-group" id="radioDemo2">
//     <label class="form-check-label" for="radioDemo2">Read and write</label>
// </div>
//
// Style guide: forms.form-radio

// Usage
//
// Use a radio group when is mandatory for the user to select an option from several ones. Keep the number of options less than 6-8, otherwise use a <code>select</code> element.
//
// <b>Best practices</b>
//
// <ul><li>List options in a logical order:
// <ul><li>most likely to least likely to be selected</li>
// <li>simplest to most complex operation</li>
// <li>least to most risk</li></ul></li>
// <li>Make one radio group option the default. Select the safest, most secure, and private option first. If safety and security aren’t factors, select the most likely or convenient option.</li>
// <li>If you need an unselected state, add a radio group with a "None" option.</li>
// <li>If you can't have a list of all possible options, add an "Other" option.</li>
// <li>Avoid putting things in alphabetical order because it is language dependent and not localizable.</li>
// <li>Make sure choices don’t overlap. For example, Select age: 0-20, 20-40 — What do you pick if your age is 20?</li>
// <li>Avoid forgetting to include choices. For example, Select age: Below 20, Above 20 — What do you pick if you are 20?</li></ul>
//
// <b>Content guidelines</b>
//
// Labels should be concise and provide context.
//
// Markup:
// <div>
//     <p class="form-label">Your policy renewal period</p>
//     <div class="form-check">
//        <input class="form-check-input" type="radio" name="radio-group-1" id="radioDemo3">
//        <label class="form-check-label" for="radioDemo3">Yearly</label>
//    </div>
//    <div class="form-check">
//        <input class="form-check-input" type="radio" name="radio-group-1" id="radioDemo4">
//        <label class="form-check-label" for="radioDemo4">Monthly</label>
//    </div>
// </div>
//
// Style guide: forms.form-radio.usage

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it depends on another selection.
//
// Markup:
// <div class="form-check">
//    <input class="form-check-input" type="radio" name="radio-group-2" id="radioDemo5" checked disabled>
//    <label class="form-check-label" for="radioDemo5">Yearly</label>
// </div>
// <div class="form-check">
//    <input class="form-check-input" type="radio" name="radio-group-2" id="radioDemo6" disabled>
//    <label class="form-check-label" for="radioDemo6">Monthly</label>
// </div>
//
// Weight: 1
//
// Style guide: forms.form-radio.disabled

.form-check {
    --size-x: #{$form-check-input-size};
    --size-y: #{$form-check-input-size};
    --padding-left: calc(var(--size-x, #{$form-check-input-size}) + var(--medium));
    --color: var(--);
    --bg: var(--#{$form-check-input-bg});
    --border-color: var(--#{$form-check-input-border-color});
    --border-radius: #{$form-check-input-border-radius};
    --box-shadow: #{$form-check-input-box-shadow};
    --focus-border-color: var(--#{$form-check-input-focus-border-color});
    --focus-box-shadow: var(--#{$form-check-input-focus-box-shadow});
    --checked-bg: var(--#{$form-check-input-checked-bg});
    --checked-border-color: var(--#{$form-check-input-checked-border-color});
    --indeterminate-bg: var(--#{$form-check-input-indeterminate-bg});
    --indeterminate-border-color: var(--#{$form-check-input-indeterminate-border-color});

    display: block;
    padding-left: var(--padding-left, 2rem);

    & + & {
        margin-top: var(--#{$form-check-stack-gap-y}, spacer(#{$form-check-stack-gap-y}));
    }

    .form-check-input {
        float: left;
        margin-left: calc(var(--padding-left, 2rem) * -1);
    }
}

.form-check-input {
    width: var(--size-x, #{$form-check-input-size});
    height: var(--size-y, #{$form-check-input-size});
    vertical-align: top;
    background-color: var(--bg, ui-color(#{$form-check-input-bg}));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-color: var(--border-color, ui-color(#{$form-check-input-border-color}));
    border-style: solid;
    border-width: $form-check-input-border-width;
    box-shadow: var(--box-shadow, #{$form-check-input-box-shadow});
    cursor: $form-check-input-cursor;
    transition: $input-toggle-transition;
    appearance: none;
    print-color-adjust: exact; // Keep themed appearance for print

    &[type="checkbox"] {
        border-radius: var(--border-radius, #{$form-check-input-border-radius});
    }

    &[type="radio"] {
        border-radius: 50%;
    }

    &:focus {
        --border-color: var(--focus-border-color);
        --box-shadow: var(--focus-box-shadow);
    }

    &:checked {
        --bg: var(--checked-bg);
        --border-color: var(--checked-border-color);

        &[type="checkbox"] {
            background-image: escape-svg($form-check-input-checked-bg-image);
        }

        &[type="radio"] {
            background-image: escape-svg($form-check-radio-checked-bg-image);
        }
    }

    &[type="checkbox"]:indeterminate {
        --bg: var(--indeterminate-bg);
        --border-color: var(--indeterminate-border-color);

        background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }

    &[disabled],
    &:disabled,
    &.disabled {
        opacity: $form-check-input-disabled-opacity;
        pointer-events: none;

        ~ .form-check-label {
            cursor: default;
            opacity: $form-check-label-disabled-opacity;
        }
    }
}

.form-check-label {
    --color: var(--#{$form-check-label-color});

    display: block;
    color: var(--color, ui-color(#{$form-check-label-color}));
    cursor: $form-check-label-cursor;
}

// Toggle
//
// A toggle is used to view or switch between enabled or disabled states.
//
// Markup:
// <div class="form-check form-toggle">
//     <input class="form-check-input" type="checkbox" id="toggleDemo">
//     <label class="form-check-label" for="toggleDemo">Enable public access</label>
// </div>
//
// Style guide: forms.form-toggle

// Usage
//
// Use toggles when the user intent is to turn something on or off instantly, for example, to enable public access to an insurance product. If a physical switch would work for the action, a toggle is probably the best component to use.
//
// <b>Best practices</b>
//
// <ul><li>Toggles should never require users to press a button to apply the settings.</li></ul>
//
// <b>Content guidelines</b>
//
// Labels should be concise and provide context.
//
// Style guide: forms.form-toggle.usage

// Disabled
//
// Add the <code>disabled</code> boolean attribute to give it a grayed out appearance and remove pointer events. Disable a field only if it depends on another selection.
//
// Markup:
// <div class="form-check form-toggle">
//     <input class="form-check-input" type="checkbox" id="toggleDemo2" disabled>
//     <label class="form-check-label" for="toggleDemo2">Enable public access</label>
// </div>
//
// Weight: 1
//
// Style guide: forms.form-toggle.disabled

.form-toggle {
    --size-x: #{$form-toggle-size};
    --border-radius: #{$form-toggle-border-radius};

    .form-check-input {
        margin-left: calc(var(--padding-left) * -1);
        background-image: escape-svg($form-toggle-bg-image);
        background-position: left center;
        background-size: calc(var(--size-y) - #{$form-check-input-border-width} * 2);

        &:focus {
            background-image: escape-svg($form-toggle-focus-bg-image);
        }

        &:checked {
            background-image: escape-svg($form-toggle-checked-bg-image);
            background-position: right center;
        }
    }
}

.form-check-inline {
    --margin-right: var(--#{$form-check-inline-margin-right});

    display: inline-block;
    margin-right: var(--margin-right, spacer(#{$form-check-inline-margin-right}));
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &[disabled],
    &:disabled,
    &.disabled {
        + .btn {
            opacity: $form-check-btn-check-disabled-opacity;
            filter: none;
            pointer-events: none;
        }
    }
}
