@import "../00-settings/index";
@import "../01-tools/index";

@import "../bootstrap/navbar";

// Navbar
//
// Markup: navbar.hbs
//
// Style guide: components.navbar

.navbar {
    min-height: $navbar-min-height;
    padding: 0 spacer(large);
    background-color: $navbar-background-color;
    box-shadow: $navbar-box-shadow;
}

.navbar-primary,
.navbar-secondary {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(desktop) {
        flex: 1 1 30%;
    }
}

.navbar-primary {
    .btn-subtle.btn-square:first-of-type {
        margin-left: -($btn-square-padding);
    }
}

.navbar-secondary {
    .nav {
        flex-wrap: nowrap;
        align-items: center;
    }

    .nav-link {
        padding: $nav-link-padding-y;
        white-space: nowrap;
    }

    @include media-breakpoint-up(desktop) {
        justify-content: flex-end;
        order: 6;
    }
}

.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-title {
    margin-bottom: 0;
    margin-left: spacer(small);
}

.navbar-nav {
    .active > .nav-link,
    .nav-link.active {
        font-weight: $font-weight-bold;
    }
}

.navbar-toggler,
.navbar-toggler:focus {
    border-width: 0;
    box-shadow: none;
}

.navbar-light {
    .nav-secondary .nav-link {
        color: $navbar-light-color;
    }
}

.navbar-dark {
    .navbar-secondary .nav-link {
        color: $navbar-dark-color;
    }
}
